<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'Flagman.Horeca2.MicroPos.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'Flagman.Horeca2.MicroPos.TitleBottom' | translate}}
  </p>
  <div class="row flex-center">
    <div class="col order-1 wow fadeInLeft" data-wow-delay="1.2s">



      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-th fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca2.MicroPos.SpecTitleOne' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca2.MicroPos.SpecTextOne' | translate}}
        </p>
      </div>
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-rocket fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca2.MicroPos.SpecTitleTwo' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca2.MicroPos.SpecTextTwo' | translate}}
      </p>

    </div>


    <div class="col-lg-5 order-3 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" style="height: 400px; width: 540px"  src="assets/Flagman-pos.png" alt="Card image cap">

    </div>



    <div class="col order-2 wow fadeInLeft" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-signal fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca2.MicroPos.SpecTitleThree' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca2.MicroPos.SpecTextThree' | translate}}

        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca2.MicroPos.SpecTitleFour' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca2.MicroPos.SpecTextFour' | translate}}
      </p>



    </div>

  </div>


  <button mdbBtn color="primary" class="waves-light mt-5 wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec' | translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-6 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
        <p style="text-align: left;"><strong>Диагональ:</strong> 15″<br>
          <strong>Максимальное разрешение:</strong> 1024×768<br>
          <strong>Сенсорная панель:</strong> Резистивный<br>
          <strong>Процессор:</strong> Intel® Celeron® Processor J1900 2.4Ghz<br>
          <strong>Память RAM:</strong> 4GB<br>
          <strong>Память ROM:</strong> 64GB SSD<br>
          <strong>Интерфейсы:</strong> COM: 2xRS-232, 1xRJ-45; 7xUSB 2.0; LANx1 (1000Mbps); 1хPS/2; 1xVGA; 1xAudio Out; 2x1W Speaker Out; 1xRJ-11 (Cash Drawer Port).</p>
      </div>
    </div>
  </div>
</div>























