<div class="bgalexthree ">
  <div class="rgba-blue-strong" >
<div class="view mask pattern-6  my-3 py-3">

<div class="container  my-5">


  <!--Section: Content-->
  <section class="dark-grey-text">
    <!--First row-->
    <div class="row">

      <!--First column-->
      <div class="col-md-6 mb-4 wow fadeInLeft" data-wow-delay="0.6s">

        <!-- Card -->
        <a  class="">

          <!-- Card content -->
          <div class="card-body border">

            <div class="media">
              <span class="card-img-100 d-inline-flex justify-content-center align-items-center  grey lighten-3 mr-4">
                 <img class="img-fluid" src="assets/complect/1-icon-serv.png" alt="Sample image">
              </span>
              <div class="media-body">
                <h5 class="white-text mb-3">{{'PosCompact.DivThreeAll.TitleOne' | translate}}</h5>
                <p class="white-text font-weight-bold  mb-0">{{'PosCompact.DivThreeAll.TextOne' | translate}}</p>
              </div>
            </div>

          </div>

        </a>
        <!-- Card -->

      </div>
      <!--First column-->

      <!--Second column-->
      <div class="col-md-6 mb-4 wow fadeInRight" data-wow-delay="0.6s">

        <!-- Card -->
        <a  class="">

          <!-- Card content -->
          <div class="card-body border">

            <div class="media">
              <span class="card-img-100 d-inline-flex justify-content-center align-items-center  grey lighten-3 mr-4">
                <img class="img-fluid" src="assets/complect/1-icon-serv1.png" alt="Sample image">
              </span>
              <div class="media-body">
                <h5 class="white-text mb-3">{{'PosCompact.DivThreeAll.TitleTwo' | translate}}</h5>
                <p class="white-text font-weight-bold mb-0">{{'PosCompact.DivThreeAll.TextTwo' | translate}}</p>
              </div>
            </div>

          </div>

        </a>
        <!-- Card -->
        <!-- Card -->

      </div>
      <!--Second column-->

    </div>
    <!--First row-->

  </section>

</div>
</div>
</div>
</div>
