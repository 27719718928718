<div class="container pt-5 pb-5">
    <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">{{'PosAlimentara.ScalesRS.TitleTop' | translate}}</h3>
    <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">{{'PosAlimentara.ScalesRS.TitleBottom' | translate}}</p>
  <div class="row flex-center">

    <div class="col-lg-6 order-1 wow fadeInLeft" data-wow-delay="1.2s">
      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-tv fa-2x green-text pr-2"></i>
          {{'PosAlimentara.ScalesRS.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosAlimentara.ScalesRS.SpecTextOne' | translate}}
        </p>
      </div>




      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-keyboard fa-2x green-text pr-2"></i>
          {{'PosAlimentara.ScalesRS.SpecTitleTwo' | translate}}
        </h6>
        <p class="">
          {{'PosAlimentara.ScalesRS.SpecTextTwo' | translate}}

        </p>
      </div>


      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
          {{'PosAlimentara.ScalesRS.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'PosAlimentara.ScalesRS.SpecTextThree' | translate}}
        </p>
      </div>


      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-battery-full fa-2x green-text pr-2"></i>
        {{'PosAlimentara.ScalesRS.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosAlimentara.ScalesRS.SpecTextFour' | translate}}

      </p>




    </div>





    <div class="col-lg-4 order-21 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/alexmarket/Market.png" lightbox backgroundColor="white" alt="Sample image">


    </div>




  </div>

  <div class="flex-center">
    <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
      <mdb-icon fas icon="arrow-down"></mdb-icon>
      {{'BUTTONS.TehSpec' | translate}}
      <mdb-icon fas icon="arrow-down"></mdb-icon>
    </button>
  </div>

  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
        <p style="text-align: left;"><strong>Механизм принтера:</strong> Прямая линия термопринтер<br>
          <strong>Скорость печати:</strong> до 12 строк в секунду<br>
          <strong>Ширина бумаги:</strong> 57 мм (42 символов в строке)<br>
          <strong>Толщина бумаги:</strong> 60 – 72 мкм<br>
          <strong>Ролл Диаметр:</strong> 55 мм<br>
          <strong>Загрузка бумаги:</strong> Легкая загрузка бумаги<br>
          <strong>Дисплей оператора:</strong> Графический ЖК-дисплей, 7 строк х 25 символов (256 х 128)<br>
          <strong>Дисплей клиента:</strong> Графический ЖК-дисплей, 2 строки х 16 символов (256 х 64)<br>
          <strong>Клавиатура:</strong> 33 клавиш<br>
          <strong>Базы данных:</strong> PLU Вместимость 100 000 PLus<br>
          <strong>Inventory группы:</strong> 9<br>
          <strong>Bедомства:</strong> 9<br>
          <strong>Oператоры:</strong> 30 (30 активных)<br>
          <strong>Фискальная память:</strong> Энергонезависимая, может поддерживать до 1825 ежедневных отчетов<br>
          <strong>Способы оплаты:</strong> Наличные, чек *, карточка *, кредит * (* программируемый)<br>
          <strong>Денежные средства</strong> с альтернативной валюты<br>
          <strong>Tипы штрих-кода:</strong> EAN 13, EAN 8, UPC, Отпечатано электронными весами<br>
          <strong>Максимальное количество продаж в одной квитанции</strong> Более 380<br>
          <strong>Cвязь:</strong> 2 х RS-232C порт для подключения:<br>
          – Считыватель бар-кода<br>
          – Цифровая шкала<br>
          – Внешний дисплей покупателя<br>
          – ПК<br>
          <strong>1 х USB класс</strong> устройства через мини-USB (опция)<br>
          <strong>Контроль Ящика 1 х </strong><br>
          <strong>Bluetooth</strong> (опция)<br>
          <strong>GPRS</strong> (опция)<br>
          <strong>Ethernet</strong> (опция)<br>
          <strong>Источник питания:</strong> Входное напряжение: AC 110 / 240В ~, 50-60 Гц<br>
          <strong>i. Выход:</strong> DC 9V / 5A<br>
          <strong>Aккумулятор:</strong> Литий-ионный аккумулятор емкостью 2000 мАч 7.4V<br>
          <strong>Размеры:</strong> (ДхШхВ) 160 х 216 х 97 мм<br>
          <strong>Bес:</strong> 570 г</p>
      </div>
    </div>
  </div>


</div>









































<!--<div class="container pt-5 pb-5">-->
<!--  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">Весы торговые с RS</h3>-->
<!--  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">ВЕСЫ торговые BSN 6/15 kg D1.3 T2 230x290 с RS-232</p>-->
<!--  <div class="row flex-center">-->


<!--    <div class="col-md-3 order-2 wow fadeInRight pt-5" data-wow-delay="0.6s">-->
<!--    <div class="pt-5">-->
<!--      <h6 class="font-weight-bold mb-3">-->
<!--        <i class="fas fa-sitemap fa-2x green-text pr-2"></i>-->
<!--        Большой набор интерфейсов-->
<!--      </h6>-->
<!--      <p class="">-->
<!--        Платформа из нержавеющей стали-->
<!--        Дисплей покупателя установлен на стойке-->
<!--        Усиленная конструкция корпуса-->
<!--        Усиленная конструкция грузоприемной платформы-->
<!--        Клавиатура 20 клавиш-->
<!--        Автоматическая и полуавтоматическая установка нуля-->
<!--        Выборка массы тары-->
<!--        Встроенный аккумулятор-->
<!--      </p>-->
<!--    </div>-->
<!--    </div>-->




<!--    <div class="col-lg-5 order-1 wow fadeInLeft" data-wow-delay="0.6s">-->
<!--      <img class="img-fluid" src="assets/complect/alimentara/ВЕСЫ-торговые-BSN-6_15kgD1_3.jpg" alt="Sample image">-->

<!--    </div>-->




<!--  </div>-->

<!--<div class="flex-center">-->
<!--  <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>-->
<!--    <mdb-icon fas icon="arrow-down"></mdb-icon>-->
<!--    Тех.Характеристики-->
<!--    <mdb-icon fas icon="arrow-down"></mdb-icon>-->
<!--  </button>-->
<!--</div>-->

<!--  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">-->
<!--    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">-->

<!--      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">-->
<!--        <p style="text-align: left;"><strong>Механизм принтера:</strong> Прямая линия термопринтер<br>-->
<!--          <strong>Скорость печати:</strong> до 12 строк в секунду<br>-->
<!--          <strong>Ширина бумаги:</strong> 57 мм (42 символов в строке)<br>-->
<!--          <strong>Толщина бумаги:</strong> 60 – 72 мкм<br>-->
<!--          <strong>Ролл Диаметр:</strong> 55 мм<br>-->
<!--          <strong>Загрузка бумаги:</strong> Легкая загрузка бумаги<br>-->
<!--          <strong>Дисплей оператора:</strong> Графический ЖК-дисплей, 7 строк х 25 символов (256 х 128)<br>-->
<!--          <strong>Дисплей клиента:</strong> Графический ЖК-дисплей, 2 строки х 16 символов (256 х 64)<br>-->
<!--          <strong>Клавиатура:</strong> 33 клавиш<br>-->
<!--          <strong>Базы данных:</strong> PLU Вместимость 100 000 PLus<br>-->
<!--          <strong>Inventory группы:</strong> 9<br>-->
<!--          <strong>Bедомства:</strong> 9<br>-->
<!--          <strong>Oператоры:</strong> 30 (30 активных)<br>-->
<!--          <strong>Фискальная память:</strong> Энергонезависимая, может поддерживать до 1825 ежедневных отчетов<br>-->
<!--          <strong>Способы оплаты:</strong> Наличные, чек *, карточка *, кредит * (* программируемый)<br>-->
<!--          <strong>Денежные средства</strong> с альтернативной валюты<br>-->
<!--          <strong>Tипы штрих-кода:</strong> EAN 13, EAN 8, UPC, Отпечатано электронными весами<br>-->
<!--          <strong>Максимальное количество продаж в одной квитанции</strong> Более 380<br>-->
<!--          <strong>Cвязь:</strong> 2 х RS-232C порт для подключения:<br>-->
<!--          – Считыватель бар-кода<br>-->
<!--          – Цифровая шкала<br>-->
<!--          – Внешний дисплей покупателя<br>-->
<!--          – ПК<br>-->
<!--          <strong>1 х USB класс</strong> устройства через мини-USB (опция)<br>-->
<!--          <strong>Контроль Ящика 1 х </strong><br>-->
<!--          <strong>Bluetooth</strong> (опция)<br>-->
<!--          <strong>GPRS</strong> (опция)<br>-->
<!--          <strong>Ethernet</strong> (опция)<br>-->
<!--          <strong>Источник питания:</strong> Входное напряжение: AC 110 / 240В ~, 50-60 Гц<br>-->
<!--          <strong>i. Выход:</strong> DC 9V / 5A<br>-->
<!--          <strong>Aккумулятор:</strong> Литий-ионный аккумулятор емкостью 2000 мАч 7.4V<br>-->
<!--          <strong>Размеры:</strong> (ДхШхВ) 160 х 216 х 97 мм<br>-->
<!--          <strong>Bес:</strong> 570 г</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->



<!--</div>-->























