import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-hotel-page',
  templateUrl: './auto-hotel-page.component.html',
  styles: [
  ]
})
export class AutoHotelPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
