<div class="pb-2">
  <div class="container grey lighten-4 pt-4 wow fadeInLeft" data-wow-delay="0.6s">
    <!-- Section: Block Content -->
    <section>
      <!-- Grid row -->
      <div class="row no-gutters">
        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/1.png" class="img-fluid" alt="Sample image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle1' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText1' | translate}}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/2.png" class="img-fluid" alt="Sample image">
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a href="" class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle2' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText2' | translate}}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!-- Section: Block Content -->
  </div>
</div>


<div class="pb-2">
  <div class="container grey lighten-4 pt-4 wow fadeInRight" data-wow-delay="0.6s">
    <!-- Section: Block Content -->
    <section>
      <!-- Grid row -->
      <div class="row no-gutters">
        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/3.png" class="img-fluid" alt="Sample image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle3' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText3' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 mb-4 pt-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/4.png" class="img-fluid" alt="Sample image">
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a href="" class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle4' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText4' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!-- Section: Block Content -->
  </div>
</div>


<div class="pb-2">
  <div class="container grey lighten-4 pt-4 wow fadeInLeft" data-wow-delay="0.6s">
    <!-- Section: Block Content -->
    <section>
      <!-- Grid row -->
      <div class="row no-gutters">
        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/5.png" class="img-fluid" alt="Sample image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle5' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText5' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/6.png" class="img-fluid" alt="Sample image">
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a href="" class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle6' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText6' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!-- Section: Block Content -->
  </div>
</div>


<div class="pb-2">
  <div class="container grey lighten-4 pt-4 wow fadeInRight" data-wow-delay="0.6s">
    <!-- Section: Block Content -->
    <section>
      <!-- Grid row -->
      <div class="row no-gutters">
        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/7.png" class="img-fluid" alt="Sample image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle7' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText7' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/8.png" class="img-fluid" alt="Sample image">
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a href="" class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle8' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText8' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!-- Section: Block Content -->
  </div>
</div>


<div class="pb-2">
  <div class="container grey lighten-4 pt-4 wow fadeInLeft" data-wow-delay="0.6s">
    <!-- Section: Block Content -->
    <section>
      <!-- Grid row -->
      <div class="row no-gutters">
        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/viber_2020-01-.jpg" class="img-fluid" alt="Sample image">
                  <a>
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle9' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText9' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 mb-4">
          <!-- Card -->
          <div class="">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                <div class="view overlay">
                  <img src="assets/flagman/viber_2020-01-3.jpg" class="img-fluid" alt="Sample image">
                </div>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0 px-md-3 pl-lg-0">
                <div class="">
                  <div class="card-body">
                    <h5 class="card-title mb-1">
                      <strong>
                        <a href="" class="dark-grey-text">
                          {{'Flagman.SixFlag.IconTitle10' | translate}}
                        </a>
                      </strong>
                    </h5>
                    <p class="card-title mb-1">
                    <p>
                      <a class="dark-grey-text">
                        {{'Flagman.SixFlag.IconText10' | translate}}
                    </a>
                  </p>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Card -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!-- Section: Block Content -->
  </div>
</div>
