<div class="bgabout wow fadeInLeft" data-wow-delay="0.6s">
  <section class=" flex-center">
    <div class="container flex-center">
      <div class="col-md-4 mx-auto pl-5 order-2">
        <div class="view mb-0 pb-0">
          <img src="assets/27.png" class="img-fluid pt-5 wow fadeInRight" data-wow-delay="1.6s" alt="smaple image">
        </div>
      </div>

      <section class=" px-md-5 mx-md-5 dark-grey-text pt-5">

        <div class="row mb-5">
          <div class="col-md-12 mb-4 order-1">

            <div class="col-md-12 mb-4">
              <h4 class="font-weight-bold white-text wow fadeInLeft" data-wow-delay="1.6s">
                {{'About.Header.Title' | translate}}
              </h4>
            </div>
          </div>
        </div>

      </section>
    </div>
  </section>

</div>









