import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sixres',
  templateUrl: './sixres.component.html',
  styles: [
  ]
})
export class SixresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
