<div class="gotop">
<app-headeraurora></app-headeraurora>
</div>
<app-fullaurora></app-fullaurora>
<app-threepos></app-threepos>
<app-fouraurora></app-fouraurora>
<app-fiveaurora></app-fiveaurora>
<app-sixaurora></app-sixaurora>
<app-sevenpos></app-sevenpos>
<app-eightpos></app-eightpos>
<app-questions></app-questions>
<app-morepos></app-morepos>
<app-lastshoppos></app-lastshoppos>
