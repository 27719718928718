<div class="container grey lighten-4 py-5">
  <!--Section: Content-->
  <section class="text-center px-md-5 mx-md-5 dark-grey-text wow zoomIn" data-wow-delay="0.6s">
    <h3 class="font-weight-bold">
      {{'Flagman.Horeca1.Full.TitleTop' | translate}}
      <span class="blue-text">
        {{'Flagman.Horeca1.Full.TitleBottom' | translate}}
      </span></h3>
  </section>
</div>

<div class="container">


  <!--Section: Content-->
  <section class="text-center pt-3">

    <div class="row ">



      <div class="col-lg-6 wow fadeInLeft" data-wow-delay="0.6s">

        <!--Carousel Wrapper-->
        <div id="carousel-thumb1" class="carousel slide carousel-fade carousel-thumbnails mb-5 pb-4" data-ride="carousel">


          <mdb-carousel [isControls]="true" [animation]="'slide'">
            <!--First slide-->
            <mdb-slide>
              <img src="assets/flagman/horeca-1.png"
                   lightbox backgroundColor="white"
                   alt="First slide" class="img-fluid">
            </mdb-slide>

          </mdb-carousel>


        </div>
        <!--/.Carousel Wrapper-->

        <div lightbox-group  class="row mb-4 ">

          <figure class="col-md-3 border mr-3">
            <img src="assets/complect/hone/mini-pos-flagman.jpg"
                 lightbox
                 class="img-fluid">
          </figure>
          <figure class="col-md-3 border mr-3">
            <img src="assets/complect/hone/2-pos-compact1.png"
                 lightbox
                 class="img-fluid">
          </figure>
          <figure class="col-md-3 border mr-3">
            <img src="assets/complect/hone/1_viber_pos-complect.jpg"
                 lightbox
                 class="img-fluid">
          </figure>

        </div>
      </div>





      <div class="col-lg-6 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

        <h3 class="h3-responsive text-center text-md-left product-name font-weight-bold dark-grey-text mb-1 ml-xl-0 ml-4 pb-5">
          {{'Flagman.Horeca1.Full.ComplectTitle' | translate}}
        </h3>


        <div class="font-weight-normal">


          <h5 class="ml-xl-0 ml-4">
            <strong>1.</strong><span class="blue-text">{{'Flagman.Horeca1.Full.ComplectOne' | translate}} </span> {{'Flagman.Horeca1.Full.ComplectOneOne' | translate}}</h5>
          <h5 class="ml-xl-0 ml-4">
            <strong>2.</strong>{{'Flagman.Horeca1.Full.ComplectTwo' | translate}}</h5>
          <h5 class="ml-xl-0 ml-4">
            <strong>3.</strong>{{'Flagman.Horeca1.Full.ComplectThree' | translate}}</h5>
          <h5 class="ml-xl-0 ml-4 pb-1">
            <strong>4.</strong>{{'Flagman.Horeca1.Full.ComplectFour' | translate}}</h5>
          <hr>

          <div class="d-flex justify-content-between py-2">
            <div>
              <img src="assets/alexmarket/313131-check-30x30.png"><span>  {{'Flagman.Horeca1.Full.ComplectGiveOne' | translate}}</span>
            </div>
            <div>
              <img src="assets/alexmarket/313131-check-30x30.png"><span>  {{'Flagman.Horeca1.Full.ComplectGiveTwo' | translate}}</span>
            </div>
            <div>
              <img src="assets/alexmarket/313131-check-30x30.png"><span>  {{'Flagman.Horeca1.Full.ComplectGiveThree' | translate}}</span>
            </div>
          </div>
          <ng-container *ngIf="this.main.lang == 'ru'">
            <div class="py-1">
              <img src="assets/banner/techpod.jpg" style="height: 130px;">
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'en'">
            <div class="py-1">
              <img src="assets/banner/techpodEn.jpg" style="height: 130px;">
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'ro'">
            <div class="py-1">
              <img src="assets/banner/techpodRo.jpg" style="height: 130px;">
            </div>
          </ng-container>

          <h3 class="h3-responsive text-center text-md-left mb-3 ml-xl-0 ml-4 pt-3">
          <span class="red-text font-weight-bold">
            <strong class="pr-3">
              <span class="black-text">
              {{'Flagman.Horeca1.Full.ComplectPriceTitle' | translate}}
              </span>
              {{'Flagman.Horeca1.Full.ComplectPrice' | translate}}
            </strong>
          </span>
            <span class="grey-text">
            <small>
              <s>
                {{'Flagman.Horeca1.Full.ComplectOldPrice' | translate}}
              </s>
            </small>
          </span>
          </h3>

          <div class="mt-1">
            <div class="row  mb-2">
              <div class="col-md-12 text-center text-md-left ">
                <button class="btn btn-primary btn-rounded">
                  <i class="fas fa-cart-plus mr-2" aria-hidden="true"></i>
                  {{'BUTTONS.Order' | translate}}
                </button>
              </div>
            </div>
          </div>

        </div>



      </div>
    </div>

  </section>
  <!--Section: Content-->


</div>
