<section class="pb-5">
  <div class="container ">

    <!--Section heading-->
    <h2 class="h2 py-3 wow fadeInDown font-weight-bold text-center" data-wow-delay="0.6s">
      <strong>{{ 'HOME.CERTIFICATE.Title' | translate}}</strong>
    </h2>


    <!--Grid row-->
    <div class="row d-flex justify-content-center">

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light  wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <img src="assets/cert/am.jpg" class="img-fluid" alt="Sample project image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

      </div>
      <!--Grid column-->

<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1 d-none d-sm-block">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>-->
<!--          <img src="assets/cert/az.jpg" class="img-fluid" alt="Sample project image">-->
<!--          <a>-->
<!--            <div class="mask rgba-white-slight"></div>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1 d-none d-sm-block">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <img src="assets/cert/bcn.jpg" class="img-fluid" alt="Sample project image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

      </div>
      <!--Grid column-->


<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1 d-none d-sm-block">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>-->
<!--          <img src="assets/cert/bda.jpg" class="img-fluid" alt="Sample project image">-->
<!--          <a>-->
<!--            <div class="mask rgba-white-slight"></div>-->
<!--          </a>-->
<!--        </div>-->

<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->



      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1 d-none d-sm-block">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <img src="assets/cert/bsn.jpg" class="img-fluid" alt="Sample project image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <!--Grid column-->


      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1 d-none d-sm-block">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <img src="assets/cert/bxn.jpg" class="img-fluid" alt="Sample project image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <!--Grid column-->
    </div>

    <!--Grid row-->
    <div class="pt-3 text-center">
      <button [routerLink]="'/certifications'" class=" " mdbBtn type="button" color="primary"  mdbWavesEffect>{{'BUTTONS.AllCert' | translate}}</button>
    </div>
  </div>
</section>
<!--/Projects section v.1-->
