<!--Section: Content-->

<!-- Section heading -->
<h2 class="text-center font-weight-bold mb-4 pb-2   wow zoomIn" data-wow-delay="0.6s">
  {{'AlexMarket.Features.TitleTop' | translate}}
  <p>
    {{'AlexMarket.Features.TitleBottom' | translate}}
</h2>


<div class="d-none d-lg-block">
  <div class="container d-flex justify-content-between wow zoomIn" data-wow-delay="0.6s">
    <!-- Grid row -->
    <!-- Grid column -->

    <div class="col-lg-3">

      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleOne' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextOne' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->


      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleTwo' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextTwo' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </div>
    <!-- Grid column -->

    <div class="col-lg-1 pt-5  d-none d-md-block">
    </div>

    <!-- Grid column -->
    <div class="col-lg-4 pt-5 flex-center">
      <img class="img-fluid" src="assets/alexmarket/Logo AlexMarket PrintScreen.png"
           lightbox backgroundColor="white"
           alt="Sample image">
    </div>

    <div class="col-md-1 pt-5  ">
    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-4 ">


      <!-- Grid row -->
      <div class="row mb-3 ">

        <!-- Grid column -->
        <div class="col-1 pt-1 mr-4">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-8 pt-1 ">
          <h5 class="font-weight-bold mb-3 p">
            {{'AlexMarket.Features.SpecTitleThree' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextThree' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-1 pt-1 mr-4">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-8 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleFour' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextFour' | translate}}
          </p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->


    </div>
    <!-- Grid column -->


  </div>
  <!-- Grid row -->
</div>


<div class="container 	d-block d-lg-none">

  <div class="col-lg-3">

    <!-- Grid row -->
    <div class="row mb-3">

      <!-- Grid column -->
      <div class="col-2 pt-1">
        <i class="fas fa-2x  fa-film blue-text"></i>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-10 pt-1">
        <h5 class="font-weight-bold mb-3">
          {{'AlexMarket.Features.SpecTitleOne' | translate}}
        </h5>
        <p class="black-text ">
          {{'AlexMarket.Features.SpecTextOne' | translate}}
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->


    <!-- Grid row -->
    <div class="row mb-3 wow zoomIn" data-wow-delay="0.6s">

      <!-- Grid column -->
      <div class="col-2 pt-1">
        <i class="fas fa-2x  fa-film blue-text"></i>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-10 pt-1">
        <h5 class="font-weight-bold mb-3">
          {{'AlexMarket.Features.SpecTitleTwo' | translate}}
        </h5>
        <p class="black-text ">
          {{'AlexMarket.Features.SpecTextTwo' | translate}}
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Grid column -->

  <div class="col-lg-1 pt-5  d-none d-md-block">
  </div>

  <!-- Grid column -->
  <div class="col-lg-4 pt-5 flex-center">
    <img class="img-fluid" src="assets/alexmarket/2-screen2-600x456.png"
         alt="Sample image">
  </div>

  <div class="col-md-1 pt-5  ">
  </div>
  <!-- Grid column -->

  <!-- Grid column -->
  <div class="col-lg-4 ">


    <!-- Grid row -->
    <div class="row mb-3 ">

      <!-- Grid column -->
      <div class="col-1 pt-1 mr-4">
        <i class="fas fa-2x  fa-film blue-text"></i>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-8 pt-1 ">
        <h5 class="font-weight-bold mb-3 p">
          {{'AlexMarket.Features.SpecTitleThree' | translate}}
        </h5>
        <p class="black-text ">
          {{'AlexMarket.Features.SpecTextThree' | translate}}
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

    <!-- Grid row -->
    <div class="row mb-3">

      <!-- Grid column -->
      <div class="col-1 pt-1 mr-4">
        <i class="fas fa-2x  fa-film blue-text"></i>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-8 pt-1">
        <h5 class="font-weight-bold mb-3">
          {{'AlexMarket.Features.SpecTitleFour' | translate}}
        </h5>
        <p class="black-text ">
          {{'AlexMarket.Features.SpecTextFour' | translate}}
        </p>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->


  </div>
  <!-- Grid column -->


</div>


<div class="flex-center">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 container wow zoomIn" data-wow-delay="0.6s">
    <div class="col">
      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleFive' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextFive' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
    <div class="col">
      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleSix' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextSix' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
    <div class="col">
      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleSeven' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextSeven' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
    <div class="col">
      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-2 pt-1">
          <i class="fas fa-2x  fa-film blue-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-10 pt-1">
          <h5 class="font-weight-bold mb-3">
            {{'AlexMarket.Features.SpecTitleEight' | translate}}
          </h5>
          <p class="black-text ">
            {{'AlexMarket.Features.SpecTextEight' | translate}}
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
  </div>
</div>
<!--Section: Content-->


