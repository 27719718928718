<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'Flagman.Horeca1.RP58.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'Flagman.Horeca1.RP58.TitleBottom' | translate}}
  </p>
  <div class="row flex-center">

    <div class="col order-1 wow fadeInRight" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-rocket fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.RP58.SpecTitleOne' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.RP58.SpecTextOne' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-search-plus fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.RP58.SpecTitleTwo' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.RP58.SpecTextTwo' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-chart-area fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca1.RP58.SpecTitleThree' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca1.RP58.SpecTextThree' | translate}}
      </p>

    </div>


    <div class="col-lg-6 order-3 wow fadeInLeft" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/complect/hone/1_viber_pos-complect.jpg" alt="Sample image">

    </div>



    <div class="col order-2 wow fadeInRight" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-power-off fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.RP58.SpecTitleFour' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.RP58.SpecTextFour' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-clock fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.RP58.SpecTitleFive' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.RP58.SpecTextFive' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-cog fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca1.RP58.SpecTitleSix' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca1.RP58.SpecTextSix' | translate}}
      </p>




    </div>

  </div>

  <button mdbBtn color="primary" class="waves-light mt-0 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec'| translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
          <p style="text-align: left;"><strong>Тип принтера:</strong> Термопринтер<br>
            <strong>Способ печати:</strong> Прямая Термопечать<br>
            <strong>Скорость печати, мм/сек:</strong> 100<br>
            <strong>Разрешение печати, dpi:</strong> 203<br>
            <strong>Ширина печати, мм:</strong> 48<br>
            <strong>Наработка на отказ, км:</strong> 100<br>
            <strong>Ширина ленты (этикетки), мм:</strong> 57.5±0.5<br>
            <strong>Максимальный диаметр ленты, мм:</strong> 80<br>
            <strong>Поддержка 2D штрих-кодов:</strong> Нет<br>
            <strong>Поддержка линейных штрих-кодов:</strong> UPC-A, UPC-E, EAN-8, EAN-13, ITF, CODABAR , CODE93, CODE128, CODE 39<br>
            <strong>Система команд управления:</strong> ESC, POS<br>
            <strong>Интерфейсы:</strong> LAN (Ethernet)<br>
            <strong>Подключение денежного ящика:</strong> 1хRJ-11<br>
            <strong>Датчики:</strong> Окончания Бумаги<br>
            <strong>Автообрезка:</strong> Нет<br>
            <strong>Габаритные размеры, мм:</strong> 210x135x130</p>

      </div>
    </div>
  </div>


</div>
























