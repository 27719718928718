<section class="bgdivb wow fadeInLeftBig" data-wow-delay="0.6s">
  <div class="mask rgba-blue-light">
    <div class="pb-3 pt-3">
      <mdb-card-body cascade="true" class="text-center">
        <!--Title-->
        <mdb-card-title class="pt-0">
          <h2 class="pt-0">
            <strong class="white-text wow zoomIn" data-wow-delay="0.6s">
              {{'B52.Div.Title' | translate}}
            </strong>
          </h2>

        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>
</section>

