<div class="black-text" style="font-family: 'Times New Roman'">
  <div class="container pt-2">
    <!--    <mdb-breadcrumb-item>-->
    <!--      <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>-->
    <!--      <mdb-breadcrumb-item  [routerLink]="'/auto-index'">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>-->
    <!--      <mdb-breadcrumb-item class="active">{{'CarWeights.StaticOc.Breadcrumb' | translate}}</mdb-breadcrumb-item>-->
    <!--    </mdb-breadcrumb-item>-->

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-7 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-4 pb-5">
            <div [innerHTML]="'AutomaticSystem.Header.Title'  | translate"></div>
          </h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'AutomaticSystem.Header.SubTitle' | translate}}
          </h5>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-5 mb-3">
          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/automaticSystem/logo.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <h3 class="mb-3">{{'AutomaticSystem.Header.FuncTitle' | translate}}</h3>

        <ul class="mb-4">
          <div [innerHTML]="'AutomaticSystem.Header.FuncText'  | translate"></div>
        </ul>
      </h5>
    </div>

  </section>


  <section class="pt-4">
    <div class="container">
      <div class="row flex-center" lightbox-group>
        <div class="col-md-6 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'AutomaticSystem.TableOne.TitleOne' | translate}}
            </h5>
            <div class="flex-center">
              <a>
                <ng-container *ngIf="this.main.lang == 'ru'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/table2Ru.jpg" alt="Sample image">
                  </div>
                </ng-container>
                <ng-container *ngIf="this.main.lang == 'en'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/table2En.jpg" alt="Sample image">
                  </div>
                </ng-container>
                <ng-container *ngIf="this.main.lang == 'ro'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/table2Ro.jpg" alt="Sample image">
                  </div>
                </ng-container>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'AutomaticSystem.TableOne.TitleTwo' | translate}}
            </h5>
            <div class="flex-center">
              <a>
                <ng-container *ngIf="this.main.lang == 'ru'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/tableRu.jpg" alt="Sample image">
                  </div>
                </ng-container>
                <ng-container *ngIf="this.main.lang == 'en'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/tableEn.jpg" alt="Sample image">
                  </div>
                </ng-container>
                <ng-container *ngIf="this.main.lang == 'ro'">
                  <div class="py-1">
                    <img lightbox class="img-fluid" style="max-height: 270px" src="assets/automaticSystem/tableRo.jpg" alt="Sample image">
                  </div>
                </ng-container>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container my-5">

      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-0">
          <i class="fas fa-share fa-lg red-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-xl-10 col-md-11 col-10">
          <h5 class="font-weight-bold mb-3"><em>{{'AutomaticSystem.TableOne.DescOne' | translate}}</em></h5>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

      <!-- Grid row -->
      <div class="row mb-3">

        <!-- Grid column -->
        <div class="col-0">
          <i class="fas fa-share fa-lg yellow-text"></i>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-xl-10 col-md-11 col-10">
          <h5 class="font-weight-bold mb-3"><em>{{'AutomaticSystem.TableOne.DescTwo' | translate}}</em></h5>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
  </section>


  <div class="container mt-4">


    <!--Section: Content-->
    <section class="">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-4 col-md-12 mb-lg-0 mb-4">

          <!-- Featured image -->
          <div lightbox-group class="view overlay rounded  mb-lg-0 mb-4">
            <a>
              <img lightbox class="img-fluid" src="assets/automaticSystem/plan.jpg" alt="Sample image">
            </a>
          </div>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-7 col-md-6 mb-md-0 mb-4 mt-xl-5">
          <h5 class=" mb-4"><em>{{'AutomaticSystem.ImageList.One' | translate}}</em></h5>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
      <hr class="mx-5">

    </section>
    <!--Section: Content-->


  </div>


  <div class="container mt-4">


    <!--Section: Content-->
    <section class="">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="order-2 col-lg-4 col-md-12 mb-lg-0 mb-4">

          <!-- Featured image -->
          <div lightbox-group class="view overlay rounded  mb-lg-0 mb-4">
            <a>
              <img lightbox class="img-fluid" src="assets/automaticSystem/image table.jpg" alt="Sample image">
            </a>
          </div>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="order-1 col-lg-7 col-md-6 mb-md-0 mb-4 mt-xl-5">
          <h5 class=" mb-5"><em>{{'AutomaticSystem.ImageList.Two' | translate}}</em></h5>
          <h5 class=" mt-5"><em>{{'AutomaticSystem.ImageList.TwoTwo' | translate}}</em></h5>
        </div>
        <!-- Grid column -->


      </div>
      <!-- Grid row -->
      <hr class="mx-5">

    </section>
    <!--Section: Content-->


  </div>


  <div class="container mt-4">


    <!--Section: Content-->
    <section class="">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-4 col-md-12 mb-lg-0 mb-4">

          <!-- Featured image -->
          <div lightbox-group class="view overlay rounded  mb-lg-0 mb-4">
            <a>
              <img lightbox class="img-fluid" src="assets/automaticSystem/three.jpg" alt="Sample image">
            </a>
          </div>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-7 col-md-6 mb-md-0 mb-4 mt-xl-5">
          <h5 class=" mb-4"><em>{{'AutomaticSystem.ImageList.Three' | translate}}</em></h5>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
      <hr class="mx-5">

    </section>
    <!--Section: Content-->


  </div>


  <section class="container mt-2">
    <div style="font-family: 'Times New Roman'">
      <div class="black-text">
        <h3 class="mb-3">{{'AutomaticSystem.ImageList.FuncTitle' | translate}}</h3>

        <ul class="mb-4">
          <h5><div [innerHTML]="'AutomaticSystem.ImageList.FuncText'  | translate"></div></h5>
        </ul>
      </div>
    </div>

  </section>

  <section class="container mt-2">
    <div style="font-family: 'Times New Roman'">
      <div class="black-text">
        <h3 class="mb-3">{{'AutomaticSystem.ImageList.FuncTwoTitle' | translate}}</h3>

        <ul class="mb-4">
          <h5><div class="" [innerHTML]="'AutomaticSystem.ImageList.FuncTwoText'  | translate"></div></h5>
        </ul>
      </div>
    </div>

  </section>


  <div class="container mt-4">


    <!--Section: Content-->
    <section class="">
      <div class="flex-center">
        <h3 class="mb-3">{{'AutomaticSystem.Set.Title' | translate}}</h3>
      </div>
      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-4 col-md-12 mb-lg-0 mb-4">

          <!-- Featured image -->
          <div lightbox-group class="view overlay rounded  mb-lg-0 mb-4">
            <a>
              <ng-container *ngIf="this.main.lang == 'ru'">
                <div class="py-1">
                  <img lightbox class="img-fluid" src="assets/automaticSystem/transportRu.jpg" alt="Sample image">
                </div>
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'en'">
                <div class="py-1">
                  <img lightbox class="img-fluid" src="assets/automaticSystem/transportEn.jpg" alt="Sample image">
                </div>
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'ro'">
                <div class="py-1">
                  <img lightbox class="img-fluid" src="assets/automaticSystem/transportEn.jpg" alt="Sample image">
                </div>
              </ng-container>
            </a>
          </div>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-8 col-md-6 mb-md-0 mb-4 mt-xl-5">
          <div class="mb-4">
            <ul class="mb-4">
              <h5><div [innerHTML]="'AutomaticSystem.Set.Text'  | translate"></div></h5>
            </ul>
            <ul class="mb-4">
              <h5><div [innerHTML]="'AutomaticSystem.Set.TextTwo'  | translate"></div></h5>
            </ul>
            <ul class="mb-4">
              <h5><div [innerHTML]="'AutomaticSystem.Set.TextThree'  | translate"></div></h5>
            </ul>
            <ul class="mb-4">
              <h5><div [innerHTML]="'AutomaticSystem.Set.TextFour'  | translate"></div></h5>
            </ul>
            <ul class="mb-4">
              <h5><div [innerHTML]="'AutomaticSystem.Set.TextFive'  | translate"></div></h5>
            </ul>
          </div>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <h4 class="text-center py-3">
        {{'AutomaticSystem.Set.ImageTitle' | translate}}
      </h4>
      <div class="view overlay rounded  mt-1 mb-5">
        <a>
          <ng-container *ngIf="this.main.lang == 'ru'">
            <div class="py-1">
              <img lightbox src="assets/automaticSystem/controlRu.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'en'">
            <div class="py-1">
              <img lightbox src="assets/automaticSystem/controlEn.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'ro'">
            <div class="py-1">
              <img lightbox src="assets/automaticSystem/controlRo.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="">

        <h5><div class="" [innerHTML]="'AutomaticSystem.Set.ImageSpec'  | translate"></div></h5>

    </div>
  </div>

  <div class="container">
    <h3 class=" mb-4">{{'AutomaticSystem.Table2.Title' | translate}}</h3>

    <table class="table">
      <h5>
      <tbody>
      <tr>
        <th scope="row">1</th>
        <td>{{'AutomaticSystem.Table2.One1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.One2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">2</th>
        <td>{{'AutomaticSystem.Table2.Two1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Two2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">3</th>
        <td>{{'AutomaticSystem.Table2.Three1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Three2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">4</th>
        <td>{{'AutomaticSystem.Table2.Four1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Four2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">5</th>
        <td>{{'AutomaticSystem.Table2.Five1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Five2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">6</th>
        <td>{{'AutomaticSystem.Table2.Six1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Six2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">7</th>
        <td>{{'AutomaticSystem.Table2.Seven1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Seven2' | translate}}</td>
      </tr>

      <tr>
        <th scope="row">8</th>
        <td>{{'AutomaticSystem.Table2.Eight1' | translate}}</td>
        <td>{{'AutomaticSystem.Table2.Eight2' | translate}}</td>
      </tr>
      </tbody>
        </h5>
    </table>
  </div>


  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <h4 class="text-center py-1">
        {{'AutomaticSystem.Schema.Title' | translate}}
      </h4>
      <div class="view overlay rounded  mt-1 mb-2">
        <a>
          <ng-container *ngIf="this.main.lang == 'ru'">
            <div class="py-1">
              <img style="max-height: 500px" lightbox src="assets/automaticSystem/schemaRu.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'en'">
            <div class="py-1">
              <img style="max-height: 500px" lightbox src="assets/automaticSystem/schemaEn.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
          <ng-container *ngIf="this.main.lang == 'ro'">
            <div class="py-1">
              <img style="max-height: 500px" lightbox src="assets/automaticSystem/schemaRo.jpg" class="img-fluid" alt="Sample project image"/>
            </div>
          </ng-container>
        </a>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <h4 class="text-center py-1">
        {{'AutomaticSystem.Document.Title' | translate}}
      </h4>
      <div class="view overlay rounded  mt-1 mb-2">
        <a>
          <img style="max-height: 700px" lightbox src="assets/automaticSystem/doc.jpg" class="img-fluid" alt="Sample project image"/>
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <h5 class="mb-3">{{'AutomaticSystem.CertInfo.Title' | translate}}</h5>

    <ul class="mb-4">
      <h5><div class="" [innerHTML]="'AutomaticSystem.CertInfo.Text'  | translate"></div></h5>
    </ul>
  </div>

  <div class="container">
    <h5 class="mb-3">{{'AutomaticSystem.Last.Title' | translate}}</h5>

    <h5 class="mb-3">
     <ul class="fa-ul mb-5">
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text1' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text2' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text3' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text4' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text5' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text6' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text7' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text8' | translate}}</li>
        <li class="mb-2"><span class="fa-li"><i class="fas fa-check green-text"></i></span>{{'AutomaticSystem.Last.Text9' | translate}}</li>
      </ul>
    </h5>
  </div>

</div>
