<div class="bgcomplecthead wow zoomIn" data-wow-delay="0.1s">
  <mdb-card-body cascade="true" class="text-center">
    <!--Title-->
    <mdb-card-title>
      <h1 class="pt-5">
        <strong class="white-text wow fadeInDown" data-wow-delay="0.6s">
          {{'Flagman.Horeca1.Header.Title' | translate}}
          <p class=" wow zoomIn" data-wow-delay="1.0s"></strong>
      </h1>
    </mdb-card-title>

    <mdb-card-text class="black-text pt-5 container wow fadeInDown" data-wow-delay="1.4s">
      <div class="row text-center d-flex justify-content-center">

        <div class="col-lg-2 col-md-6 mb-lg-0 mb-5">
          <img class="img-fluid" src="assets/complect/hone/cafe-1.png" alt="Sample image">
          <h6 class="font-weight-bold mb-4 white-text">{{'Flagman.Horeca1.Header.One' | translate}}</h6>

        </div>
        <div class="col-lg-2 col-md-6 mb-lg-0 mb-5">
          <img class="img-fluid" src="assets/complect/hone/zakusechnie.png" alt="Sample image">
          <h6 class="font-weight-bold mb-4 white-text">{{'Flagman.Horeca1.Header.Two' | translate}}</h6>

        </div>
        <div class="col-lg-2 col-md-6 mb-md-0 mb-5">
          <img class="img-fluid" src="assets/complect/hone/bar.png" alt="Sample image">
          <h6 class="font-weight-bold mb-4 white-text">{{'Flagman.Horeca1.Header.Three' | translate}}</h6>

        </div>
        <div class="col-lg-2 col-md-6 mb-md-0 mb-5">
          <img class="img-fluid" src="assets/complect/hone/coffeteria.png" alt="Sample image">
          <h6 class="font-weight-bold mb-4 white-text">{{'Flagman.Horeca1.Header.Four' | translate}}</h6>
        </div>
        <div class="col-lg-2 col-md-6 mb-md-0 mb-5">
          <img class="img-fluid" src="assets/complect/hone/res.png" alt="Sample image">
          <h6 class="font-weight-bold mb-4 white-text">{{'Flagman.Horeca1.Header.Five' | translate}}</h6>
        </div>

      </div>
    </mdb-card-text>
  </mdb-card-body>
</div>












