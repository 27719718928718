<div class="container pl-5">
<div class="col-lg-12 mb-4">
  <ol class="step pl-0 pt-5">
    <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
      <div class="step-number ">

              <span class="fa-stack fa-2x green  green-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
      </div>
      <div class="step-excerpt">
        <h6 class="font-weight-bold black-text  pt-3">
          {{'Flagman.Flag.IconTitle1' | translate }}
        </h6>
        <p>
          {{'Flagman.Flag.IconText1' | translate }}
        </p>
      </div>
    </li>
    <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
      <div class="step-number pt-3">
              <span class="fa-stack fa-2x green  green-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
      </div>
      <div class="step-excerpt pt-3">
        <h6 class="font-weight-bold black-text  pt-3">
          {{'Flagman.Flag.IconTitle2' | translate }}
        </h6>
        <p>
          {{'Flagman.Flag.IconText2' | translate }}
        </p>
      </div>
    </li>
    <li class="step-element   pt-3 wow zoomIn" data-wow-delay="0.6s">
      <div class="step-number pt-3 ">
              <span class="fa-stack fa-2x green  green-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
      </div>
      <div class=" pt-3">
        <h6 class="font-weight-bold black-text  pt-3">
          {{'Flagman.Flag.IconTitle3' | translate }}
        </h6>
        <p>
          {{'Flagman.Flag.IconText3' | translate }}
        </p>
      </div>
    </li>


  </ol>

</div>
</div>



<style>
  .fa-play:before {
    margin-left: .3rem;
  }

  /* Steps */
  .step {
    list-style: none;
    margin: 0;
  }

  .step-element {
    display: flex;
    padding: 1rem 0;
  }

  .step-number {
    position: relative;
    width: 7rem;
    flex-shrink: 0;
    text-align: center;
  }

  .step-number .number {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }

  .step-number .number {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    border-radius: 10rem;
  }

  .step-number::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 48px;
    bottom: -2rem;
    margin-left: -1px;
    border-left: 2px dashed #000000;
  }

  .step .step-element:last-child .step-number::before {
    bottom: 1rem;
  }
</style>
