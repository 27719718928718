<div class="container gotop mt-5">
  <section class="dark-grey-text mb-5">
    <app-alerting></app-alerting>
    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-5 mb-lg-0 pb-lg-3 mb-4 order-2 wow zoomIn" data-wow-delay="0.6s">


        <!--Form with header-->
        <mdb-card>

          <mdb-card-body>
            <!--Header-->
            <div class="form-header ">
              <h3>
                <mdb-icon fas icon="envelope wow zoomIn" data-wow-delay="0.6s"></mdb-icon>
                {{'Contact.SendMessage.Title' |translate}}</h3>
            </div>

            <p class=" wow zoomIn" data-wow-delay="0.6s">{{'Contact.SendMessage.Text' |translate}}</p>
            <br>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <!--Body-->
              <div class="md-form">
                <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
                <input
                  mdbInput
                  formControlName="name"
                  type="text"
                  id="name"
                  class="form-control"
                  [ngClass]="{'invalid': form.get('name').invalid && form.get('name').touched }"
                >
                <span
                  class="helper-text red-text"
                  *ngIf="form.get('name').invalid && form.get('name').touched"
                >
                    <span *ngIf="form.get('name').errors['required']">
                      Имя не может быть пустым
                    </span>
                    </span>
                <label for="name">{{'Contact.SendMessage.Name' |translate}}</label>

              </div>

              <div class="md-form">
                <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
                <input
                  mdbInput
                  formControlName="email"
                  type="text"
                  id="email"
                  class="form-control"
                  [ngClass]="{'invalid': form.get('email').invalid && form.get('email').touched }"
                >
                <span
                  class="red-text "
                  *ngIf="form.get('email').touched && form.get('email').invalid"
                >
                <span *ngIf="form.get('email').errors.required">
                  Email не может быть пустым
                </span>
                <span *ngIf="form.get('email').errors.email">
                  Пожалуйста. введите правильный Email адрес
                </span>
              </span>
                <label for="email">{{'Contact.SendMessage.Email' |translate}}</label>
              </div>

              <div class="md-form">
                <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
                <input
                  mdbInput
                  formControlName="subject"
                  type="text"
                  id="subject"
                  class="form-control"
                  [ngClass]="{'invalid': form.get('subject').invalid && form.get('subject').touched }"
                >
                <span
                  class="helper-text red-text"
                  *ngIf="form.get('subject').invalid && form.get('subject').touched"
                >
                    <span *ngIf="form.get('subject').errors['required']">
                      Тема не может быть пустой
                    </span>
                    </span>
                <label for="subject">{{'Contact.SendMessage.Theme' |translate}}</label>
              </div>

              <div class="md-form">
                <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                <textarea
                  mdbInput
                  formControlName="message"
                  type="text"
                  id="message"
                  class="md-textarea"
                  [ngClass]="{'invalid': form.get('message').invalid && form.get('message').touched }"
                >
              </textarea>
                <span
                  class="helper-text red-text"
                  *ngIf="form.get('message').invalid && form.get('message').touched"
                >
                    <span *ngIf="form.get('message').errors['required']">
                      Сообщение не может быть пустым
                    </span>
                    </span>
                <label for="message">{{'Contact.SendMessage.Content' |translate}}</label>
              </div>
              <div class="text-center">
                <button
                  [disabled]="form.invalid || form.disabled"
                  data-toggle="modal"
                  data-target="#basicExample"
                  class="btn btn-primary btn-lg btn-block"
                  type="submit"
                >
                  {{'BUTTONS.Send' | translate}}
                </button>
              </div>
            </form>
          </mdb-card-body>

        </mdb-card>
        <!--Form with header-->


      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-7 order-1">
        <!-- Section heading -->
        <h3 class="font-weight-bold text-left mb-4 wow fadeInLeft" data-wow-delay="0.6s">{{'Contact.Header.Title' | translate}}</h3>
        <!-- Section description -->
        <p class="text-left  pb-0 wow fadeInLeft" data-wow-delay="0.6s">
          {{'Contact.Header.Text' | translate}}
        </p>

        <!-- Buttons-->
        <div class="row ">


          <div class="col-lg-6 mb-4">
            <ol class="step pl-0 pt-0">
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number ">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-envelope fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt">
                  <h6 class="font-weight-bold black-text  pt-0">{{'Contact.Header.TitleIconOne' | translate}}</h6>
                  <p>{{'Contact.Header.TextIconOne' | translate}}</p>
                </div>
              </li>
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number pt-4">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-phone fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt pt-0">
                  <h6 class="font-weight-bold black-text  pt-3">{{'Contact.Header.TitleIconTwo' | translate}}</h6>
                  <p><a href="tel:+37322244572">{{'Contact.Header.TextIconTwo' | translate}}</a></p>
                </div>
              </li>
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number pt-4">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-phone fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt pt-0 wow zoomIn" data-wow-delay="0.6s">
                  <h6 class="font-weight-bold black-text  pt-3">{{'Contact.Header.TitleIconThree' | translate}}</h6>
                  <p><a href="tel:+37369900989">{{'Contact.Header.TextIconThree' | translate}}</a> </p>
                </div>
              </li>
            </ol>
          </div>


          <div class="col-lg-6 mb-4">
            <ol class="step pl-0 pt-0">
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number ">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-print fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt">
                  <h6 class="font-weight-bold black-text  pt-0">{{'Contact.Header.TitleIconFour' | translate}}</h6>
                  <p>{{'Contact.Header.TextIconFour' | translate}}</p>
                </div>
              </li>
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number pt-4">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-cog fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt pt-3">
                  <h6 class="font-weight-bold black-text  pt-0">{{'Contact.Header.TitleIconFive' | translate}}</h6>
                  <p><a href="tel:+37322243087">{{'Contact.Header.TextIconFive' | translate}}</a></p>
                </div>
              </li>
              <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
                <div class="step-number pt-4">
              <span class="fa-stack fa-2x blue  blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-phone fa-stack-1x fa-inverse"></i>
              </span>
                </div>
                <div class="step-excerpt pt-3">
                  <h6 class="font-weight-bold black-text  pt-0">{{'Contact.Header.TitleIconSix' | translate}}</h6>
                  <p><a href="tel:+37360001103">{{'Contact.Header.TextIconSix' | translate}}</a></p>
                </div>
              </li>
            </ol>
          </div>


        </div>
        <h3 class="font-weight-bold text-center mb-4 wow fadeInLeft" data-wow-delay="0.6s">
          {{'Contact.Header.TextBottom' | translate}}
        </h3>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->


</div>
<!--Section: Content-->

<div class="container">
  <div class="row d-flex justify-content-center">

    <div class="col-md-5 col-lg-5 col-xl-3 wow zoomIn" data-wow-delay="1.6s">
      <h5 class="font-weight-bold border-bottom border-secondary pt-4 mb-4"><a class="dark-grey-text">
        {{'Contact.Office.Balti.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Balti.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Balti.Contact'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Balti.Time'  | translate"></div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 col-lg-4 col-xl-3 wow zoomIn" data-wow-delay="1.6s">
      <h5 class="font-weight-bold border-bottom border-secondary pt-4 mb-4"><a class="dark-grey-text">
        {{'Contact.Office.Comrat.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Comrat.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Comrat.Contact'  | translate"></div>
          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Comrat.Time'  | translate"></div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 col-lg-4 col-xl-3 wow zoomIn" data-wow-delay="1.6s">
      <h5 class="font-weight-bold border-bottom border-secondary pt-4 mb-4"><a class="dark-grey-text">
        {{'Contact.Office.Drocia.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Drocia.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Drocia.Contact'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Drocia.Time'  | translate"></div>

          </div>
        </div>
      </div>
    </div>


    <div class="col-md-5 col-lg-4 col-xl-3 wow zoomIn" data-wow-delay="1.6s">
      <h5 class="font-weight-bold border-bottom border-secondary pt-4 mb-4"><a class="dark-grey-text" href="#">
        {{'Contact.Office.Ungheni.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Ungheni.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Ungheni.Contact'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Ungheni.Time'  | translate"></div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-md-5 col-lg-4 col-xl-3  wow zoomIn" data-wow-delay="1.6s">
      <h5 class="font-weight-bold border-bottom border-secondary pt-0 mb-4"><a class="dark-grey-text" href="#">
        {{'Contact.Office.Tiraspol.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Tiraspol.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Tiraspol.Contact'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Tiraspol.Time'  | translate"></div>

          </div>
        </div>
      </div>

      <h5 class="font-weight-bold border-bottom border-secondary pt-4 mb-4"><a class="dark-grey-text">
        {{'Contact.Office.Ucraina.Title' | translate}}
      </a></h5>
      <div class="wpb_wrapper"><h5 style="text-align: left" class="vc_custom_heading"></h5>
        <div
          class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_chino">
          <span class="vc_sep_holder vc_sep_holder_l"><span class="vc_sep_line"></span></span><span
          class="vc_sep_holder vc_sep_holder_r"><span class="vc_sep_line"></span></span>
        </div>
        <div class="wpb_single_image wpb_content_element vc_align_left">

          <figure class="wpb_wrapper vc_figure">
            <div class="vc_single_image-wrapper   vc_box_border_grey"></div>
          </figure>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Ucraina.Adress'  | translate"></div>

          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
            <div [innerHTML]="'Contact.Office.Ucraina.Contact'  | translate"></div>
          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">

          </div>
        </div>
      </div>
    </div>



    <div class="col-md-5 col-lg-4 col-xl-9 wow zoomIn" data-wow-delay="1.6s">
      <!--Google map-->
      <div id="map-container-section" class="z-depth-1-half map-container-section mb-4" style="height: 400px">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.409922412399!2d28.833268315432562!3d47.03218651284877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c4b32635d1b%3A0x55a41cd8fa072219!2sAlex%20S%26E!5e0!3m2!1sru!2s!4v1576842217558!5m2!1sru!2s"
          frameborder="0"
          style="border:0" allowfullscreen></iframe>
      </div>
    </div>

  </div>

</div>


<style>
  .map-container-section {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .map-container-section iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }


  .fa-play:before {
    margin-left: .3rem;
  }

  /* Steps */
  .step {
    list-style: none;
    margin: 0;
  }

  .step-element {
    display: flex;
    padding: 1rem 0;
  }

  .step-number {
    position: relative;
    width: 7rem;
    flex-shrink: 0;
    text-align: center;
  }

  .step-number .number {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }

  .step-number .number {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    border-radius: 10rem;
  }

  .step-number::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 48px;
    bottom: -2rem;
    margin-left: -1px;
    border-left: 2px dashed #fff;
  }

  .step .step-element:last-child .step-number::before {
    bottom: 1rem;
  }
</style>
