import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-treeflag',
  templateUrl: './treeflag.component.html',
  styles: [
  ]
})
export class TreeflagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
