import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fivemark',
  templateUrl: './fivemark.component.html',
  styles: [
  ]
})
export class FivemarkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
