<!--Section: Features v.1-->
<section class="text-center " xmlns:color="http://www.w3.org/1999/xhtml">
  <div class="container text-center ">
    <!--Grid row-->
    <div class="row pt-5 flex-center">

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>

        <h4 class="font-weight-bold mt-4 red-text">{{'CarWeights.Category.IconTitleOne' | translate}}</h4>
        <p class="font-weight-bold blue-text">{{'CarWeights.Category.IconTextOne' | translate}}</p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
        <h4 class="font-weight-bold mt-4 red-text">{{'CarWeights.Category.IconTitleTwo' | translate}}</h4>
        <p class="font-weight-bold blue-text">{{'CarWeights.Category.IconTextTwo' | translate}}</p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
        <h4 class="font-weight-bold mt-4 red-text">{{'CarWeights.Category.IconTitleThree' | translate}}</h4>
        <p class="font-weight-bold blue-text">{{'CarWeights.Category.IconTextThree' | translate}}</p>
      </div>
      <!--Grid column-->

    </div>





  </div>


</section>
<!--Section: Features v.1-->
