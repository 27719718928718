<!--Section: Content-->
<section class="container pt-2">
  <mdb-breadcrumb-item>
    <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{'Bunker.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
  </mdb-breadcrumb-item>

  <h1 class="text-center font-weight-bold mb-4 pb-2 wow zoomIn" data-wow-delay="0.3s">
    {{'Bunker.Index.Title' | translate}}
  </h1>


</section>
<!--Section: Content-->

<div class="container flex-center pt-4">

  <div class="row">

    <div class="col-lg-4">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half pb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px;max-height: 330px" src="assets/industrial/bunker/1.jpg" alt="Sample image">
        <a [routerLink]="'/filling-batcher'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center">
          <h5 class="font-weight-bold">{{'Bunker.Index.Cat1' | translate}}</h5>
        </div>

      </div>


    </div>
    <div class="col-lg-4">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half pb-4 wow zoomIn" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/industrial/bunker/2.jpg" alt="Sample image">
        <a [routerLink]="'/bunker-scales'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">{{'Bunker.Index.Cat2' | translate}}</h5>
        </div>

      </div>

    </div>


    <div class="col-lg-4">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half  wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/industrial/bunker/lll.jpg" alt="Sample image">
        <a [routerLink]="'/modern-bunker-scales'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">{{'Bunker.Index.Cat3' | translate}}</h5>
        </div>

      </div>

    </div>

    <div class="w-100"></div>

  </div>

</div>


<!--Section: Features v.1-->
<section class="text-center ">
  <div class="container text-center ">
    <!--Grid row-->
    <div class="row pt-5 flex-center">

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>

        <h4 class="font-weight-bold mt-4 red-text">
          {{'Bunker.Index.IconTitleOne' | translate}}
        </h4>
        <p class="font-weight-bold blue-text">
          {{'Bunker.Index.IconTextOne' | translate}}
        </p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
        <h4 class="font-weight-bold mt-4 red-text">
          {{'Bunker.Index.IconTitleTwo' | translate}}
        </h4>
        <p class="font-weight-bold blue-text">
          {{'Bunker.Index.IconTextTwo' | translate}}
        </p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
        <h4 class="font-weight-bold mt-4 red-text">
          {{'Bunker.Index.IconTitleThree' | translate}}
        </h4>
        <p class="font-weight-bold blue-text">
          {{'Bunker.Index.IconTextThree' | translate}}
        </p>
      </div>
      <!--Grid column-->

    </div>





  </div>


</section>
<!--Section: Features v.1-->


<app-support-indus></app-support-indus>




<section class="container pt-2 wow zoomIn" data-wow-delay="0.3s">
  <div style="font-family: 'Times New Roman'">
    <h5>
      <div [innerHTML]="'Bunker.Index.Text'  | translate"></div>
    </h5>
  </div>

  <div class="row pt-5 pb-5 flex-center">


    <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInLeft" data-wow-delay="0.3s">
      <div class="avatar white d-flex justify-content-center align-items-center">

        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        <img style="max-height: 300px"
             lightbox backgroundColor="white" src="assets/cert/bxn.jpg"
             class="img-fluid z-depth-1"
        />
      </div>
      <div class="text-center mt-4">
        <p class="font-weight-bold mb-lg-0">
          {{'Certificates.BXN' | translate}}
        </p>
      </div>
    </div>




    <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInRight" data-wow-delay="0.3s">
      <div class="avatar white d-flex justify-content-center align-items-center">

        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        <img style="max-height: 300px"
             lightbox backgroundColor="white" src="assets/cert/bsn.jpg"
             class="img-fluid z-depth-1"
        />
      </div>
      <div class="text-center mt-4">
        <p class="font-weight-bold mb-lg-0">
          {{'Certificates.BSN' | translate}}
        </p>
      </div>
    </div>



  </div>


</section>
