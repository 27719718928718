<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/wagon-index'">{{'Rails.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'Rails.Scales.BreadcrumbActive' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">
            {{'Rails.Scales.Title' | translate}}
          </h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'Rails.Scales.SubTitle' | translate}}
          </h5>


        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4 mb-0">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/vagon/logo.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        {{'Rails.Scales.ContentTitleOne' | translate}}
        <div class="card-body">
          <ul class="mb-4">
            <div [innerHTML]="'Rails.Scales.ContentTextOne'  | translate"></div>
          </ul>
        </div>
      </h5>
    </div>


    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text font-weight-bold">
        {{'Rails.Scales.ContentTitleTwo' | translate}}
      </h5>
      <h5 class="black-text">
        <div [innerHTML]="'Rails.Scales.ContentTextTwo'  | translate"></div>
      </h5>
    </div>


  </section>




  <!--Radio buttons-->
  <section class="container">


    <div  class="container">




      <div lightbox-group class=" ">
        <div class="row ">
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image003.jpg" alt="">
          </div>
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image005.jpg" alt="">

          </div>
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image007.jpg" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image009.jpg" alt="">
          </div>
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image011.jpg" alt="">
          </div>
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image013.jpg" alt="">
          </div>
        </div>


        <div class="row flex-center">
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image015.jpg" alt="">
          </div>
          <div class="col-4 py-1 my-1">
            <img lightbox class="img-fluid" src="assets/industrial/vagon/image017.jpg" alt="">
          </div>
        </div>


      </div>





    </div>



  </section>
  <!--Radio buttons-->


  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'Rails.Scales.ContentBottom'  | translate"></div>
      </h5>
    </div>


    <!--Section: Content-->
    <section class="dark-grey-text text-center">

      <div class="row mx-0 nopadding">
        <div class="col-md-6 mb-4 mx-0" style="padding-top: 22px;">

          <div class="view mb-0">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/3FzSHWheosk" allowfullscreen></iframe>
            </div>

          </div>


        </div>
        <div class="col-md-6 mb-4 mx-0 pt-2">

          <div class="view mb-0 mx-0">
            <img src="assets/industrial/ffa.jpg" class="img-fluid" alt="smaple image">
          </div>

        </div>
      </div>

    </section>
    <!--Section: Content-->



    <h1 class="h2 text-center">{{'CarWeights.Icons.DefaultTitle' | translate}}</h1>

    <div class="flex-center pt-2 pb-2">
      <div class="row">
        <div class="col">
          <img src="assets/industrial/icons/Temp-30+45.png" ngbTooltip="{{'CarWeights.Icons.IconTemp' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/SensorIPsecure.png" ngbTooltip="{{'CarWeights.Icons.IconSensor' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RGML14.png" ngbTooltip="{{'CarWeights.Icons.IconRGML' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Paint.png" ngbTooltip="{{'CarWeights.Icons.IconPaint' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RS232conection.png" ngbTooltip="{{'CarWeights.Icons.IconRS' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/ShockProtection.png" ngbTooltip="{{'CarWeights.Icons.IconShock' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DisplayIPsecure.png" ngbTooltip="{{'CarWeights.Icons.IconDisplay' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Soft.png" ngbTooltip="{{'CarWeights.Icons.IconSoft' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/ScalesControl.png" ngbTooltip="{{'CarWeights.Icons.IconScales' | translate}}" container="body"  class="pr-4 pb-4">
        </div>
      </div>
    </div>




    <h1 class="h2 text-center">{{'CarWeights.Icons.ModernTitle' | translate}}</h1>

    <div class="flex-center pt-2 pb-2">
      <div class="row">
        <div class="col">
          <img src="assets/industrial/icons/plusplus.png" ngbTooltip="{{'CarWeights.Icons.IconPlusPlus' | translate}}" container="body"  class="pr-1 pb-1">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/AddDisplay.png" ngbTooltip="{{'CarWeights.Icons.IconAddDisplay' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DisplayShockProtection.png" ngbTooltip="{{'CarWeights.Icons.IconDisplayProtect' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DigitalSensors.png" ngbTooltip="{{'CarWeights.Icons.IconDigital' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/lights.png" ngbTooltip="{{'CarWeights.Icons.IconLights' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/barrier.png" ngbTooltip="{{'CarWeights.Icons.IconBarrier' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Photo.png" ngbTooltip="{{'CarWeights.Icons.IconPhoto' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RFIDidentification.png" ngbTooltip="{{'CarWeights.Icons.IconRFID' | translate}}" container="body"  class="pr-2 pb-2">
        </div>
      </div>
    </div>



    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-6 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsb.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSA' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-6">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstOne' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastOne' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstTwo' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastTwo' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Four.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Four.LastThirteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </section>



</div>
