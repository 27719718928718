<div class="view pb-4">
  <div style="height: 300px;" class="bgdivflag">
  </div>
  <div class="mask ">
    <div class="pt-2">
      <mdb-card-body cascade="true" class="text-center pt-5">
        <!--Title-->
        <mdb-card-title class="pt-5">
          <div class="flex-center wow zoomIn" data-wow-delay="0.6s">
            <img class="img-fluid" src="assets/flagman/logoflag.png" alt="Sample image">

          </div>
          <h6>
            <strong class="white-text wow zoomIn" data-wow-delay="0.6s" style="font-size: 22px">
              {{'Flagman.DivFlag.Title' | translate}}
            </strong>
          </h6>
        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>

</div>
