<div class="pb-2 pt-5">
<div class="container">
  <section class="">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-md-6 mb-4">
        <h3 class="font-weight-bold mb-3 wow fadeInLeft" data-wow-delay="0.6s">{{'Flagman.SevenFlag.IconTitle1' | translate}}</h3>
        <p class="text-muted pt-3 wow fadeInLeft" data-wow-delay="0.6s">
          {{'Flagman.SevenFlag.IconText1' | translate}}
        </p>
        <h3 class="font-weight-bold mb-3 wow fadeInLeft" data-wow-delay="1.6s">{{'Flagman.SevenFlag.IconTitle2' | translate}}</h3>
        <p class="text-muted pt-3 wow fadeInLeft" data-wow-delay="1.6s">
          {{'Flagman.SevenFlag.IconText2' | translate}}
        </p>
        <h3 class="font-weight-bold mb-3 wow fadeInLeft" data-wow-delay="2.0s">{{'Flagman.SevenFlag.IconTitle3' | translate}}</h3>
        <p class="text-muted pt-3 wow fadeInLeft" data-wow-delay="2.0s">
          {{'Flagman.SevenFlag.IconText3' | translate}}
        </p>
      </div>

      <div class="col-md-6 col-lg-4 d-flex justify-content-center mb-md-0 mb-5 wow fadeInRight" data-wow-delay="0.6s">
        <img src="assets/flagman/flagman-mob.jpg" class="img-fluid" alt="Sample image">
      </div>
    </div>

  </section>
</div>
</div>
