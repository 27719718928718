<!--<div class="view">-->
<!--  <div style="height: 454px;" class="bgalex ">-->
<!--  </div>-->
<!--  <div class="mask ">-->
<!--    &lt;!&ndash;Section: Content&ndash;&gt;-->
<section class="bgalex">
<div class="containermid flex-center">
      <div class="row pr-lg-3 flex-center">
        <div class="col-md-4 order-2">

          <div class="view ">
            <img src="assets/alexmarket/Logo AlexMarket PrintScreen.png" class="img-fluid  wow fadeInRight" data-wow-delay="0.6s" alt="smaple image">
          </div>

        </div>
        <div class="col-md-5 d-flex align-items-center wow fadeInLeft  text-center order-1" data-wow-delay="0.6s">
          <div>
            <img src="assets/dd11.png" class="img-fluid wow fadeInRight" data-wow-delay="0.6s" alt="smaple image">


            <h3 class="font-weight-bold mb-4 white-text pt-3 pl-3">{{'AlexMarket.Header.TitleTop' | translate}}</h3>

            <p class="white-text pb-5 pl-3 font-weight-bold">{{'AlexMarket.Header.TitleBottom' | translate}}</p>

          </div>
        </div>
      </div>
</div>
</section>
<!--    &lt;!&ndash;Section: Content&ndash;&gt;-->
<!--</div>-->











