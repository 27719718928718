<div class="container ">
</div>
<section class="text-center pb-3 my-5">
  <div class="container">


    <div class="row flex-center wow zoomIn" data-wow-delay="0.6s">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4 ">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div class="col-5  w-100 white d-flex justify-content-center align-items-center">
                <img src="assets/b52/bellboy.png" class="img-fluid  "/>
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">
                  {{'B52.First.One' | translate}}
                </h6>
                <button routerLink="/auto-hotel" type="button" class="btn btn-orange btn-rounded ">
                  {{'BUTTONS.Detail' | translate}}
                </button>

              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/b52/Hair__Beauty_Salon-512.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">
                  {{'B52.First.Two' | translate}}
                </h6>
                <button routerLink="/auto-fitnes" type="button" class="btn btn-primary btn-rounded">
                  {{'BUTTONS.Detail' | translate}}
                </button>

              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/b52/restaurant-icon-png-11.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">
                  {{'B52.First.Three' | translate}}
                </h6>
                <button routerLink="/auto-restourant" type="button" class="btn btn-green btn-rounded">
                  {{'BUTTONS.Detail' | translate}}
                </button>

              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/b52/billiard1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">
                  {{'B52.First.Four' | translate}}
                </h6>
                <button routerLink="/auto-billiard" type="button" class="btn btn-purple btn-rounded">
                  {{'BUTTONS.Detail' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->
      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-4 col-md-10">

        <img
          src="assets/b52/123.png"
          class="img-fluid  "
        />

      </div>
      <!-- Grid column -->


    </div>
    <!--Grid row-->

  </div>

</section>

