<div class="container my-4 py-4 grey lighten-4 wow zoomIn" data-wow-delay="0.6s">


  <!--Section: Content-->
  <section class="px-md-1 mx-md-3 text-center text-lg-left dark-grey-text">

    <!--Grid row-->
    <div class="row">

      <!--Grid column-->
      <div class="col-md-4 mb-4 mb-md-0">

        <!--Image-->
        <div class="view">
          <img
            src="assets/auto/restourant/kisspng-network-operations-center-network-monitoring-manag-analyst-5acc160d96ee83_2986819115233244296182.png"
            class="img-fluid" alt="">
        </div>

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-8 mb-4 mb-md-0">

        <h6>{{'B52.Restaurant.Three.OneTop' | translate}}</h6>
        <h3>{{'B52.Restaurant.Three.OneCenter' | translate}}</h3>
        <p style="text-align: justify;">{{'B52.Restaurant.Three.OneBottom' | translate}}</p>


      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->

  </section>
  <!--Section: Content-->


</div>
