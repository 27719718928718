<div class="container mt-5">


  <!--Section: Content-->
  <section>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-8 mb-4 align-self-center text-center text-lg-left wow fadeInLeft" data-wow-delay="0.6s">
        <div class="wpb_wrapper">
          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6>{{'B52.Hotel.Four.OneTop' | translate}}</h6>
              <h3>{{'B52.Hotel.Four.OneCenter' | translate}}</h3>
              <p style="text-align: justify;">{{'B52.Hotel.Four.OneBottom' | translate}}</p>

            </div>
          </div>

          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6>{{'B52.Hotel.Four.TwoTop' | translate}}</h6>
              <h3>{{'B52.Hotel.Four.TwoCenter' | translate}}</h3>
              <p style="text-align: justify;">{{'B52.Hotel.Four.TwoBottom' | translate}}</p>

            </div>
          </div>
        </div>


      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-4 mb-4 wow fadeInRight" data-wow-delay="0.6s">

        <!-- Featured image -->
        <div class="view overlay">
          <img class="img-fluid mx-auto"
               src="assets/auto/restourant/kisspng-computer-programming-software-developer-software-e-internet-computer-technology-5a6acebec12a92_3136952715169491827912-298x300.png"
               alt="Sample image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->


</div>
