<div class="container pt-5">
  <ng-container *ngIf="this.main.lang == 'ru'">
    <p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:24px;font-family:"Times New Roman",serif;color:#0C3C84;'>ПОЛИТИКА ИСПОЛЬЗОВАНИЯ COOKIE</span></strong></p>

    <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:14px;font-family:"Helvetica",sans-serif;color:#0C3C84;'>Файлы cookie и их использование</span></strong></p>

    <p style='font-size:14px;font-family:"Helvetica",sans-serif;'>Файлы cookie – это маленькие текстовые файлы, которые сохраняются на вашем устройстве при посещении веб-сайтов. Они помогают улучшить ваш опыт работы с сайтом и предоставлять более персонализированный сервис. Ниже описаны различные типы файлов cookie, которые мы используем:</p>

    <ol style="list-style-type: decimal;margin-left:32.75px;">
      <li><span style='font-family:"Helvetica",sans-serif;'>Необходимые файлы cookie обеспечивают работоспособность основных функций сайта, таких как доступ к защищенным областям.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Аналитические файлы cookie помогают нам понять, как пользователи взаимодействуют с сайтом.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Файлы cookie для персонализации сохраняют ваши предпочтения и настройки, например, выбранный вами язык или регион.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Маркетинговые файлы cookie используются для отображения рекламы, которая больше соответствует вашим интересам.</span></li>
    </ol>
  </ng-container>

  <ng-container *ngIf="this.main.lang == 'en'">
    <p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:24px;font-family:"Times New Roman",serif;color:#0C3C84;'>COOKIE POLICY</span></strong></p>

    <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:14px;font-family:"Helvetica",sans-serif;color:#0C3C84;'>Cookies and Their Use</span></strong></p>

    <p style='font-size:14px;font-family:"Helvetica",sans-serif;'>Cookies are small text files that are stored on your device when you visit websites. They help to enhance your site experience and provide more personalized service. Below are the different types of cookies we use:</p>

    <ol style="list-style-type: decimal;margin-left:32.75px;">
      <li><span style='font-family:"Helvetica",sans-serif;'>Necessary cookies enable core functionality of the site such as access to secure areas.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Analytical cookies help us understand how users interact with the site.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Personalization cookies store your preferences and settings, such as your chosen language or region.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Marketing cookies are used to display advertisements that are more relevant to your interests.</span></li>
    </ol>
  </ng-container>

  <ng-container *ngIf="this.main.lang == 'ro'">
    <p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:24px;font-family:"Times New Roman",serif;color:#0C3C84;'>POLITICA DE UTILIZARE A COOKIE-URILOR</span></strong></p>

    <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:14px;font-family:"Helvetica",sans-serif;color:#0C3C84;'>Cookie-uri și Utilizarea Lor</span></strong></p>

    <p style='font-size:14px;font-family:"Helvetica",sans-serif;'>Cookie-urile sunt fișiere de text de mici dimensiuni care sunt stocate pe dispozitivul dumneavoastră atunci când vizitați site-uri web. Ele ajută la îmbunătățirea experienței pe site și oferă un serviciu mai personalizat. Mai jos sunt descrise diferitele tipuri de cookie-uri pe care le utilizăm:</p>

    <ol style="list-style-type: decimal;margin-left:32.75px;">
      <li><span style='font-family:"Helvetica",sans-serif;'>Cookie-urile necesare permit funcționalitatea de bază a site-ului, cum ar fi accesul la zonele securizate.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Cookie-urile analitice ne ajută să înțelegem cum interacționează utilizatorii cu site-ul.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Cookie-urile de personalizare stochează preferințele și setările dumneavoastră, cum ar fi limba sau regiunea aleasă.</span></li>
      <li><span style='font-family:"Helvetica",sans-serif;'>Cookie-urile de marketing sunt utilizate pentru a afișa reclame care sunt mai relevante pentru interesele dumneavoastră.</span></li>
    </ol>
  </ng-container>

</div>



