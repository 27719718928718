<div class="container mt-5 pt-0 pb-3 px-5 ">
  <!--Section: Content-->
  <section class="text-center dark-grey-text">
    <h1 class="font-weight-bold black-text pb-4 wow zoomIn" data-wow-delay="0.6s">
      {{'B52.Second.Title' | translate}}
    </h1>

    <div class="row text-center d-flex justify-content-center my-5">
      <div class="col-lg-3 col-md-6 mb-4 wow zoomIn" data-wow-delay="0.6s">
        <img src="assets/b52/1-icon-b52.jpg" class="img-fluid pb-3"/>
        <strong class="font-weight-bold mb-3 mt-3">{{'B52.Second.One' | translate}} </strong>
      </div>
      <div class="col-lg-3 col-md-6 mb-4 wow zoomIn" data-wow-delay="0.6s">
        <img src="assets/b52/1-icon-b52-2.jpg" class="img-fluid pb-3"/>
        <strong class="font-weight-bold mb-3 mt-3">{{'B52.Second.Two' | translate}}</strong>

      </div>
      <div class="col-lg-3 col-md-6 mb-4 wow zoomIn" data-wow-delay="0.6s">
        <img src="assets/b52/1-icon-b52-3.jpg" class="img-fluid pb-3"/>
        <strong class="font-weight-bold mb-3 mt-3">{{'B52.Second.Three' | translate}}</strong>

      </div>
      <div class="col-lg-3 col-md-6 mb-4 wow zoomIn" data-wow-delay="0.6s">
        <img src="assets/b52/1-icon-b52-4.jpg" class="img-fluid pb-3"/>
        <strong class="font-weight-bold mb-3 mt-3">{{'B52.Second.Four' | translate}}</strong>

      </div>
    </div>

  </section>
  <!--Section: Content-->


</div>
