<app-carousel></app-carousel>
<div class="d-none d-sm-block">
<app-info-short></app-info-short>
</div>

<app-why></app-why>

<div class="d-none d-sm-block">
<app-qualities></app-qualities>
</div>

<app-projects></app-projects>

<app-index-shop></app-index-shop>

<app-questions></app-questions>

<app-news></app-news>

<app-video></app-video>

<app-partners></app-partners>

<app-certificate></app-certificate>

<app-maps></app-maps>
