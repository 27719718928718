<div class="gotop">
  <app-headerautores></app-headerautores>
</div>
<app-firstres></app-firstres>
<app-secondres></app-secondres>
<app-threeres></app-threeres>
<app-fourres></app-fourres>
<app-fiveres></app-fiveres>
<app-divautores></app-divautores>
<app-sixres></app-sixres>
<app-divtech></app-divtech>
<app-questions></app-questions>
