<div class="container pb-4">


  <!--Section: Content-->
  <section class="dark-grey-text text-center">


    <h1 class="font-weight-bold black-text mb-4 pb-2 wow zoomIn" data-wow-delay="0.6s">
      {{'Flagman.FourFlag.Title' | translate}}
    </h1>

    <div class="container">
      <div class="row">
        <div class="col">

          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="1.0s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_01_1.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon1' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->


        </div>
        <div class="col">

          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="1.5s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_02_2.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-3 pt-3">
                {{'Flagman.FourFlag.Icon2' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->

        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="2.0s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_03_3.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon3' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="2.5s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_04_4.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon4' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="3.0s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_05_5.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon5' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
      </div>
    </div>


    <div class="container pt-5">
      <div class="row">
        <div class="col">

          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="3.5s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_06_6.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon6' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->


        </div>
        <div class="col">

          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="4.0s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_07_7.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-3 pt-3">
                {{'Flagman.FourFlag.Icon7' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->

        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="4.5s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_08_8.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon8' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="5.0s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon_09_9.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-3 pt-3">
                {{'Flagman.FourFlag.Icon9' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
        <div class="col">
          <!-- Card -->
          <a class="card hoverable wow zoomIn" data-wow-delay="5.5s">

            <!-- Card content -->
            <div class="card-body my-4">

              <img
                src="assets/flagman/icon0101.png"
                class="img-fluid  "
              />
              <h6 class="black-text mb-0 pt-3">
                {{'Flagman.FourFlag.Icon10' | translate}}
              </h6>

            </div>

          </a>
          <!-- Card -->
        </div>
      </div>
    </div>


  </section>


</div>
