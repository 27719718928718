<div class="">
<div class="container pt-0">
  <mdb-breadcrumb-item>
    <mdb-breadcrumb-item  [routerLink]="'/solutions'">{{'SOLUTION.Index.Title' | translate}}</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{'SOLUTION.Index.SolutionNameTwo' | translate}}</mdb-breadcrumb-item>
  </mdb-breadcrumb-item>

  <!--Section: Content-->
  <section>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-7 mb-3 align-self-center text-center text-lg-left">

        <h1 class="h2 mb-4 pb-5">
          <div [innerHTML]="'SOLUTION.Index.SolutionNameTwo'  | translate"></div>
        </h1>
        <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
          <div [innerHTML]="'SOLUTION.Dispensing.SubTitle'  | translate"></div>
        </h5>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-5 mb-3">
        <!-- Featured image -->
        <div class="view overlay">
          <img class="img-fluid rounded-circle mx-auto" src="assets/solutions/index/image001-min.jpg" alt="Sample image">

        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'SOLUTION.Dispensing.SubSubTitle'  | translate"></div>
      </h5>
    </div>

  </section>
  <!--Section: Content-->




  <section class="container">
    <div class="container py-5 my-5">
      <h6 class="mb-0">
        <div [innerHTML]="'SOLUTION.Dispensing.ContentOne.Title'  | translate"></div>
      </h6>
        <div class="row mb-5 d-flex align-items-center">
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
              </div>
              <h6>
                <div class="card-body ">
                <div [innerHTML]="'SOLUTION.Dispensing.ContentOne.SubTitle'  | translate"></div>
                <ul class="mb-4">
                  <div [innerHTML]="'SOLUTION.Dispensing.ContentOne.Li'  | translate"></div>
                </ul>
              </div>
              </h6>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/solutions/dispensing/image003-min.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
        </div>
    </div>



    <div class="container py-5 my-5">
        <div class="row mb-5 d-flex align-items-center justify-content-end">
          <h6 class="mb-0">
            <div [innerHTML]="'SOLUTION.Dispensing.ContentTwo.Title'  | translate"></div>
          </h6>
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/solutions/dispensing/image005-min.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
              </div>
              <h6>
              <div class="card-body ">
                <div [innerHTML]="'SOLUTION.Dispensing.ContentTwo.SubTitle'  | translate"></div>
                <ul class="mb-4">
                  <div [innerHTML]="'SOLUTION.Dispensing.ContentTwo.Li'  | translate"></div>
                </ul>
              </div>
              </h6>
            </div>
          </div>
        </div>

    </div>
  </section>

  <div lightbox-group class=" ">
    <div class="row ">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image007-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image009-min.jpg" alt="">

      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image011-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image013-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image015-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image017-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image019-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image021-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image023-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image025-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image027-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image029-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image031-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image033-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image035-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image037-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image039-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image041-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image043-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image045-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image047-min.jpg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image049-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image051-min.jpg" alt="">
      </div>
      <div class="col-4 py-1 my-1">
        <img lightbox class="img-fluid" src="assets/solutions/dispensing/image053-min.jpg" alt="">
      </div>
    </div>
  </div>

  <div class="container py-5 my-5">
    <div class="row mb-5 d-flex align-items-center">
      <div class="col-lg-8 col-md-6">
        <div class="">
          <div class="card-header white">
            <h6>
            <div [innerHTML]="'SOLUTION.Dispensing.ContentThree.Title'  | translate"></div>
            </h6>
          </div>
          <h6>
            <div class="card-body ">
              <ul class="mb-4">
                <div [innerHTML]="'SOLUTION.Dispensing.ContentThree.Li'  | translate"></div>
              </ul>
            </div>
          </h6>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="view overlay rounded">
          <img src="assets/solutions/dispensing/image057-min.jpg" class="img-fluid"
               alt="Sample project image"/>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
    </div>
  </div>


  <section class="pb-5 my-2 flex-center">
    <div class="container text-center">
      <h2 class="h2 py-4 wow fadeInDown font-weight-bold" data-wow-delay="0.6s">{{ 'SOLUTION.Dispensing.PartnerTitle' | translate}}</h2>

      <mdb-carousel [isControls]="false" class="carousel-multi-item multi-animation wow fadeIn" data-wow-delay="0.6s" [type]="'carousel-multi-item'"
                    [animation]="'slide'">
        <mdb-carousel-item *ngFor="let item of slides; let i = index">
          <div class="col-md-2 " *ngFor="let card of item">
            <mdb-card class="my-1">
              <mdb-card-img class="img-thumbnail" [src]="card.img" alt="Card image cap"></mdb-card-img>
            </mdb-card>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>

    </div>
  </section>


</div>

</div>
