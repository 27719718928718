<div class="view">
  <div style="height: 140px;" class="bgdivert">
  </div>
  <div class="mask ">
    <div class="pt-2">
      <mdb-card-body cascade="true" class="text-center pt-5">
        <!--Title-->
        <mdb-card-title class="pt-5">
          <h2>
            <strong class="white-text wow bounceIn" data-wow-delay="0.6s">{{'AlexMarket.Divert.Title' | translate}}</strong>
          </h2>
        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>
  <div class="flex-center">
    <div class="btn-group pt-4 pb-4 " role="group" aria-label="Basic example">
      <button type="button" class="btn btn-red wow fadeInLeft" data-wow-delay="0.6s"><i
        class="fas fa-phone-square fa-sm pr-2" aria-hidden="true"></i>{{'BUTTONS.Call' | translate}}</button>
      <button type="button" class="btn btn-blue wow fadeInRight" data-wow-delay="0.6s"><i
        class="far fa-envelope fa-sm pr-2" aria-hidden="true"></i>{{'BUTTONS.Write' | translate}}</button>
    </div>
  </div>
</div>
