<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item
        [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item [routerLink]="'/wagon-index'">{{'Rails.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'Rails.Modern.Title' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'Rails.Modern.Title' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'Rails.Modern.SubTitle' | translate}}
          </h5>
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-4 mb-3">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/vagon/logo2.jpg" alt="Sample image">
          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h4 class="black-text">
        {{'Rails.Modern.ImageOne' | translate}}
      </h4>

      <!-- Featured image -->
      <div class="view overlay">
        <img class="img-fluid" src="assets/industrial/modern/1.jpg" alt="Sample image">

      </div>
    </div>

  </section>


  <section class="container pt-3">
    <div style="font-family: 'Times New Roman'">
      <h4 class="black-text">
        {{'Rails.Modern.ImageTwo' | translate}}
      </h4>
    </div>

    <!-- Featured image -->
    <div class="view overlay">
      <img class="img-fluid" src="assets/industrial/modern/3.jpg" alt="Sample image">

    </div>
  </section>


  <section class="container pt-3">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'Rails.Modern.Text'  | translate"></div>
      </h5>
    </div>


    <!--Section: Content-->
    <section class="dark-grey-text text-center">

      <div class="row mx-0 nopadding">
        <div class="col-md-6 mb-4 mx-0" style="padding-top: 22px;">

          <div class="view mb-0">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/3FzSHWheosk"
                      allowfullscreen></iframe>
            </div>

          </div>


        </div>
        <div class="col-md-6 mb-4 mx-0 pt-2">

          <div class="view mb-0 mx-0">
            <img src="assets/industrial/ffa.jpg" class="img-fluid" alt="smaple image">
          </div>

        </div>
      </div>

    </section>
    <!--Section: Content-->


  </section>

  <section class="pt-0">
    <h4 style="font-family: 'Times New Roman'"
        class="font-weight-bold flex-center pb-2">{{'CarWeights.AutoModern.Table.Title' | translate}}</h4>
    <div class="container">
      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="font-weight-bold flex-center black-text">
              {{'CarWeights.AutoModern.Table.Old.Title' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mb-3">
          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="font-weight-bold flex-center black-text">
              {{'CarWeights.AutoModern.Table.New.Title' | translate}}
            </h5>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.One' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/clock.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.One' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Two' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb2.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/Temp-30+45.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Two' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Three' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb3.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb4.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Three' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Four' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb5.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb6.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Four' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Five' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb7.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb8.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Five' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Six' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb9.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb10.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Six' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Seven' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb12.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/Automatic.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Seven' | translate}}
            </h5>
          </div>

        </div>
      </div>
    </div>


    <!--Grid row-->
    <div class="pt-4 py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-2 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsaa.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSA' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-5">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstOne' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastOne' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstTwo' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastTwo' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Five.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Five.LastThirteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </section>

</div>
