import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-threeaurora',
  templateUrl: './threeaurora.component.html',
  styleUrls: ['./threeaurora.component.scss']
})
export class ThreeauroraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
