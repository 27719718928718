<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">{{'Aurora.FMP.TitleTop' | translate}}</h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">{{'Aurora.FMP.TitleBottom' | translate}}</p>
  <div class="row">


    <div class="col order-2 wow fadeInRight" data-wow-delay="0.6s">

      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-power-off fa-2x green-text pr-2"></i>
          {{'Aurora.FMP.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'Aurora.FMP.SpecTextOne' | translate}}
        </p>
      </div>
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-rocket fa-2x green-text pr-2"></i>
        {{'Aurora.FMP.SpecTitleTwo' | translate}}
      </h6>
      <p class="">
        {{'Aurora.FMP.SpecTextTwo' | translate}}
      </p>

    </div>


    <div class="col-lg-5 order-1 wow fadeInLeft" data-wow-delay="0.6s">
      <img class="img-fluid pb-5" src="assets/alexmarket/aurora/FMP-101-min.jpg" alt="Sample image">

    </div>



    <div class="col order-3 wow fadeInRight" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-signal fa-2x green-text pr-2"></i>
          {{'Aurora.FMP.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'Aurora.FMP.SpecTextThree' | translate}}

        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-lightbulb fa-2x green-text pr-2"></i>
        {{'Aurora.FMP.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'Aurora.FMP.SpecTextFour' | translate}}
      </p>



    </div>

  </div>


  <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec' | translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3  font-weight-normal">
        <ng-container *ngIf="this.main.lang == 'ru'">
          <div class="py-1">
            <img  src="assets/alexmarket/aurora/fmpRu.png">
          </div>
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'en'">
          <div class="py-1">
            <img  src="assets/alexmarket/aurora/fmpEn.png">
          </div>
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'ro'">
          <div class="py-1">
            <img  src="assets/alexmarket/aurora/fmpRo.png">
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>























