<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item [routerLink]="'/auto-index'">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'CarWeights.OnBoard.Title' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'CarWeights.OnBoard.Title' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'CarWeights.OnBoard.SubTitle' | translate}}
          </h5>


        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4 mb-3">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/board/logo.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.OnBoard.Text'  | translate"></div>
      </h5>
    </div>

  </section>


  <section class="container">


    <div class="container py-5 my-5">
      <section class="p-md-3 mx-md-5">
        <div class="row mb-5 d-flex align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'CarWeights.OnBoard.ImageTitleOne' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <div [innerHTML]="'CarWeights.OnBoard.ImageTextOne'  | translate"></div>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/board/module.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a href="#">
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="row mb-5 d-flex align-items-center justify-content-end">
          <div class="col-md-6 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/board/222.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a href="#">
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'CarWeights.OnBoard.ImageTitleTwo' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <div [innerHTML]="'CarWeights.OnBoard.ImageTextTwo'  | translate"></div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'CarWeights.OnBoard.ImageTitleThree' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <div [innerHTML]="'CarWeights.OnBoard.ImageTextThree'  | translate"></div>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="view overlay rounded">
              <img src="assets/industrial/board/333.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a href="#">
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>


  </section>


</div>
