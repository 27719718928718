<section class="bgflaghead ">

  <div class="container flex-center">
    <div class="col-md-6 mx-auto pl-5 d-none d-md-block">
      <div class="view mb-0 pb-4">
        <img src="assets/flagman/Flagman-foto.png" class="img-fluid wow fadeInLeft pt-5" data-wow-delay="0.6s"
             alt="smaple image">
      </div>
    </div>

    <section class=" px-md-5 mx-md-5 dark-grey-text pt-5 flex-center">

      <div class="row mb-5">
        <div class="col-md-12  mb-4">

          <div class="view mb-5">
            <img src="assets/flagman/Flagman-logo.png" class="img-fluid wow fadeInLeft" data-wow-delay="0.6s"
                 alt="smaple image">
          </div>
          <div class="col-md-12 mb-4">
            <h4 class="font-weight-bold mb-4 pb-2 white-text pb-5 wow fadeInRight" data-wow-delay="0.6s">
              {{'Flagman.Header.Title' | translate}}
            </h4>
          </div>
        </div>
      </div>

    </section>
  </div>


</section>











