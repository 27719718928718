<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'Flagman.Horeca1.Android.TitleTop' | translate}}
    <span class="blue-text">
      {{'Flagman.Horeca1.Android.TitleBottom' | translate}}
    </span>
  </h3>
  <div class="row">


    <div class="col order-1 wow fadeInRight" data-wow-delay="0.6s">

      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-signal fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.Android.SpecTitleOne' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.Android.SpecTextOne' | translate}}
        </p>
      </div>
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-sync fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca1.Android.SpecTitleTwo' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca1.Android.SpecTextTwo' | translate}}
      </p>

    </div>


    <div class="col-lg-6 order-3 wow fadeInLeft" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/complect/hone/Flagman-mobil.jpg" alt="Sample image">

    </div>


    <div class="col order-2 wow fadeInRight" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-mobile-alt fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.Android.SpecTitleThree' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.Android.SpecTextThree' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-clock fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca1.Android.SpecTitleFour' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca1.Android.SpecTextFour' | translate}}
      </p>


    </div>

  </div>


</div>























