<!--Section: Products v.3-->
<div class="container ">
  <h1 class="font-weight-bold text-center dark-grey-text pb-2 pt-4 wow fadeIn" data-wow-delay="0.6s">
    {{'Flagman.ThreeFlag.Title' | translate}}
  </h1>
  <hr class="w-header my-4 pb-5">
</div>
<section class="text-center pb-3 pt-3  bgflagtree">
  <div class="container">


    <div class="row flex-center wow zoomIn" data-wow-delay="0.6s">

      <div class="col-lg-6 col-md-10">


      </div>

      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-restauran-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon1' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/cafenea.png"
                  class="img-fluid"
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon2' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-fast-food-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon3' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-retea-de-restaurante-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon4' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-catering-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon5' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-korporativnaya-pitanie-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="pt-2">
                  {{'Flagman.ThreeFlag.Icon6' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-bar-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon7' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


        <!--News tile -->
        <div class="news-tile view   mb-4 ">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-biliard-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="pt-2">
                  {{'Flagman.ThreeFlag.Icon8' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>


        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-restoran-v-otele-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon9' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-0">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-dostavca-edi-green-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.ThreeFlag.Icon10' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

      </div>


    </div>


  </div>

</section>
