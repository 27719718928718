<div *ngIf="post$ | async as post; else loading ">
<div class="gotop grey lighten-5">
  <!--Section: Content-->
  <section class="text-justify my-4 py-5 container border">

    <div class="text-center ">
    <h2>
      <a class="font-weight-bold">
        <ng-container *ngIf="this.main.lang == 'ru'">
          {{post.title}}
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'en'">
          {{post.titleEn}}
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'ro'">
          {{post.titleRo}}
        </ng-container>
      </a>
    </h2>
    <p>{{post.date | date: 'medium'}}</p>
      <hr>
    </div>


    <div>
      <!-- <ng-container *ngIf="this.main.lang == 'ru'">
        <quill-view-html [content]="post.content"></quill-view-html>
      </ng-container>
      <ng-container *ngIf="this.main.lang == 'en'">
        <quill-view-html [content]="post.contentEn"></quill-view-html>
      </ng-container>
      <ng-container *ngIf="this.main.lang == 'ro'">
        <quill-view-html [content]="post.contentRo"></quill-view-html>
      </ng-container> -->
      <ng-container *ngIf="this.main.lang == 'ru'">
        <div [froalaView]="post.content"></div>
      </ng-container>

      <ng-container *ngIf="this.main.lang == 'ro'">
        <div  [froalaView]="post.contentRo"></div>
      </ng-container>

      <ng-container *ngIf="this.main.lang == 'en'">
        <div  [froalaView]="post.contentEn"></div>
      </ng-container>

    </div>

  </section>
  <!--Section: Content-->


</div>
</div>


<ng-template #loading>
  <p class="text-center">Загружаю пост...</p>
</ng-template>
