<div class="container">
  <div class="py-5 my-5">
    <!-- Plugins: BeforeDisplayContent -->
    <!-- K2 Plugins: K2BeforeDisplayContent -->
    <!-- Item title -->
    <h2 itemprop="name" class="itemTitle">
      Пользовательское соглашение                            </h2>
    <!-- Item text -->
    <div itemprop="description" class="itemFullText"> <p><strong>Общие положения</strong>&nbsp;</p>
      <ol>
        <li><strong>ООО «</strong><strong>Alex</strong> <strong>S</strong><strong>&amp;</strong><strong>E</strong><strong>»</strong> (далее — «Издательство») предлагает Пользователю установить и использовать приложение <strong>«</strong><strong>Scales</strong> <strong>Control</strong><strong>»</strong> (далее — «Приложение»), на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение»).</li>
        <li>Начиная использовать приложение или его отдельные функции, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не вправе использовать данное Приложение, и должен немедленно прекратить его использование.</li>
        <li>Соглашение может быть изменено без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция ПС всегда находится на странице по адресу <strong>https</strong><strong>://</strong><strong>www</strong><strong>.</strong><strong>alex</strong><strong>-</strong><strong>se</strong><strong>.</strong><strong>com</strong><strong>/</strong><strong>ru</strong><strong>/</strong><strong>applestore</strong><strong>/</strong><strong>policy</strong></li>
      </ol>
      <p><strong>Исключительные права на содержание Приложения</strong></p>
      <ol>
        <li>С помощью Приложения Издатель предоставляет пользователю содержимое, в том числе текст, изображения, элементы дизайна и другое, являющееся объектами исключительного права Издателя или других правообладателей</li>
        <li>Использование содержимого, а также каких-либо иных элементов Приложения возможно только в рамках функционала, предлагаемого Приложением. Никакие элементы Приложения не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РМ.</li>
      </ol>
      <p><strong>Сайты и контент третьих лиц</strong></p>
      <ol>
        <li>Приложения могут содержать ссылки на сайты в сети Интернет и мобильные приложения третьих лиц. Указанные третьи лица и их контент не проверяются Издателем на соответствие тем или иным требованиям. Издатель не несет ответственность за любую информацию, материалы, размещенные на сайтах и в приложениях третьих лиц, к которым Пользователь получает доступ с использованием Приложения.</li>
        <li>Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на Сайте, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны Издателя, за исключением случаев, когда на это прямо указывается в материалах, размещённых Издателем.</li>
      </ol>
      <p><strong>Отсутствие гарантий, ограничение ответственности</strong></p>
      <ol>
        <li>Пользователь использует Приложение на свой собственный риск. Приложение предоставляются «как есть». Издатель не принимает на себя никакой ответственности, в том числе за соответствие Приложения целям Пользователя.</li>
        <li>Издатель не гарантирует, что: Приложение соответствует/будет соответствовать требованиям Пользователя; Приложение будет выполнять свои функции непрерывно, быстро, надежно и без ошибок; качество какого-либо продукта, услуги, информации и пр., полученных с использованием Приложения, будет соответствовать ожиданиям Пользователя;</li>
        <li>Любые материалы, доступ к которым Пользователь получает с использованием Приложения, Пользователь может использовать на свой собственный страх и риск, и самостоятельно несет ответственность за возможные последствия использования указанных материалов.</li>
      </ol>
      <p><strong>Иные положения</strong></p>
      <ol>
        <li>Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Республики Молдова. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Республики Молдова. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Республики Молдова.</li>
        <li>Бездействие со стороны Издателя в случае нарушения Пользователем либо иными пользователями положений Соглашения не лишает Издателя права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа Издателя от своих прав в случае совершения в последующем подобных либо сходных нарушений.</li>
        <li>Любые вопросы оплаты цифровых товаров: выпусков изданий и подписок, регулируются соглашением Пользователя с оператором магазина приложений: iTunes S.a.r.l. и не имеют отношения к данному Соглашению</li>
      </ol>
      <p>&nbsp;</p>
    </div>
    <div class="clr"></div>
    <div class="AfterDisplay">
      <!-- Plugins: AfterDisplay -->
      <!-- K2 Plugins: K2AfterDisplay -->
    </div>
    <div class="clr"></div>
    <div class="jsn-article-footer">
      <div class="clr"></div>
    </div>
    <div class="clr"></div>
    <!-- Plugins: AfterDisplayContent -->
    <!-- K2 Plugins: K2AfterDisplayContent -->
  </div>
  <div class="ItemTabs">
    <!-- K2 Plugins: K2BeforeDisplay (addTabs) -->
  </div>
  <div class="itemLinks">
    <div class="clr"></div>
    <div class="clr"></div>
  </div>
</div>
