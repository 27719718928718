<section class="container">
  <div style="font-family: 'Times New Roman'">
    <h5 class="black-text">
      <div [innerHTML]="'CarWeights.StaticAuto.HeaderText'  | translate"></div>
    </h5>
  </div>



  <!--Section: Content-->
  <section class="dark-grey-text text-center">

    <div class="row mx-0 nopadding">
      <div class="col-md-6 mb-4 mx-0" style="padding-top: 22px;">

        <div class="view mb-0">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/3FzSHWheosk" allowfullscreen></iframe>
          </div>

        </div>


      </div>
      <div class="col-md-6 mb-4 mx-0 pt-2">

        <div class="view mb-0 mx-0">
          <img src="assets/industrial/ffa.jpg"   class="img-fluid" alt="smaple image">
        </div>

      </div>
    </div>

  </section>
  <!--Section: Content-->




  <h1 class="h2 text-center">{{'CarWeights.Icons.DefaultTitle' | translate}}</h1>

  <div class="flex-center pt-2 pb-0">
    <div class="row">
      <div class="col">
        <img src="assets/industrial/icons/Temp-30+45.png" ngbTooltip="{{'CarWeights.Icons.IconTemp' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/SensorIPsecure.png" ngbTooltip="{{'CarWeights.Icons.IconSensor' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/RGML14.png" ngbTooltip="{{'CarWeights.Icons.IconRGML' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/Paint.png" ngbTooltip="{{'CarWeights.Icons.IconPaint' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/RS232conection.png" ngbTooltip="{{'CarWeights.Icons.IconRS' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/ShockProtection.png" ngbTooltip="{{'CarWeights.Icons.IconShock' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/DisplayIPsecure.png" ngbTooltip="{{'CarWeights.Icons.IconDisplay' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/Soft.png" ngbTooltip="{{'CarWeights.Icons.IconSoft' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
      <div class="col">
        <img src="assets/industrial/icons/ScalesControl.png" ngbTooltip="{{'CarWeights.Icons.IconScales' | translate}}" container="body"  class="pr-5 pb-5">
      </div>
    </div>
  </div>
</section>
