import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {ProgrammesPageComponent} from './components/programmes-page/programmes-page.component';
import {AlexMarketPageComponent} from './components/alex-market-page/alex-market-page.component';
import {FlagmanPageComponent} from './components/flagman-page/flagman-page.component';
import {B52PageComponent} from './components/b52-page/b52-page.component';
import {PosCompactPageComponent} from './components/pos-compact-page/pos-compact-page.component';
import {PosAlimentaraPageComponent} from './components/pos-alimentara-page/pos-alimentara-page.component';
import {PosMarketPageComponent} from './components/pos-market-page/pos-market-page.component';
import {PosHorecaonePageComponent} from './components/pos-horecaone-page/pos-horecaone-page.component';
import {PosHorecatwoPageComponent} from './components/pos-horecatwo-page/pos-horecatwo-page.component';
import {SupportServicePageComponent} from './components/support-service-page/support-service-page.component';
import {ContactsPageComponent} from './components/contacts-page/contacts-page.component';
import {AboutPageComponent} from './components/about-page/about-page.component';
import {AutoRestourantPageComponent} from './components/auto-restourant-page/auto-restourant-page.component';
import {AutoFitnesPageComponent} from './components/auto-fitnes-page/auto-fitnes-page.component';
import {AutoHotelPageComponent} from './components/auto-hotel-page/auto-hotel-page.component';
import {AutoBilliardPageComponent} from './components/auto-billiard-page/auto-billiard-page.component';
import {NewsPageComponent} from './components/news-page/news-page.component';
import {PostPageComponent} from './components/news-page/post-page/post-page.component';
import {ShopPageComponent} from './components/shop-page/shop-page.component';
import {ProductsPageComponent} from './components/shop-page/products-page/products-page.component';
import {CartPageComponent} from './components/cart-page/cart-page.component';
import {CheckoutPageComponent} from './components/checkout-page/checkout-page.component';
import {ThankyouPageComponent} from './components/thankyou-page/thankyou-page.component';
import {SignUpComponent} from './user/sign-up/sign-up.component';
import {LoginComponent} from './user/login/login.component';
import {AuthGuard} from './classes/auth.guard';
import {IndustrialComponent} from './components/industrial/industrial.component';
import {StaticAutoPageComponent} from './components/industrial/category-page/static-auto-page/static-auto-page.component';
import {MovingFermerPageComponent} from './components/industrial/moving-fermer-page/moving-fermer-page.component';
import {PerenosnieNagruzkaPageComponent} from './components/industrial/perenosnie-nagruzka-page/perenosnie-nagruzka-page.component';
import {StaticNagrizkaPageComponent} from './components/industrial/static-nagrizka-page/static-nagrizka-page.component';
import {BortSystemPageComponent} from './components/industrial/bort-system-page/bort-system-page.component';
import {ModernAutoPageComponent} from './components/industrial/modern-auto-page/modern-auto-page.component';
import {VagonIndexPageComponent} from './components/vagons/vagon-index-page/vagon-index-page.component';
import {VagonPageComponent} from './components/vagons/vagon-page/vagon-page.component';
import {VagonModernPageComponent} from './components/vagons/vagon-modern-page/vagon-modern-page.component';
import {DozatorIndexPageComponent} from './components/bunker/dozator-index-page/dozator-index-page.component';
import {DozatorOpenPageComponent} from './components/bunker/dozator-open-page/dozator-open-page.component';
import {DozatorViboiPageComponent} from './components/bunker/dozator-viboi-page/dozator-viboi-page.component';
import {DozatorTablePageComponent} from './components/bunker/dozator-table-page/dozator-table-page.component';
import {DozatorModernPageComponent} from './components/bunker/dozator-modern-page/dozator-modern-page.component';
// tslint:disable-next-line:max-line-length
import {AutomaticWeightControlSystemComponent} from './components/automatic-weight-control-system/automatic-weight-control-system.component';
import {AuroraComponent} from './components/aurora/aurora.component';
import {MobileTruckScaleWithContinuousPlatformComponent} from './components/mobile-truck-scale-with-continuous-platform/mobile-truck-scale-with-continuous-platform.component';
import {SolutionsComponent} from './components/solutions/solutions.component';
import {AgriculturalComponent} from './components/solutions/agricultural/agricultural.component';
import {DispensingComponent} from './components/solutions/dispensing/dispensing.component';
import {LivestockComponent} from './components/solutions/livestock/livestock.component';
import {WeighingComponent} from './components/solutions/weighing/weighing.component';
import {ModernizationComponent} from './components/solutions/modernization/modernization.component';
import { PagePolicyComponent } from './page-policy/page-policy.component';
import {CookiePolicyComponent} from './components/cookie-policy/cookie-policy.component';



export const routes: Routes = [
  {
    path: '',  component: MainLayoutComponent, children: [
      {path: '', redirectTo: '/', pathMatch: 'full'},
      {path: '', component: HomePageComponent},
      {path: 'ro/price/trade-equipment/itemlist/category/3-kassovye-apparaty', redirectTo: '/ro/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ro/industrial-scales', redirectTo: '/ro/auto-index'},
      {path: 'ro/tradescales', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ro/produktsiya/ro-industrialscales/itemlist/category/16-avtomobilnye-vesy', redirectTo: '/ro/auto-index'},
      {path: 'ro/produktsiya/ro-industrialscales/itemlist/category/17-vagonnye-vesy', redirectTo: '/ro/wagon-index'},
      {path: 'ro/itemlist/category/1-torgovye-vesy', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ro/item/53-0001049', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29258cfe44ee3bbbb844d1'},
      {path: 'ro/item/52-0001048', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292539fe44ee3bbbb844d0'},
      {path: 'ro/item/51-0000929', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29265dfe44ee3bbbb844d3'},
      {path: 'ro/item/50-0000931', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29271dfe44ee3bbbb844d5'},
      {path: 'ro/item/49-0000928', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292616fe44ee3bbbb844d2'},
      {path: 'ro/item/1-0000930', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f2926cbfe44ee3bbbb844d4'},
      {path: 'ro/itemlist/category/2-tovarnye-vesy', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3'},
      {path: 'ro/item/307-0008073', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29249dfe44ee3bbbb844cf'},
      {path: 'ro/item/60-0001028', redirectTo: '/ro/products/subcategory/5f1fd20177bb31183d7fd10b/item/5f27a6c6fe44ee3bbbb84493'},
      {path: 'ro/item/59-0000945', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29240bfe44ee3bbbb844ce'},
      {path: 'ro/item/58-0000946', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2923befe44ee3bbbb844cd'},
      {path: 'ro/item/57-bsn-6d1-3', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2922dffe44ee3bbbb844cc'},
      {path: 'ro/item/56-0000944', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29228cfe44ee3bbbb844cb'},
      {path: 'ro/item/55-0000925', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2921f2fe44ee3bbbb844ca'},
      {path: 'ro/item/54-0000924', redirectTo: '/ro/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29219ffe44ee3bbbb844c9'},
      {path: 'ro/itemlist/category/19-precision-scales', redirectTo: '/ro/products/subcategory/5f1fd1e777bb31183d7fd109'},
      {path: 'ro/item/236-0008936', redirectTo: '/ro/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b423fe44ee3bbbb84491'},
      {path: 'ro/item/99-0000885', redirectTo: '/ro/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b2b8fe44ee3bbbb84490'},
      {path: 'ro/item/98-0000884', redirectTo: '/ro/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b1f4fe44ee3bbbb8448f'},
      {path: 'ro/itemlist/category/20-platform-scales', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'ro/item/77-0001013', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290217fe44ee3bbbb844a3'},
      {path: 'ro/item/76-0001008', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29017ffe44ee3bbbb844a2'},
      {path: 'ro/item/73-0000910', redirectTo: '/ro/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f9ecfe44ee3bbbb8449d'},
      {path: 'ro/item/72-0000900', redirectTo: '/ro/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f90afe44ee3bbbb8449c'},
      {path: 'ro/item/71-0000893', redirectTo: '/ro/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f27ed77fe44ee3bbbb8449b'},
      {path: 'ro/item/69-0001019', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'ro/item/68-0001017', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'ro/item/67-0001020', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'ro/item/63-0000919', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'ro/item/62-0000916', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'ro/item/61-0000898', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'ro/itemlist/category/21-label-printing-scales', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ro/item/97-0007917', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29386ffe44ee3bbbb844db'},
      {path: 'ro/itemlist/category/22-crane-scales', redirectTo: '/ro/products/subcategory/5f1fd25a77bb31183d7fd10f'},
      {path: 'ro/item/102-0000892', redirectTo: '/ro/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22ae0efe44ee3bbbb84487'},
      {path: 'ro/item/101-0000891', redirectTo: '/ro/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b0b7fe44ee3bbbb8448e'},
      {path: 'ro/item/100-0000890', redirectTo: '/ro/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b03bfe44ee3bbbb8448d'},
      {path: 'ro/itemlist/category/23-monoray-scales', redirectTo: '/ro/products/subcategory/5f1fd26077bb31183d7fd110'},
      {path: 'ro/item/104-monorelsovye-npv-1000kg', redirectTo: '/ro/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'ro/item/103-monorelsovye-npv-500kg', redirectTo: '/ro/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'ro/itemlist/category/24-animal-scales', redirectTo: '/ro/products/subcategory/5f1fd24877bb31183d7fd10e'},
      {path: 'ro/item/92-0008178', redirectTo: '/ro/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523279c56ec5257dbc08ca'},
      {path: 'ro/item/91-0008177', redirectTo: '/ro/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523241c56ec5257dbc08c9'},
      {path: 'ro/item/90-0008176', redirectTo: '/ro/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f52314fc56ec5257dbc08c8'},
      {path: 'ro/item/89-0008179', redirectTo: '/ro/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f5232cec56ec5257dbc08cb'},
      {path: 'ro/itemlist/category/27-platform-scales-1000', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'ro/item/319-0010037', redirectTo: '/ro/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f291bf8fe44ee3bbbb844c1'},
      {path: 'ro/item/253-0000914', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290e2afe44ee3bbbb844ab'},
      {path: 'ro/item/252-0007612', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2909fffe44ee3bbbb844a8'},
      {path: 'ro/item/251-0000992', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291811fe44ee3bbbb844b8'},
      {path: 'ro/item/250-0007134', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29151dfe44ee3bbbb844b5'},
      {path: 'ro/item/227-0008884', redirectTo: '/ro/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f4f5db4c56ec5257dbc08ba'},
      {path: 'ro/item/87-0010038', redirectTo: '/ro/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f292cd7fe44ee3bbbb844d8'},
      {path: 'ro/item/86-0007638', redirectTo: '/ro/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f2908b7fe44ee3bbbb844a7'},
      {path: 'ro/item/85-0007622-0007354-0007623', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291b6afe44ee3bbbb844c0'},
      {path: 'ro/item/84-0007619-0007620-0007621', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291a08fe44ee3bbbb844bd'},
      {path: 'ro/item/83-0007619-0007620-0007621', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2913effe44ee3bbbb844b4'},
      {path: 'ro/item/82-0000917-0007615-0007616', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291204fe44ee3bbbb844b1'},
      {path: 'ro/item/81-0001004-0001007', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291890fe44ee3bbbb844ba'},
      {path: 'ro/item/80-0000963-0007608', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291783fe44ee3bbbb844b7'},
      {path: 'ro/item/79-0001003-0000920', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290c56fe44ee3bbbb844aa'},
      {path: 'ro/item/78-0007328-0000921', redirectTo: '/ro/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290f2dfe44ee3bbbb844ad'},
      {path: 'ro/produktsiya', redirectTo: '/ro/products'},
      {path: 'ro/produktsiya/tradescales', redirectTo: '/ro/products'},
      {path: 'ro/produktsiya/ro-industrialscales', redirectTo: '/ro/auto-index'},
      {path: 'ro/produktsiya/ro-industrialscales/itemlist/category/16-avtomobilnye-vesy', redirectTo: '/ro/auto-index'},
      {path: 'ro/produktsiya/ro-industrialscales/item/314-bortovye-sistemy-vzveshivaniya', redirectTo: '/ro/on-board-weighing-systems'},
      {path: 'ro/produktsiya/ro-industrialscales/item/118-pentru-c-nt-rirea-sarcinii-pe-axii-n-dinamic', redirectTo: '/ro/stationary-for-weighing-axle-loads'},
      {path: 'ro/produktsiya/ro-industrialscales/item/117-dlya-vzveshivaniya-nagruzki-na-os', redirectTo: '/ro/portable-for-weighing-axle-loads'},
      {path: 'ro/produktsiya/ro-industrialscales/item/116-fermier', redirectTo: '/ro/mobile-farmer-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/115-cu-in-p-n-la-80-tone', redirectTo: '/ro/stationary-automotive-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/112-cu-f-ie-de-beton-armat-max-60-tone', redirectTo: '/ro/stationary-automotive-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/itemlist/category/17-vagonnye-vesy', redirectTo: '/ro/wagon-index'},
      {path: 'ro/produktsiya/ro-industrialscales/item/119-pentru-vagoane', redirectTo: '/ro/wagon-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/itemlist/category/18-doziruyushchie-sistemy', redirectTo: '/ro/bunker-index'},
      {path: 'ro/produktsiya/ro-industrialscales/item/128-hooper-scales-120-th', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/127-hooper-scales-85-th', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/126-hooper-scales-60-th', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/125-viboi-electric', redirectTo: '/ro/filling-batcher'},
      {path: 'ro/produktsiya/ro-industrialscales/item/124-viboi', redirectTo: '/ro/filling-batcher'},
      {path: 'ro/produktsiya/ro-industrialscales/item/123-prokhodnoj-proizvoditelnost-90-tonn-chas', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/122-prokhodnoj-proizvoditelnost-60-tonn-chas', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/121-prokhodnoj-proizvoditelnost-30-tonn-chas', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/ro-industrialscales/item/120-prokhodnoj-proizvoditelnost-18-tonn-chas', redirectTo: '/ro/bunker-scales'},
      {path: 'ro/produktsiya/echipament-comercial', redirectTo: '/ro/products'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/3-kassovye-apparaty', redirectTo: '/ro/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ro/produktsiya/echipament-comercial/item/328-kassovyj-zhurnal', redirectTo: '/ro/products/item/5f294324fe44ee3bbbb844ef'},
      {path: 'ro/produktsiya/echipament-comercial/item/197-0008383', redirectTo: '/ro/products/subcategory/5f1e87661c902614531f7ece/item/5f229803fe44ee3bbbb84482'},
      {path: 'ro/produktsiya/echipament-comercial/item/196-0008384', redirectTo: '/ro/products/subcategory/5f1e87661c902614531f7ece/item/5f22ad58fe44ee3bbbb84486'},
      {path: 'ro/produktsiya/echipament-comercial/item/180-0000263', redirectTo: '/ro/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/28-printer-pos', redirectTo: '/ro/products/subcategory/5f1fd29377bb31183d7fd117'},
      {path: 'ro/produktsiya/echipament-comercial/item/226-0008704', redirectTo: '/ro/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d81fe44ee3bbbb844e1'},
      {path: 'ro/produktsiya/echipament-comercial/item/134-0007188', redirectTo: '/ro/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d23fe44ee3bbbb844e0'},
      {path: 'ro/produktsiya/echipament-comercial/item/133-0007189', redirectTo: '/ro/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293cd9fe44ee3bbbb844df'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/29-pos-monitor', redirectTo: '/ro/products/subcategory/5f1fd26677bb31183d7fd111'},
      {path: 'ro/produktsiya/echipament-comercial/item/305-0009841', redirectTo: '/ro/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290735fe44ee3bbbb844a6'},
      {path: 'ro/produktsiya/echipament-comercial/item/264-micropos-capacitive', redirectTo: '/ro/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290607fe44ee3bbbb844a5'},
      {path: 'ro/produktsiya/echipament-comercial/item/201-display', redirectTo: '/ro/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f50f384c56ec5257dbc08c7'},
      {path: 'ro/produktsiya/echipament-comercial/item/200-scitivateli', redirectTo: '/ro/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f4fa007c56ec5257dbc08bf'},
      {path: 'ro/produktsiya/echipament-comercial/item/199-micropos', redirectTo: '/ro/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290578fe44ee3bbbb844a4'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/30-barcode-scaners', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119'},
      {path: 'ro/produktsiya/echipament-comercial/item/322-0009910', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c463355090533135334c'},
      {path: 'ro/produktsiya/echipament-comercial/item/310-0009830', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'ro/produktsiya/echipament-comercial/item/309-00090906', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291f02fe44ee3bbbb844c5'},
      {path: 'ro/produktsiya/echipament-comercial/item/291-0009482', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e13fe44ee3bbbb844c3'},
      {path: 'ro/produktsiya/echipament-comercial/item/290-0009481', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c28e355090533135334a'},
      {path: 'ro/produktsiya/echipament-comercial/item/289-0009480', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f292b40fe44ee3bbbb844d6'},
      {path: 'ro/produktsiya/echipament-comercial/item/256-0009285', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c3ce355090533135334b'},
      {path: 'ro/produktsiya/echipament-comercial/item/247-0009091', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291d80fe44ee3bbbb844c2'},
      {path: 'ro/produktsiya/echipament-comercial/item/246-0009091', redirectTo: '/ro/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/31-remote-service-terminals', redirectTo: '/ro/products/subcategory/5f1fd2b177bb31183d7fd11a'},
      {path: 'ro/produktsiya/echipament-comercial/item/153-0007727', redirectTo: '/ro/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29456efe44ee3bbbb844f2'},
      {path: 'ro/produktsiya/echipament-comercial/item/152-0007728', redirectTo: '/ro/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29462ffe44ee3bbbb844f3'},
      {path: 'ro/produktsiya/echipament-comercial/item/150-0007729', redirectTo: '/ro/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f2944dafe44ee3bbbb844f1'},
      {path: 'ro/produktsiya/echipament-comercial/item/149-0000368', redirectTo: '/ro/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f294467fe44ee3bbbb844f0'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/35-programs', redirectTo: '/ro/programs'},
      {path: 'ro/produktsiya/echipament-comercial/item/159-alex-market', redirectTo: '/ro/alex-market'},
      {path: 'ro/produktsiya/echipament-comercial/item/158-b52', redirectTo: '/ro/b52'},
      {path: 'ro/produktsiya/echipament-comercial/item/157-flagman', redirectTo: '/ro/flagman'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/40-labelprinting', redirectTo: '/ro/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/41-printer-pos', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118'},
      {path: 'ro/produktsiya/echipament-comercial/item/248-0008705', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293e2dfe44ee3bbbb844e3'},
      {path: 'ro/produktsiya/echipament-comercial/item/225-0008702', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ddcfe44ee3bbbb844e2'},
      {path: 'ro/produktsiya/echipament-comercial/item/136-0007233', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'ro/produktsiya/echipament-comercial/item/135-0008703', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'ro/produktsiya/echipament-comercial/item/130-0007768', redirectTo: '/ro/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ee3fe44ee3bbbb844e5'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/48-measuring-cup', redirectTo: '/ro/products/subcategory/5f1fd2bd77bb31183d7fd11c'},
      {path: 'ro/produktsiya/echipament-comercial/item/330-0007720', redirectTo: '/ro/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f291fcffe44ee3bbbb844c7'},
      {path: 'ro/produktsiya/echipament-comercial/item/329-0007719', redirectTo: '/ro/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f293a2cfe44ee3bbbb844dd'},
      {path: 'ro/produktsiya/echipament-comercial/itemlist/category/49-denejnie-iasiki', redirectTo: '/ro/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'ro/produktsiya/echipament-comercial/item/324-0009659', redirectTo: '/ro/products/subcategory/5f1fd27c77bb31183d7fd114/item/5f293934fe44ee3bbbb844dc'},
      {path: 'ro/produktsiya/echipament-comercial/item/323-0007316', redirectTo: '/ro/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'ro/servicii', redirectTo: '/ro/support-service'},
      {path: 'ro/uslugi/suport-tehnic', redirectTo: '/ro/support-service'},
      {path: 'ro/uslugi/suportul-tehnic', redirectTo: '/ro/support-service'},
      {path: 'ro/compania/about-company', redirectTo: '/ro/about'},
      {path: 'ro/compania/contacts', redirectTo: '/ro/contacts'},
      {path: 'ro/price', redirectTo: '/ro/price-list'},
      {path: 'ro/pricelist', redirectTo: '/ro/price-list'},
      {path: 'ro/price/trade-equipment', redirectTo: '/ro/price-list'},
      {path: 'ro/produktsiya/echipament-comercial/item/300-cup', redirectTo: '/ro/products/subcategory/5f1fd2bd77bb31183d7fd11c'},
      {path: 'ru/produktsiya/ru-industrialscales/itemlist/category/17-vagonnye-vesy', redirectTo: '/ru/wagon-index'},
      {path: 'ru/price/scales/itemlist/category/22-crane-scales', redirectTo: '/ru/products/subcategory/5f1fd25a77bb31183d7fd10f'},
      {path: 'ru/price/scales/itemlist/category/1-torgovye-vesy', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ru/price/scales/itemlist/category/27-platform-scales-1000', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'ru/price/scales/item/98-0000884', redirectTo: '/ru/products/item/5f22b1f4fe44ee3bbbb8448f'},
      {path: 'ru/price/scales/itemlist/category/19-precision-scales', redirectTo: '/ru/products/subcategory/5f1fd1e777bb31183d7fd109'},
      {path: 'ru/price/scales/itemlist/category/21-label-printing-scales', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29386ffe44ee3bbbb844db'},
      {path: 'ru/price/trade-equipment', redirectTo: '/ru/products'},
      {path: 'ru/price/trade-equipment/itemlist/category/3-kassovye-apparaty', redirectTo: '/ru/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ru/price/trade-equipment/itemlist/category/29-pos-monitor', redirectTo: '/ru/products/subcategory/5f1fd26677bb31183d7fd111'},
      {path: 'ru/price/scales/itemlist/category/2-tovarnye-vesy', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/300-cup', redirectTo: '/ru/products/subcategory/5f1fd2bd77bb31183d7fd11c'},
      {path: 'ru/itemlist/category/1-torgovye-vesy', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ru/item/53-0001049', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29258cfe44ee3bbbb844d1'},
      {path: 'ru/item/52-0001048', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292539fe44ee3bbbb844d0'},
      {path: 'ru/item/51-0000929', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29265dfe44ee3bbbb844d3'},
      {path: 'ru/item/50-0000931', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29271dfe44ee3bbbb844d5'},
      {path: 'ru/item/49-0000928', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292616fe44ee3bbbb844d2'},
      {path: 'ru/item/1-0000930', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f2926cbfe44ee3bbbb844d4'},
      {path: 'ru/itemlist/category/2-tovarnye-vesy', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3'},
      {path: 'ru/item/307-0008073', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29249dfe44ee3bbbb844cf'},
      {path: 'ru/item/60-0001028', redirectTo: '/ru/products/subcategory/5f1fd20177bb31183d7fd10b/item/5f27a6c6fe44ee3bbbb84493'},
      {path: 'ru/item/59-0000945', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29240bfe44ee3bbbb844ce'},
      {path: 'ru/item/58-0000946', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2923befe44ee3bbbb844cd'},
      {path: 'ru/item/57-0000947', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2922dffe44ee3bbbb844cc'},
      {path: 'ru/item/56-0000944', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29228cfe44ee3bbbb844cb'},
      {path: 'ru/item/55-0000925', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2921f2fe44ee3bbbb844ca'},
      {path: 'ru/item/54-0000924', redirectTo: '/ru/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29219ffe44ee3bbbb844c9'},
      {path: 'ru/itemlist/category/19-precision-scales', redirectTo: '/ru/products/subcategory/5f1fd1e777bb31183d7fd109'},
      {path: 'ru/item/236-0008936', redirectTo: '/ru/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b423fe44ee3bbbb84491'},
      {path: 'ru/item/99-0000885', redirectTo: '/ru/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b2b8fe44ee3bbbb84490'},
      {path: 'ru/item/98-0000884', redirectTo: '/ru/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b1f4fe44ee3bbbb8448f'},
      {path: 'ru/itemlist/category/20-platform-scales', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'ru/item/77-0001013', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290217fe44ee3bbbb844a3'},
      {path: 'ru/item/76-0001008', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29017ffe44ee3bbbb844a2'},
      {path: 'ru/item/73-0000910', redirectTo: '/ru/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f9ecfe44ee3bbbb8449d'},
      {path: 'ru/item/72-0000900', redirectTo: '/ru/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f90afe44ee3bbbb8449c'},
      {path: 'ru/item/71-0000893', redirectTo: '/ru/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f27ed77fe44ee3bbbb8449b'},
      {path: 'ru/item/69-0001019', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'ru/item/68-0001017', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'ru/item/67-0001020', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'ru/item/63-0000919', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'ru/item/62-0000916', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'ru/item/61-0000898', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'ru/itemlist/category/21-label-printing-scales', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ru/item/97-0007917', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29386ffe44ee3bbbb844db'},
      {path: 'ru/itemlist/category/22-crane-scales', redirectTo: '/ru/products/subcategory/5f1fd25a77bb31183d7fd10f'},
      {path: 'ru/item/102-0000892', redirectTo: '/ru/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22ae0efe44ee3bbbb84487'},
      {path: 'ru/item/101-0000891', redirectTo: '/ru/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b0b7fe44ee3bbbb8448e'},
      {path: 'ru/item/100-0000890', redirectTo: '/ru/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b03bfe44ee3bbbb8448d'},
      {path: 'ru/itemlist/category/23-monoray-scales', redirectTo: '/ru/products/subcategory/5f1fd26077bb31183d7fd110'},
      {path: 'ru/item/104-monorelsovye-npv-1000kg', redirectTo: '/ru/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'ru/item/103-monorelsovye-npv-500kg', redirectTo: '/ru/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'ru/itemlist/category/24-animal-scales', redirectTo: '/ru/products/subcategory/5f1fd24877bb31183d7fd10e'},
      {path: 'ru/item/92-0008178', redirectTo: '/ru/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523279c56ec5257dbc08ca'},
      {path: 'ru/item/91-0008177', redirectTo: '/ru/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523241c56ec5257dbc08c9'},
      {path: 'ru/item/90-0008176', redirectTo: '/ru/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f52314fc56ec5257dbc08c8'},
      {path: 'ru/item/89-0008179', redirectTo: '/ru/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f5232cec56ec5257dbc08cb'},
      {path: 'ru/itemlist/category/27-platform-scales-1000', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'ru/item/319-0010037', redirectTo: '/ru/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f291bf8fe44ee3bbbb844c1'},
      {path: 'ru/item/253-0000914', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290e2afe44ee3bbbb844ab'},
      {path: 'ru/item/252-0007612', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2909fffe44ee3bbbb844a8'},
      {path: 'ru/item/251-0000992', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291811fe44ee3bbbb844b8'},
      {path: 'ru/item/250-0007134', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29151dfe44ee3bbbb844b5'},
      {path: 'ru/item/227-0008884', redirectTo: '/ru/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f4f5db4c56ec5257dbc08ba'},
      {path: 'ru/item/87-0010038', redirectTo: '/ru/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f292cd7fe44ee3bbbb844d8'},
      {path: 'ru/item/86-0007638', redirectTo: '/ru/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f2908b7fe44ee3bbbb844a7'},
      {path: 'ru/item/85-0007622-0007354-0007623', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291b6afe44ee3bbbb844c0'},
      {path: 'ru/item/84-0007619-0007620-0007621', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291a08fe44ee3bbbb844bd'},
      {path: 'ru/item/83-0007619-0007620-0007621', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2913effe44ee3bbbb844b4'},
      {path: 'ru/item/82-0000917-0007615-0007616', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291204fe44ee3bbbb844b1'},
      {path: 'ru/item/81-0001004-0001007', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291890fe44ee3bbbb844ba'},
      {path: 'ru/item/80-0000963-0007608', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291783fe44ee3bbbb844b7'},
      {path: 'ru/item/79-0001003-0000920', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290c56fe44ee3bbbb844aa'},
      {path: 'ru/item/78-0007328-0000921', redirectTo: '/ru/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290f2dfe44ee3bbbb844ad'},
      {path: 'ru/produktsiya', redirectTo: '/ru/products'},
      {path: 'ru/produktsiya/ru-tradescales', redirectTo: '/ru/products'},
      {path: 'ru/produktsiya/ru-industrialscales', redirectTo: '/ru/auto-index'},
      {path: 'ru/produktsiya/ru-industrialscales/itemlist/category/16-avtomobilnye-vesy', redirectTo: '/ru/auto-index'},
      {path: 'ru/produktsiya/ru-industrialscales/item/314-bortovye-sistemy-vzveshivaniya', redirectTo: '/ru/on-board-weighing-systems'},
      {path: 'ru/produktsiya/ru-industrialscales/item/118-dlya-vzveshivaniya-nagruzki-na-os-stationar', redirectTo: '/ru/stationary-for-weighing-axle-loads'},
      {path: 'ru/produktsiya/ru-industrialscales/item/117-dlya-vzveshivaniya-nagruzki-na-os', redirectTo: '/ru/portable-for-weighing-axle-loads'},
      {path: 'ru/produktsiya/ru-industrialscales/item/116-fermer', redirectTo: '/ru/mobile-farmer-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/115-kolejnye-npv-80-tonn', redirectTo: '/ru/stationary-automotive-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/112-c-zhelezobetonnoj-platformoj-npv-60-tonn', redirectTo: '/ru/stationary-automotive-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/itemlist/category/17-vagonnye-vesy', redirectTo: '/ru/wagon-index'},
      {path: 'ru/produktsiya/ru-industrialscales/item/119-vagonnie-vesi', redirectTo: '/ru/wagon-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/itemlist/category/18-doziruyushchie-sistemy', redirectTo: '/ru/bunker-index'},
      {path: 'ru/produktsiya/ru-industrialscales/item/128-hooper-scales-120-th', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/127-hooper-scales-85-th', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/126-hooper-scales-60-th', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/125-viboi-electric', redirectTo: '/ru/filling-batcher'},
      {path: 'ru/produktsiya/ru-industrialscales/item/124-viboi', redirectTo: '/ru/filling-batcher'},
      {path: 'ru/produktsiya/ru-industrialscales/item/123-prokhodnoj-proizvoditelnost-90-tonn-chas', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/122-prokhodnoj-proizvoditelnost-60-tonn-chas', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/121-prokhodnoj-proizvoditelnost-30-tonn-chas', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-industrialscales/item/120-prokhodnoj-proizvoditelnost-18-tonn-chas', redirectTo: '/ru/bunker-scales'},
      {path: 'ru/produktsiya/ru-tradeequipment', redirectTo: '/ru/products'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/3-kassovye-apparaty', redirectTo: '/ru/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/328-kassovyj-zhurnal', redirectTo: '/ru/products/item/5f294324fe44ee3bbbb844ef'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/197-0008383', redirectTo: '/ru/products/subcategory/5f1e87661c902614531f7ece/item/5f229803fe44ee3bbbb84482'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/196-0008384', redirectTo: '/ru/products/subcategory/5f1e87661c902614531f7ece/item/5f22ad58fe44ee3bbbb84486'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/180-0008696', redirectTo: '/ru/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/28-printer-pos', redirectTo: '/ru/products/subcategory/5f1fd29377bb31183d7fd117'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/226-0008704', redirectTo: '/ru/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d81fe44ee3bbbb844e1'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/134-0007188', redirectTo: '/ru/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d23fe44ee3bbbb844e0'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/133-0007189', redirectTo: '/ru/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293cd9fe44ee3bbbb844df'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/29-pos-monitor', redirectTo: '/ru/products/subcategory/5f1fd26677bb31183d7fd111'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/305-0009841', redirectTo: '/ru/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290735fe44ee3bbbb844a6'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/264-0009330', redirectTo: '/ru/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290607fe44ee3bbbb844a5'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/201-0008409', redirectTo: '/ru/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f50f384c56ec5257dbc08c7'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/200-0008408', redirectTo: '/ru/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f4fa007c56ec5257dbc08bf'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/199-0009329', redirectTo: '/ru/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290578fe44ee3bbbb844a4'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/30-barcode-scaners', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/322-0009910', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c463355090533135334c'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/310-0009830', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/309-00090906', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291f02fe44ee3bbbb844c5'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/291-0009482', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e13fe44ee3bbbb844c3'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/290-0009481', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c28e355090533135334a'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/289-0009480', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f292b40fe44ee3bbbb844d6'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/256-0009285', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c3ce355090533135334b'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/247-0009091', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291d80fe44ee3bbbb844c2'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/246-0009091', redirectTo: '/ru/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/31-remote-service-terminals', redirectTo: '/ru/products/subcategory/5f1fd2b177bb31183d7fd11a'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/153-0007727', redirectTo: '/ru/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29456efe44ee3bbbb844f2'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/152-0007728', redirectTo: '/ru/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29462ffe44ee3bbbb844f3'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/150-0007729', redirectTo: '/ru/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f2944dafe44ee3bbbb844f1'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/149-0000368', redirectTo: '/ru/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f294467fe44ee3bbbb844f0'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/35-programs', redirectTo: '/ru/programs'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/159-alex-market', redirectTo: '/ru/alex-market'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/158-b52', redirectTo: '/ru/b52'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/157-flagman', redirectTo: '/ru/flagman'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/40-labelprinting', redirectTo: '/ru/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/41-printer-pos', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/248-0008705', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293e2dfe44ee3bbbb844e3'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/225-0008702', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ddcfe44ee3bbbb844e2'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/136-0007233', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/135-0008703', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/130-0007768', redirectTo: '/ru/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ee3fe44ee3bbbb844e5'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/48-measuring-cup', redirectTo: '/ru/products/subcategory/5f1fd2bd77bb31183d7fd11c'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/330-0007720', redirectTo: '/ru/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f291fcffe44ee3bbbb844c7'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/329-0007719', redirectTo: '/ru/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f293a2cfe44ee3bbbb844dd'},
      {path: 'ru/produktsiya/ru-tradeequipment/itemlist/category/49-denejnie-iasiki', redirectTo: '/ru/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/324-0009659', redirectTo: '/ru/products/subcategory/5f1fd27c77bb31183d7fd114/item/5f293934fe44ee3bbbb844dc'},
      {path: 'ru/produktsiya/ru-tradeequipment/item/323-0007316', redirectTo: '/ru/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'ru/uslugi', redirectTo: '/ru/support-service'},
      {path: 'ru/uslugi/suport-tehnic', redirectTo: '/ru/support-service'},
      {path: 'ru/uslugi/suportul-tehnic', redirectTo: '/ru/support-service'},
      {path: 'ru/aboutcompany/about-company', redirectTo: '/ru/about'},
      {path: 'ru/aboutcompany/contacts', redirectTo: '/ru/contacts'},
      {path: 'ru/price', redirectTo: '/ru/price-list'},
      {path: 'ru/price/scales', redirectTo: '/ru/price-list'},
      {path: 'ru/price/trade-equipment', redirectTo: '/ru/price-list'},
      {path: 'en/itemlist/category/1-torgovye-vesy', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'en/item/53-0001049', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29258cfe44ee3bbbb844d1'},
      {path: 'en/item/52-0001048', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292539fe44ee3bbbb844d0'},
      {path: 'en/item/51-0000929', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29265dfe44ee3bbbb844d3'},
      {path: 'en/item/50-0000931', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29271dfe44ee3bbbb844d5'},
      {path: 'en/item/49-0000928', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f292616fe44ee3bbbb844d2'},
      {path: 'en/item/1-0000930', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f2926cbfe44ee3bbbb844d4'},
      {path: 'en/itemlist/category/2-tovarnye-vesy', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3'},
      {path: 'en/item/307-0008073', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29249dfe44ee3bbbb844cf'},
      {path: 'en/item/60-0001028', redirectTo: '/en/products/subcategory/5f1fd20177bb31183d7fd10b/item/5f27a6c6fe44ee3bbbb84493'},
      {path: 'en/item/59-0000945', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29240bfe44ee3bbbb844ce'},
      {path: 'en/item/58-0000946', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2923befe44ee3bbbb844cd'},
      {path: 'en/item/57-0000947', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2922dffe44ee3bbbb844cc'},
      {path: 'en/item/56-0000944', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29228cfe44ee3bbbb844cb'},
      {path: 'en/item/55-0000925', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f2921f2fe44ee3bbbb844ca'},
      {path: 'en/item/54-0000924', redirectTo: '/en/products/subcategory/5f1e8d141c902614531f7ed3/item/5f29219ffe44ee3bbbb844c9'},
      {path: 'en/itemlist/category/19-precision-scales', redirectTo: '/en/products/subcategory/5f1fd1e777bb31183d7fd109'},
      {path: 'en/item/236-0008936', redirectTo: '/en/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b423fe44ee3bbbb84491'},
      {path: 'en/item/99-0000885', redirectTo: '/en/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b2b8fe44ee3bbbb84490'},
      {path: 'en/item/98-0000884', redirectTo: '/en/products/subcategory/5f1fd1e777bb31183d7fd109/item/5f22b1f4fe44ee3bbbb8448f'},
      {path: 'en/itemlist/category/20-platform-scales', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'en/item/77-0001013', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290217fe44ee3bbbb844a3'},
      {path: 'en/item/76-0001008', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29017ffe44ee3bbbb844a2'},
      {path: 'en/item/73-0000910', redirectTo: '/en/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f9ecfe44ee3bbbb8449d'},
      {path: 'en/item/72-0000900', redirectTo: '/en/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f28f90afe44ee3bbbb8449c'},
      {path: 'en/item/71-0000893', redirectTo: '/en/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f27ed77fe44ee3bbbb8449b'},
      {path: 'en/item/69-0001019', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'en/item/68-0001017', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'en/item/67-0001020', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'en/item/63-0000919', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290066fe44ee3bbbb844a1'},
      {path: 'en/item/62-0000916', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290002fe44ee3bbbb844a0'},
      {path: 'en/item/61-0000898', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f28fd93fe44ee3bbbb8449e'},
      {path: 'en/itemlist/category/21-label-printing-scales', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'en/item/97-0007917', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4/item/5f29386ffe44ee3bbbb844db'},
      {path: 'en/itemlist/category/22-crane-scales', redirectTo: '/en/products/subcategory/5f1fd25a77bb31183d7fd10f'},
      {path: 'en/item/102-0000892', redirectTo: '/en/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22ae0efe44ee3bbbb84487'},
      {path: 'en/item/101-0000891', redirectTo: '/en/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b0b7fe44ee3bbbb8448e'},
      {path: 'en/item/100-0000890', redirectTo: '/en/products/subcategory/5f1fd25a77bb31183d7fd10f/item/5f22b03bfe44ee3bbbb8448d'},
      {path: 'en/itemlist/category/23-monoray-scales', redirectTo: '/en/products/subcategory/5f1fd26077bb31183d7fd110'},
      {path: 'en/item/104-monorelsovye-npv-1000kg', redirectTo: '/en/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'en/item/103-monorelsovye-npv-500kg', redirectTo: '/en/products/subcategory/5f1fd26077bb31183d7fd110/item/5f4f5e82c56ec5257dbc08bb'},
      {path: 'en/itemlist/category/24-animal-scales', redirectTo: '/en/products/subcategory/5f1fd24877bb31183d7fd10e'},
      {path: 'en/item/92-0008178-0007517', redirectTo: '/en/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523279c56ec5257dbc08ca'},
      {path: 'en/item/91-0008177-0007517', redirectTo: '/en/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f523241c56ec5257dbc08c9'},
      {path: 'en/item/90-0008176-0007512', redirectTo: '/en/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f52314fc56ec5257dbc08c8'},
      {path: 'en/item/89-0008179-0007515', redirectTo: '/en/products/subcategory/5f1fd24877bb31183d7fd10e/item/5f5232cec56ec5257dbc08cb'},
      {path: 'en/itemlist/category/27-platform-scales-1000', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf'},
      {path: 'en/item/319-0010037', redirectTo: '/en/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f291bf8fe44ee3bbbb844c1'},
      {path: 'en/item/253-0000914', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290e2afe44ee3bbbb844ab'},
      {path: 'en/item/252-0007612', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2909fffe44ee3bbbb844a8'},
      {path: 'en/item/251-0000992', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291811fe44ee3bbbb844b8'},
      {path: 'en/item/250-0007134', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f29151dfe44ee3bbbb844b5'},
      {path: 'en/item/227-0008884', redirectTo: '/en/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f4f5db4c56ec5257dbc08ba'},
      {path: 'en/item/87-0010038', redirectTo: '/en/products/subcategory/5f1fd23b77bb31183d7fd10c/item/5f292cd7fe44ee3bbbb844d8'},
      {path: 'en/item/86-0007638', redirectTo: '/en/products/subcategory/5f1e8ce51c902614531f7ed0/item/5f2908b7fe44ee3bbbb844a7'},
      {path: 'en/item/85-0007622-0007354-0007623', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291b6afe44ee3bbbb844c0'},
      {path: 'en/item/84-0007619-0007620-0007621', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291a08fe44ee3bbbb844bd'},
      {path: 'en/item/83-0007619-0007620-0007621', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f2913effe44ee3bbbb844b4'},
      {path: 'en/item/82-0000917-0007615-0007616', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291204fe44ee3bbbb844b1'},
      {path: 'en/item/81-0001004-0001007', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291890fe44ee3bbbb844ba'},
      {path: 'en/item/80-0000963-0007608', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f291783fe44ee3bbbb844b7'},
      {path: 'en/item/79-0001003-0000920', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290c56fe44ee3bbbb844aa'},
      {path: 'en/item/78-0007328-0000921', redirectTo: '/en/products/subcategory/5f1e882a1c902614531f7ecf/item/5f290f2dfe44ee3bbbb844ad'},
      {path: 'en/produktsiya', redirectTo: '/en/products'},
      {path: 'en/produktsiya/en-tradescales', redirectTo: '/en/products'},
      {path: 'en/produktsiya/en-industrialscales', redirectTo: '/en/auto-index'},
      {path: 'en/produktsiya/en-industrialscales/itemlist/category/16-avtomobilnye-vesy', redirectTo: '/en/auto-index'},
      {path: 'en/produktsiya/en-industrialscales/item/314-bortovye-sistemy-vzveshivaniya', redirectTo: '/en/on-board-weighing-systems'},
      {path: 'en/produktsiya/en-industrialscales/item/118-electronic-axle-weighing-scales-in-motion', redirectTo: '/en/stationary-for-weighing-axle-loads'},
      {path: 'en/produktsiya/en-industrialscales/item/117-portabile-axle-weighing-scales', redirectTo: '/en/portable-for-weighing-axle-loads'},
      {path: 'en/produktsiya/en-industrialscales/item/116-farmer', redirectTo: '/en/mobile-farmer-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/115-kolejnye-npv-80-tonn', redirectTo: '/en/stationary-automotive-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/112-with-a-reinforced-concrete-platform-max-30t', redirectTo: '/en/stationary-automotive-scales'},
      {path: 'en/produktsiya/en-industrialscales/itemlist/category/17-vagonnye-vesy', redirectTo: '/en/wagon-index'},
      {path: 'en/produktsiya/en-industrialscales/item/119-rail-scales', redirectTo: '/en/wagon-scales'},
      {path: 'en/produktsiya/en-industrialscales/itemlist/category/18-doziruyushchie-sistemy', redirectTo: '/en/bunker-index'},
      {path: 'en/produktsiya/en-industrialscales/item/128-hooper-scales-120-th', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/127-hopper-scales-85-t-h', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/126-hopper-scales-60-t-h', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/125-viboi-electric', redirectTo: '/en/filling-batcher'},
      {path: 'en/produktsiya/en-industrialscales/item/124-viboi', redirectTo: '/en/filling-batcher'},
      {path: 'en/produktsiya/en-industrialscales/item/123-hopper-scales-90-t-h', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/122-hopper-scales-30-t-h', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/121-prokhodnoj-proizvoditelnost-30-tonn-chas', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/en-industrialscales/item/120-prokhodnoj-proizvoditelnost-18-tonn-chas', redirectTo: '/en/bunker-scales'},
      {path: 'en/produktsiya/ru-tradeequipment', redirectTo: '/en/products'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/3-kassovye-apparaty', redirectTo: '/en/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'en/produktsiya/ru-tradeequipment/item/328-kassovyj-zhurnal', redirectTo: '/en/products/item/5f294324fe44ee3bbbb844ef'},
      {path: 'en/produktsiya/ru-tradeequipment/item/197-0008383', redirectTo: '/en/products/subcategory/5f1e87661c902614531f7ece/item/5f229803fe44ee3bbbb84482'},
      {path: 'en/produktsiya/ru-tradeequipment/item/196-0008384', redirectTo: '/en/products/subcategory/5f1e87661c902614531f7ece/item/5f22ad58fe44ee3bbbb84486'},
      {path: 'en/produktsiya/ru-tradeequipment/item/180-0000263', redirectTo: '/en/products/subcategory/5f1e87661c902614531f7ece'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/28-printer-pos', redirectTo: '/en/products/subcategory/5f1fd29377bb31183d7fd117'},
      {path: 'en/produktsiya/ru-tradeequipment/item/226-0008704', redirectTo: '/en/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d81fe44ee3bbbb844e1'},
      {path: 'en/produktsiya/ru-tradeequipment/item/134-0007188', redirectTo: '/en/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293d23fe44ee3bbbb844e0'},
      {path: 'en/produktsiya/ru-tradeequipment/item/133-0007189', redirectTo: '/en/products/subcategory/5f1fd29377bb31183d7fd117/item/5f293cd9fe44ee3bbbb844df'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/29-pos-monitor', redirectTo: '/en/products/subcategory/5f1fd26677bb31183d7fd111'},
      {path: 'en/produktsiya/ru-tradeequipment/item/305-0009841', redirectTo: '/en/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290735fe44ee3bbbb844a6'},
      {path: 'en/produktsiya/ru-tradeequipment/item/264-micropos-capacitive', redirectTo: '/en/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290607fe44ee3bbbb844a5'},
      {path: 'en/produktsiya/ru-tradeequipment/item/201-display', redirectTo: '/en/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f50f384c56ec5257dbc08c7'},
      {path: 'en/produktsiya/ru-tradeequipment/item/200-scitivateli', redirectTo: '/en/products/subcategory/5f1fd26c77bb31183d7fd112/item/5f4fa007c56ec5257dbc08bf'},
      {path: 'en/produktsiya/ru-tradeequipment/item/199-micropos', redirectTo: '/en/products/subcategory/5f1fd26677bb31183d7fd111/item/5f290578fe44ee3bbbb844a4'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/30-barcode-scaners', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119'},
      {path: 'en/produktsiya/ru-tradeequipment/item/322-0009910', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c463355090533135334c'},
      {path: 'en/produktsiya/ru-tradeequipment/item/310-0009830', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'en/produktsiya/ru-tradeequipment/item/309-00090906', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291f02fe44ee3bbbb844c5'},
      {path: 'en/produktsiya/ru-tradeequipment/item/291-0009482', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e13fe44ee3bbbb844c3'},
      {path: 'en/produktsiya/ru-tradeequipment/item/290-0009481', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c28e355090533135334a'},
      {path: 'en/produktsiya/ru-tradeequipment/item/289-0009480', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f292b40fe44ee3bbbb844d6'},
      {path: 'en/produktsiya/ru-tradeequipment/item/256-0009285', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f43c3ce355090533135334b'},
      {path: 'en/produktsiya/ru-tradeequipment/item/247-0009091', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291d80fe44ee3bbbb844c2'},
      {path: 'en/produktsiya/ru-tradeequipment/item/246-0009091', redirectTo: '/en/products/subcategory/5f1fd2a877bb31183d7fd119/item/5f291e93fe44ee3bbbb844c4'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/31-remote-service-terminals', redirectTo: '/en/products/subcategory/5f1fd2b177bb31183d7fd11a'},
      {path: 'en/produktsiya/ru-tradeequipment/item/153-0007727', redirectTo: '/en/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29456efe44ee3bbbb844f2'},
      {path: 'en/produktsiya/ru-tradeequipment/item/152-0007728', redirectTo: '/en/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f29462ffe44ee3bbbb844f3'},
      {path: 'en/produktsiya/ru-tradeequipment/item/150-0007729', redirectTo: '/en/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f2944dafe44ee3bbbb844f1'},
      {path: 'en/produktsiya/ru-tradeequipment/item/149-0000368', redirectTo: '/en/products/subcategory/5f1fd2b177bb31183d7fd11a/item/5f294467fe44ee3bbbb844f0'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/35-programs', redirectTo: '/en/programs'},
      {path: 'en/produktsiya/ru-tradeequipment/item/159-alex-market', redirectTo: '/en/alex-market'},
      {path: 'en/produktsiya/ru-tradeequipment/item/158-b52', redirectTo: '/en/b52'},
      {path: 'en/produktsiya/ru-tradeequipment/item/157-flagman', redirectTo: '/en/flagman'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/40-labelprinting', redirectTo: '/en/products/subcategory/5f1e8d291c902614531f7ed4'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/41-printer-pos', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118'},
      {path: 'en/produktsiya/ru-tradeequipment/item/248-0008705', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293e2dfe44ee3bbbb844e3'},
      {path: 'en/produktsiya/ru-tradeequipment/item/225-0008702', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ddcfe44ee3bbbb844e2'},
      {path: 'en/produktsiya/ru-tradeequipment/item/136-0007233', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'en/produktsiya/ru-tradeequipment/item/135-0008703', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ea2fe44ee3bbbb844e4'},
      {path: 'en/produktsiya/ru-tradeequipment/item/130-0007768', redirectTo: '/en/products/subcategory/5f1fd2a377bb31183d7fd118/item/5f293ee3fe44ee3bbbb844e5'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/48-measuring-cup', redirectTo: '/en/products/subcategory/5f1fd2bd77bb31183d7fd11c'},
      {path: 'en/produktsiya/ru-tradeequipment/item/330-0007720', redirectTo: '/en/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f291fcffe44ee3bbbb844c7'},
      {path: 'en/produktsiya/ru-tradeequipment/item/329-0007719', redirectTo: '/en/products/subcategory/5f1fd2bd77bb31183d7fd11c/item/5f293a2cfe44ee3bbbb844dd'},
      {path: 'en/produktsiya/ru-tradeequipment/itemlist/category/49-denejnie-iasiki', redirectTo: '/en/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'en/produktsiya/ru-tradeequipment/item/324-0009659', redirectTo: '/en/products/subcategory/5f1fd27c77bb31183d7fd114/item/5f293934fe44ee3bbbb844dc'},
      {path: 'en/produktsiya/ru-tradeequipment/item/323-0007316', redirectTo: '/en/products/subcategory/5f1fd27c77bb31183d7fd114'},
      {path: 'en/uslugi', redirectTo: '/en/support-service'},
      {path: 'en/uslugi/suport-tehnic', redirectTo: '/en/support-service'},
      {path: 'en/uslugi/suportul-tehnic', redirectTo: '/en/support-service'},
      {path: 'en/aboutcompanyen/company/about-company', redirectTo: '/en/about'},
      {path: 'en/aboutcompanyen/company/contacts', redirectTo: '/en/contacts'},
      {path: 'en/price', redirectTo: '/en/price-list'},
      {path: 'en/pricelist', redirectTo: '/en/price-list'},
      {path: 'en/price/trade-equipment', redirectTo: '/en/price-list'},
      {path: 'programs', component: ProgrammesPageComponent},
      {path: 'alex-market', component: AlexMarketPageComponent},
      {path: 'flagman', component: FlagmanPageComponent},
      {path: 'b52', component: B52PageComponent},
      {path: 'pos-compact', component: PosCompactPageComponent},
      {path: 'pos-alimentara', component: PosAlimentaraPageComponent},
      {path: 'pos-market', component: PosMarketPageComponent},
      {path: 'pos-horeca-1', component: PosHorecaonePageComponent},
      {path: 'pos-horeca-2', component: PosHorecatwoPageComponent},
      {path: 'support-service', component: SupportServicePageComponent},
      {path: 'contacts', component: ContactsPageComponent},
      {path: 'about', component: AboutPageComponent},
      {path: 'auto-restourant', component: AutoRestourantPageComponent},
      {path: 'auto-fitnes', component: AutoFitnesPageComponent},
      {path: 'auto-hotel', component: AutoHotelPageComponent},
      {path: 'auto-billiard', component: AutoBilliardPageComponent},
      {path: 'news', component: NewsPageComponent},
      {path: 'news/post/:id', component: PostPageComponent},
      {path: 'products', component: ShopPageComponent},
      {path: 'products/subcategory/:id', component: ShopPageComponent},
      {path: 'products/subcategory/:id/item/:id', component: ProductsPageComponent},
      {path: 'products/item/:id', component: ProductsPageComponent},
      {path: 'cart', component: CartPageComponent},
      {path: 'price-list', component: CheckoutPageComponent},
      {path: 'certifications', component: ThankyouPageComponent},
      {path: 'auto-index', component: IndustrialComponent},
      {path: 'stationary-automotive-scales', component: StaticAutoPageComponent},
      {path: 'mobile-farmer-scales', component: MovingFermerPageComponent},
      {path: 'portable-for-weighing-axle-loads', component: PerenosnieNagruzkaPageComponent},
      {path: 'stationary-for-weighing-axle-loads', component: StaticNagrizkaPageComponent},
      {path: 'on-board-weighing-systems', component: BortSystemPageComponent},
      {path: 'modernization-of-automotive-scales', component: ModernAutoPageComponent},
      {path: 'wagon-index', component: VagonIndexPageComponent},
      {path: 'wagon-scales', component: VagonPageComponent},
      {path: 'wagon-modern', component: VagonModernPageComponent},
      {path: 'bunker-index', component: DozatorIndexPageComponent},
      {path: 'bunker-scales', component: DozatorOpenPageComponent},
      {path: 'dozator-table', component: DozatorTablePageComponent},
      {path: 'filling-batcher', component: DozatorViboiPageComponent},
      {path: 'modern-bunker-scales', component: DozatorModernPageComponent},
      {path: 'automatic-weight-and-dimension-control-system', component: AutomaticWeightControlSystemComponent},
      {path: 'mobile-truck-scale-with-continuous-platform', component: MobileTruckScaleWithContinuousPlatformComponent},
      {path: 'solutions', component: SolutionsComponent},
      {path: 'agricultural-industry', component: AgriculturalComponent},
      {path: 'dispensing-mixing-line-of-a-glass-factory', component: DispensingComponent},
      {path: 'livestock-industry', component: LivestockComponent},
      {path: 'modernization-of-concrete-plants', component: ModernizationComponent},
      {path: 'weighing-of-road-and-rail-transport', component: WeighingComponent},
      {path: 'aurora', component: AuroraComponent},
      {path: 'account/signup', component: SignUpComponent, canActivate: [AuthGuard]},
      {path: 'account/login', component: LoginComponent},
      {path: 'aboutcompany/scales-control-polzovatelskoe-soglashenie', component: PagePolicyComponent},
      {path: 'cookie-policy', component: CookiePolicyComponent},
    ]
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
},
  { path: '**', redirectTo: '/', pathMatch: 'full' },

    ];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    enableTracing: false,
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabled',
}),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
