<div class="bginfolist">


  <div class="container">


    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row pt-5">

        <!-- Grid column -->
        <div class="col-lg-5 mb-4 align-self-center text-center text-lg-left">
          <div class="pb-5 wow fadeInLeft" data-wow-delay="0.6s">
            <mdb-card bgColor="blue">
              <mdb-card-body>
                <h2 class="font-weight-bold amber-lighter-hover white-text mb-0">{{'Support.Header.Title' | translate}}</h2>
              </mdb-card-body>
            </mdb-card>
          </div>

          <div class="media white wow fadeInLeft" data-wow-delay="1.0s">
            <i class="fas fa-info fa-3x blue  p-4 rounded-left text-white mr-3"></i>
            <div class="media-body ">
              <p class=" pt-3 mr-3 flex-center text-muted">
                <small>
                  {{'Support.Header.Subtitle' | translate}}
                </small>
              </p>
            </div>
          </div>


        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-5 mb-4">


        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->


    <!--First row-->
    <div class="row">

      <!--First column-->
      <div class="col-md-4 mb-4 wow zoomIn" data-wow-delay="0.6s">

        <!-- Card -->
        <a class="card hoverable">

          <!-- Card content -->
          <div class="card-body">

            <div class="media">
            <!--               
              <span
                class="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                          <img src="assets/1-icon-3657.png" class="img-fluid" alt="Sample project image">
              </span> 
            -->
              <div class="media-body">
                <h5 class="dark-grey-text mb-3">{{'Support.Header.TradeTitle' | translate}}</h5>
                <p class="font-weight-bold blue-text mb-0">{{'Support.Header.TradeSubtitle' | translate}}</p>
              </div>
            </div>

          </div>

        </a>
        <!-- Card -->

      </div>
      <!--First column-->

      <!--Second column-->
      <div class="col-md-4 mb-4 wow zoomIn" data-wow-delay="0.6s">

        <!-- Card -->
        <a class="card hoverable">

          <!-- Card content -->
          <div class="card-body">

            <div class="media">
              <span
                class="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                          <img src="assets/1-icon-3657.png" class="img-fluid" alt="Sample project image">
              </span>
              <div class="media-body">
                <h5 class="dark-grey-text mb-3">{{'Support.Header.PromTitle' | translate}}</h5>
                <p class="font-weight-bold blue-text mb-0">{{'Support.Header.PromSubtitle' | translate}}</p>
              </div>
            </div>

          </div>

        </a>
        <!-- Card -->
        <!-- Card -->

      </div>
      <!--Second column-->

    </div>
    <!--First row-->


  </div>

</div>
