<div class="gotop">
  <app-headerhotel></app-headerhotel>
</div>
<app-firsthotel></app-firsthotel>
<app-divhotel></app-divhotel>
<app-secondhotel></app-secondhotel>
<app-threehotel></app-threehotel>
<app-divhotel></app-divhotel>
<app-fourhotel></app-fourhotel>
<app-fivehotel></app-fivehotel>
<app-divtech></app-divtech>
<app-questions></app-questions>
