<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item
        [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item
        [routerLink]="'/bunker-index'">{{'Bunker.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'Bunker.PackingDispenser.Title' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row pt-4">

        <!-- Grid column -->
        <div class="col-lg-12 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'Bunker.PackingDispenser.Title' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'Bunker.PackingDispenser.SubTitle'  | translate"></div>
          </h5>


        </div>
        <!-- Grid column -->


      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>


  <section class="container">
    <div class="row mb-5 d-flex align-items-center justify-content-end">
      <div class="col-md-4 col-lg-4">
        <div class="view overlay rounded">
          <img src="assets/industrial/bunker/cv.png" class="img-fluid"
               alt="Sample project image"/>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="">
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'Bunker.PackingDispenser.Image'  | translate"></div>
          </h5>
        </div>
      </div>
    </div>

  </section>


  <section class="container">


    <div class="container py-5 my-5">
      <section class="p-md-3 mx-md-5">
        <div class="row mb-5 d-flex align-items-center">
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'Bunker.PackingDispenser.IconTitleOne' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <div [innerHTML]="'Bunker.PackingDispenser.IconTextOne'  | translate"></div>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/bunker/l1.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="row mb-5 d-flex align-items-center justify-content-end">
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/bunker/l2.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'Bunker.PackingDispenser.IconTitleTwo' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <div [innerHTML]="'Bunker.PackingDispenser.IconTextTwo'  | translate"></div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-6 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsn.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSN' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-7">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstOne' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastOne' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstTwo' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastTwo' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstFourteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastFourteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Six.FirstFifteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Six.LastFifteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </section>


</div>
