<div class="view">
  <div style="height: 109px;" class="bginfoprog">
  </div>
  <div class="mask pt-3">
    <div class="">
      <mdb-card-body cascade="true" class="text-center ">
        <!--Title-->
        <mdb-card-title>
          <h3>
            <strong class="white-text wow fadeIn" data-wow-delay="0.6s">{{'Programs.List.Info' | translate}}</strong>
          </h3>
        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>
</div>

