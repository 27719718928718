<div class="view">
  <div style="height: 100%;" class="bgquest">
    <div class="pt-1 wow bounceIn" data-wow-delay="0.6s">
      <mdb-card-body cascade="true" class="text-center pt-5 pb-3">
        <!--Title-->
        <mdb-card-title>
          <h3>
            <strong class="white-text">{{ 'HOME.QUESTIONS.Title' | translate }}</strong>
          </h3>
        </mdb-card-title>

        <mdb-card-text class="white-text">
          <h5>{{ 'HOME.QUESTIONS.Text' | translate }}</h5>
        </mdb-card-text>

        <app-alerting></app-alerting>


        <!-- Default inline form -->
        <form class="form-inline flex-center" [formGroup]="form" (ngSubmit)="onSubmit()">
          <!-- Default input -->

          <div class="row">
            <div class="col-md-4">
                            <span
                              class="orange-text font-weight-bold"
                              *ngIf="form.get('name').invalid && form.get('name').touched"
                            >
                    <span *ngIf="form.get('name').errors['required']">
                      Имя не может быть пустым
                    </span>
                    </span>
              <input
                mdbInput
                type="text"
                class="form-control mb-2 mr-sm-2"
                formControlName="name"
                id="name"
                placeholder="{{ 'HOME.QUESTIONS.Name' | translate }}"
                [ngClass]="{'invalid': form.get('name').invalid && form.get('name').touched }"
              >
            </div>
            <div class="col-md-4">

              <!-- Default input -->
              <span
                class="orange-text font-weight-bold"
                *ngIf="form.get('email').touched && form.get('email').invalid"
              >
                <span *ngIf="form.get('email').errors.required">
                  Email не может быть пустым
                </span>
                <span *ngIf="form.get('email').errors.email">
                  Пожалуйста. введите правильный Email адрес
                </span>
              </span>
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">@</div>
                </div>
                <input
                  mdbInput
                  type="text"
                  formControlName="email"
                  class="form-control py-0"
                  id="email"
                  placeholder="{{ 'HOME.QUESTIONS.Email' | translate }}"
                  [ngClass]="{'invalid': form.get('email').invalid && form.get('email').touched }"
                >
              </div>

            </div>
            <div class="col-md-4">
              <div
                class="orange-text font-weight-bold"
                *ngIf="form.get('phone').touched && form.get('phone').invalid"
              >
                <span *ngIf="form.get('phone').errors.required">
                  Телефон не может быть пустым
                </span>
                <span *ngIf="form.get('phone').errors.pattern">
                  Пожалуйста. введите правильный Телефон
                </span>
                <span *ngIf="form.get('phone').errors.minlength">
                  Минимальная длина 9 цифр
                </span>
              </div>
              <input
                mdbInput
                type="text"
                class="form-control mb-2 mr-sm-2"
                formControlName="phone"
                id="phone"
                placeholder="{{ 'HOME.QUESTIONS.Phone' | translate }}"
                [ngClass]="{'invalid': form.get('phone').invalid && form.get('phone').touched }"
              >
            </div>
          </div>


          <button
            [disabled]="form.invalid || form.disabled"
            data-toggle="modal"
            data-target="#basicExample"
            type="submit"
            mdbBtn
            color="primary"
            class="mt-0"
          >
            {{ 'BUTTONS.Question' | translate}}
          </button>
        </form>


      </mdb-card-body>
    </div>
  </div>

</div>
