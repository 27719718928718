<div class="gotop">
  <app-headerflag></app-headerflag>
</div>
<app-secondflag></app-secondflag>
<app-treeflag></app-treeflag>
<app-divert></app-divert>
<app-fourflag></app-fourflag>
<app-divflag></app-divflag>
<app-sixflag></app-sixflag>
<app-sevenflag></app-sevenflag>
<app-flag></app-flag>
<app-divtech></app-divtech>
<app-complectflag></app-complectflag>
<app-questions></app-questions>
