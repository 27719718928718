import { Component,  } from '@angular/core';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent  {
  public map: any = { lat: 51.678418, lng: 7.809007 };


}
