<div class="container mt-5 wow zoomIn" data-wow-delay="0.6s">




    <!-- Section heading -->
    <h2 class="text-center h1-responsive font-weight-bold my-5 wow fadeInDown" data-wow-delay="0.6s">{{ 'HOME.NEWS.Title' | translate}}</h2>


    <div  class="row">

      <!-- Grid column -->
      <ng-container *ngFor="let post of posts | slice:0:4">
      <div  class="col-lg-3 col-md-12 mb-lg-0 mb-4">

        <!-- Card -->
        <div class="card hoverable">

          <!-- Card image -->
          <img class="card-img-top" src="{{post.img}}" alt="Card image cap">

          <!-- Card content -->
          <div class="card-body">

            <!-- Title -->
            <a [routerLink]="['news/post', post._id]" class="black-text font-weight-bold">
              <ng-container *ngIf="this.main.lang == 'ru'">
                {{post.title}}
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'en'">
                {{post.titleEn}}
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'ro'">
                {{post.titleRo}}
              </ng-container>
            </a>
            <!-- Text -->
            <p class="card-title text-muted font-small mt-3 mb-2">
              <ng-container *ngIf="this.main.lang == 'ru'">
                {{post.preview}}
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'en'">
                {{post.previewEn}}
              </ng-container>
              <ng-container *ngIf="this.main.lang == 'ro'">
                {{post.previewRo}}
              </ng-container>
            </p>
            <div class="d-flex justify-content-between pt-3">
            <p [routerLink]="['news/post', post._id]" type="button" class=" text-primary p-0 mx-0">{{ 'HOME.NEWS.See' | translate}}<i class="fa fa-angle-right ml-2"></i></p><span class="d-flex justify-content-end">{{post.date | date:'dd.MM.yy': null: 'ru'}}</span>
            </div>
          </div>

        </div>
        <!-- Card -->
      </div>
      <!-- Grid column -->
      </ng-container>


    </div>
    <!-- Grid row -->



</div>
















