<div>
<div class=" my-1 py-5  grey lighten-5">
  <!--Section: Content-->
  <section class="text-center px-md-5 mx-md-5 dark-grey-text wow bounceIn" data-wow-delay="0.6s">

    <img src="assets/dd11.png" class="img-fluid wow fadeInRight" data-wow-delay="0.6s" alt="smaple image">
    <h1 class="font-weight-bold blue-text">{{'AlexMarket.Complect.TitleTop' | translate}}</h1>
    <h6 class="font-weight-bold grey-text">{{'AlexMarket.Complect.TitleBottom' | translate}}</h6>

  </section>
  <!--Section: Content-->


</div>







<div class=" mt-0 mb-0">
  <!-- Section -->
  <section class="  wow fadeInLeft" data-wow-delay="0.6s">

    <div class="row no-gutters">

      <div class="col-lg-6 order-md-2">
        <!-- Card -->
        <div class="card card-image bgpos flex-center">
          <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded ">
            <div>
              <h5 class="py-3 font-weight-bold">
                <strong>{{'AlexMarket.Complect.ComTitleTop' | translate}} </strong><p>
                <h2><strong>{{'AlexMarket.Complect.ComTitleBottom' | translate}}</strong></h2>
              </h5>
              <p class="pb-0"><div [innerHTML]="'AlexMarket.Complect.ComText'  | translate"></div>
              <a routerLink="/pos-compact" class="btn btn-white btn-rounded btn-md black-text"><i class="fas fa-clone left"></i>
                {{'AlexMarket.Complect.ComButton' | translate}}
              </a>
            </div>
          </div>
        </div>
        <!-- Card -->

      </div>

      <div class="col-lg-6  bgcomplect3  img-fluid">

        <div class="">

        </div>
      </div>

    </div>

  </section>
  <!-- Section -->






  <!-- Grid row -->
  <div class="row no-gutters  wow fadeInRight" data-wow-delay="0.6s">

    <!-- Grid column -->
    <div class="col-md-6 mb-0">

      <!-- Card -->
      <div class="card-image bgalim flex-center">
        <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded">
          <div>
            <h5 class="py-3 font-weight-bold">
              <strong>{{'AlexMarket.Complect.AliTitleTop' | translate}}</strong>
                <p><h2><strong>{{'AlexMarket.Complect.AliTitleBottom' | translate}}</strong></h2>
            </h5>
            <p class="pb-0"><div [innerHTML]="'AlexMarket.Complect.AliText'  | translate"></div>

            <a routerLink="/pos-alimentara" class="btn btn-white btn-rounded btn-md black-text"><i class="fas fa-clone left"></i>
              {{'AlexMarket.Complect.AliButton' | translate}}

            </a>
          </div>
        </div>
      </div>
      <!-- Card -->

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-md-6 mb-0">

      <!-- Card -->
      <div class="card-image bgcomplect2 ">
        <div class="text-white text-center d-flex align-items-center  py-5 px-3 px-md-5 rounded">

        </div>
      </div>
      <!-- Card -->

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->
</div>









    <div class="row no-gutters  wow fadeInLeft" data-wow-delay="0.6s">

      <div class="col-lg-6 order-md-2">
        <!-- Card -->
        <div class="card card-image bgsuper flex-center">
          <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded ">
            <div>
              <h5 class="py-3 font-weight-bold">
                <strong>{{'AlexMarket.Complect.MarkTitleTop' | translate}}</strong>
                <p> <h2><strong>{{'AlexMarket.Complect.MarkTitleBottom' | translate}}</strong></h2>
              </h5>
              <p class="pb-0">{{'AlexMarket.Complect.MarkText' | translate}}
            </p>
              <a routerLink="/pos-market" class="btn btn-white btn-rounded btn-md black-text"><i class="fas fa-clone left"></i>
                {{'AlexMarket.Complect.MarkButton' | translate}}
              </a>
            </div>
          </div>
        </div>
        <!-- Card -->

      </div>

      <div class="col-lg-6 mx-auto d-flex bgcomplect1 align-items-center text-white">
        <div class="px-4 py-4 py-lg-0">

        </div>
      </div>

    </div>











  <!-- Grid row -->
  <div class="row no-gutters  wow fadeInRight" data-wow-delay="0.6s">

    <!-- Grid column -->
    <div class="col-md-6 mb-0">

      <!-- Card -->
      <div class="card-image bgaurora flex-center">
        <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded">
          <div>
            <h5 class="py-3 font-weight-bold">
              <strong>{{'AlexMarket.Complect.AuroraTitleTop' | translate}}</strong>
              <p><h2><strong>{{'AlexMarket.Complect.AuroraTitleBottom' | translate}}</strong></h2>
            </h5>
            <p class="pb-0"><div [innerHTML]="'AlexMarket.Complect.AuroraText'  | translate"></div>

            <a routerLink="/aurora" class="btn btn-white btn-rounded btn-md black-text"><i class="fas fa-clone left"></i>
              {{'AlexMarket.Complect.AuroraButton' | translate}}

            </a>
          </div>
        </div>
      </div>
      <!-- Card -->

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-md-6 mb-0">

      <!-- Card -->
      <div class="card-image bgcomplect4 ">
        <div class="text-white text-center d-flex align-items-center  py-5 px-3 px-md-5 rounded">

        </div>
      </div>
      <!-- Card -->

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->





</div>
