<div class="bgbhead ">
<section class="mask rgba-blue-light flex-center">
<div class="container flex-center">
    <div class="col-md-6 mx-auto pl-5 d-none d-md-block">
      <div class="view mb-0 pb-0" >
        <img src="assets/b52/Б52-фото2.png" class="img-fluid wow fadeInLeft pt-5 " data-wow-delay="0.6s" alt="smaple image">
      </div>
    </div>

  <section class=" px-md-5 mx-md-5 dark-grey-text pt-5">
    <div class="row mb-5 ">
      <div class="col-md-12  col-lg-12 mb-4">
        <div class="view mb-5">
          <img src="assets/b52/b52-logo1.jpg" class="img-fluid wow fadeInLeft pt-5"  data-wow-delay="0.6s" alt="smaple image">
        </div>
        <div class="col-md-12 mb-4">
        <h5 class="font-weight-bold white-text">
          <div [innerHTML]="'B52.Header.Title'  | translate"></div>
        </h5>
        </div>
      </div>
    </div>

  </section>
</div>
</section>
</div>









