import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divfit',
  templateUrl: './divfit.component.html',
  styles: [
  ]
})
export class DivfitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
