<div class="container pb-4">
<div class="pt-5 flex-center wow bounceIn" data-wow-delay="0.6s">
  <mdb-card  style="width: 1300px; ">
    <!-- Card content -->
    <mdb-card-body cascade="true" class="pt-5 text-left bginfolist">

      <!--Title-->
      <mdb-card-title class="">
        <h4>
          <strong>{{'Flagman.DivTech.Title' | translate}}</strong>
        </h4>
      </mdb-card-title>


      <mdb-card-text class="black-text -align-left">
        <div [innerHTML]="'Flagman.DivTech.Text'  | translate"></div>
      </mdb-card-text>
      <div class="mt-5">
      <button type="button" class="btn btn-primary btn-rounded">{{'BUTTONS.Support' | translate }}</button>
      </div>
    </mdb-card-body>

  </mdb-card>

</div>
</div>
