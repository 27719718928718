<div class="alert-wrap" *ngIf="text">
  <div
    class="alert"
    [ngClass]="{
    'alert-success': type === 'success',
    'alert-warning': type === 'warning',
    'alert-danger': type === 'danger'
    }"
  >
    <p>{{text}}</p>
  </div>
</div>
