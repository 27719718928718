  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/solutions'">{{'SOLUTION.Index.Title' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'SOLUTION.Index.SolutionNameOne' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'SOLUTION.Index.SolutionNameOne' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'SOLUTION.Agricultural.SubTitle' | translate}}
          </h5>


        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4 mb-3">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/solutions/index/image0033-min.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->



    <section class="container">

    <div class="card-header white py-3 my-3">
        <a [routerLink]="'/filling-batcher'">
          <h4 class="font-weight-bold mb-0">{{'SOLUTION.Agricultural.ContentOne.Title' | translate}}</h4>
        </a>
    </div>

    <div class="row mb-5 d-flex align-items-center justify-content-end">
      <div class="col-md-4 col-lg-4">
        <div class="view overlay rounded">
          <img src="assets/solutions/agricultural/image003-min.jpg" class="img-fluid"
               alt="Sample project image" />
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="">
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'SOLUTION.Agricultural.ContentOne.SubTitle'  | translate"></div>

          </h5>
        </div>
      </div>
    </div>

  </section>


    <section class="container">

      <div class="card-header white py-3 my-3 d-flex flex-row-reverse">
        <a [routerLink]="'/products/subcategory/5f1e882a1c902614531f7ecf'">
          <h4 class="font-weight-bold mb-0">{{'SOLUTION.Agricultural.ContentTwo.Title' | translate}}</h4>
        </a>
      </div>

      <div class="row mb-5 d-flex align-items-center justify-content-end">
        <div class="col-md-4 col-lg-4 order-2">
          <div class="view overlay rounded">
            <img src="assets/solutions/agricultural/image005-min.jpg" class="img-fluid"
                 alt="Sample project image" />
            <a>
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 order-1">
          <div class="">
            <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
              <div [innerHTML]="'SOLUTION.Agricultural.ContentTwo.SubTitle'  | translate"></div>

            </h5>
          </div>
        </div>
      </div>

    </section>



    <section class="container">

      <div class="card-header white py-3 my-3">
        <a [routerLink]="'/products/subcategory/5f1fd24877bb31183d7fd10e'">
          <h4 class="font-weight-bold mb-0">{{'SOLUTION.Agricultural.ContentThree.Title' | translate}}</h4>
        </a>
      </div>

      <div class="row mb-5 d-flex align-items-center justify-content-end">
        <div class="col-md-4 col-lg-4">
          <div class="view overlay rounded">
            <img src="assets/solutions/agricultural/image007-min.jpg" class="img-fluid"
                 alt="Sample project image" />
            <a>
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <div class="col-lg-8 col-md-6">
          <div class="">
            <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
              <div [innerHTML]="'SOLUTION.Agricultural.ContentThree.SubTitle'  | translate"></div>

            </h5>
          </div>
        </div>
      </div>

    </section>



    <section class="container">

      <div class="card-header white py-3 my-3 d-flex flex-row-reverse">
        <a [routerLink]="'/auto-index'">
          <h4 class="font-weight-bold mb-0">{{'SOLUTION.Agricultural.ContentFour.Title' | translate}}</h4>
        </a>
      </div>

      <div class="row mb-5 d-flex align-items-center justify-content-end">
        <div class="col-md-4 col-lg-4 order-2">
          <div class="view overlay rounded">
            <img src="assets/solutions/agricultural/image009-min.jpg" class="img-fluid"
                 alt="Sample project image" />
            <a>
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 order-1">
          <div class="">
            <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
              <div [innerHTML]="'SOLUTION.Agricultural.ContentFour.SubTitle'  | translate"></div>

            </h5>
          </div>
        </div>
      </div>

    </section>

  </div>
