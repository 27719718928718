import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-morepos',
  templateUrl: './morepos.component.html',
  styles: [
  ]
})
export class MoreposComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
