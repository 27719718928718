<section class="container pt-5 wow zoomIn" data-wow-delay="0.3s">
  <div style="font-family: 'Times New Roman'">
    <h5>
      <div [innerHTML]="'CarWeights.Category.Text'  | translate"></div>
    </h5>
  </div>
  <div class="row pt-5 pb-5 flex-center">
    <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInLeft" data-wow-delay="0.3s">
      <div class="avatar white d-flex justify-content-center align-items-center">

        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        <img
          style="max-height: 300px"
          lightbox backgroundColor="white" src="assets/cert/bsaa.jpg"
          class="img-fluid z-depth-1"
        />
      </div>
      <div class="text-center mt-4">
        <p class="font-weight-bold mb-lg-0">
          {{'Certificates.BSA' | translate}}
        </p>
      </div>
      <div class="text-center mt-4">
        <p class="font-weight-bold mb-0">
          {{'Certificates.BDA' | translate}}
        </p>
      </div>
    </div>


  </div>


</section>
