<section class="text-center my-5">
  <div class="container">
<h2 class="h1-responsive font-weight-bold my-5 wow fadeInDown" data-wow-delay="0.6s">{{ 'HOME.VIDEO.Title' | translate }}</h2>
<div class="row pl-3 pr-3">
  <div class="col-lg-3 col-md-4 mb-3 wow fadeInLeft" data-wow-delay="0.6s">
    <a><img class="img-fluid z-depth-1" src="assets/video/autores.jpg" alt="video" (click)="videoModal1.show()"></a>
    <div class="pt-3">
    <strong>Автоматизация ресторана от Alex S&E</strong>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 mb-3 wow fadeInUpBig" data-wow-delay="0.6s">
    <a><img class="img-fluid z-depth-1" src="assets/video/maxresdefault.jpg" alt="video" (click)="videoModal2.show()"></a>
    <div class="pt-3">
      <strong>Мобильное приложение AlexTerminal
      </strong>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 mb-3 wow fadeInDownBig" data-wow-delay="0.6s">
    <a><img class="img-fluid z-depth-1" src="assets/video/autobo.jpg" alt="video" (click)="videoModal3.show()"></a>
    <div class="pt-3">
      <strong>Автоматизация торговли Alex S&E</strong>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 mb-3 wow fadeInRight" data-wow-delay="0.6s">
    <a><img class="img-fluid z-depth-1" src="assets/video/vesi.jpg" alt="video" (click)="videoModal4.show()"></a>
    <div class="pt-3">
      <strong>Alex S&E Автомобильные весы Фермер
      </strong>
    </div>
  </div>

</div>

    <div class="flex-center">
      <a  href="https://www.youtube.com/channel/UCZeEuCodwjhQIwugyqWDBAA/videos" target="_blank" >
      <button  class=" " mdbBtn type="button" color="primary"  mdbWavesEffect>{{'BUTTONS.AllVideo' | translate}}</button>
      </a>
    </div>

<div mdbModal #videoModal1="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myVideoModal1Label"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body mb-0 p-0">
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/B_wa7AB_EzA" allowfullscreen></iframe>
        </div>
      </div>
      <div class="modal-footer justify-content-center">

        <button mdbBtn type="button" color="primary" rounded="true" outline="true" size="md" (click)="videoModal1.hide()">{{ 'HOME.VIDEO.Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #videoModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myVideoModal2Label"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body mb-0 p-0">
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/gyZQ1uhvrBI" allowfullscreen></iframe>
        </div>
      </div>
      <div class="modal-footer justify-content-center">

        <button mdbBtn type="button" color="primary" rounded="true" outline="true" size="md" (click)="videoModal2.hide()">{{ 'HOME.VIDEO.Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #videoModal3="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myVideoModal3Label"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body mb-0 p-0">
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Om_ZrgBU0n8" allowfullscreen></iframe>
        </div>
      </div>
      <div class="modal-footer justify-content-center">

        <button mdbBtn type="button" color="primary" rounded="true" outline="true" size="md" (click)="videoModal3.hide()">{{ 'HOME.VIDEO.Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>


    <div mdbModal #videoModal4="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myVideoModal3Label"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body mb-0 p-0">
            <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/GpoY0HwvHcw" allowfullscreen></iframe>
            </div>
          </div>
          <div class="modal-footer justify-content-center">

            <button mdbBtn type="button" color="primary" rounded="true" outline="true" size="md" (click)="videoModal4.hide()">{{ 'HOME.VIDEO.Close' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
</div>
</section>
