import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sixflag',
  templateUrl: './sixflag.component.html',
  styles: [
  ]
})
export class SixflagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
