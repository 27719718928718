<div class="view wow fadeInLeft" data-wow-delay="0.6s">
  <div style="height: 60px;" class="bgdivhotel">
  </div>
  <div class="mask ">
    <div class="pt-2">
      <mdb-card-body cascade="true" class="text-center pt-5">
        <!--Title-->

      </mdb-card-body>
    </div>
  </div>
</div>
