<section class="bgautobill flex-center wow fadeInRight" data-wow-delay="0.6s">

  <div class="container flex-center">
    <div class="col-md-0 mx-auto pl-5 order-2">
      <div class="view mb-0 pb-0">

      </div>
    </div>

    <section class=" px-md-5 mx-md-5 dark-grey-text pt-5">

      <div class="row mb-5">
        <div class="col-md-12 mb-4 order-1">

          <div class="view mb-5">
          </div>
          <div class="col-md-12 mb-4">
            <h1 class="text-center font-weight-bold mb-4 pb-2 white-text pb-5 wow fadeInLeft" data-wow-delay="1.6s">
              <div [innerHTML]="'B52.Billiard.Header.Title'  | translate"></div>
            </h1>
          </div>
        </div>
      </div>

    </section>
  </div>


</section>











