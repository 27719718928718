import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modernization',
  templateUrl: './modernization.component.html',
  styles: [
  ],
})
export class ModernizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
