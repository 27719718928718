<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item
        [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item
        [routerLink]="'/bunker-index'">{{'Bunker.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'Bunker.Scales.Title' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row pt-4">

        <!-- Grid column -->
        <div class="col-lg-12 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'Bunker.Scales.Title' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'Bunker.Scales.SubTitle'  | translate"></div>
          </h5>


        </div>
        <!-- Grid column -->


      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>


  <section class="container">

    <div class="row mb-5 d-flex align-items-center justify-content-end">
      <div class="col-md-4 col-lg-4">
        <div class="view overlay rounded">
          <img src="assets/industrial/bunker/cv.png" class="img-fluid"
               alt="Sample project image"/>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="">
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'Bunker.Scales.Image'  | translate"></div>
          </h5>
        </div>
      </div>
    </div>


    <section class="">
      <div class="" style="font-family: 'Times New Roman'">
        <h3 class="blue-text font-weight-bold">
          {{'Bunker.Scales.FuncTitle' | translate}}
        </h3>
        <div class="card-body">
          <ul class="mb-4">
            <h5>
              <div [innerHTML]="'Bunker.Scales.FuncText'  | translate"></div>
            </h5>
          </ul>
        </div>
      </div>

    </section>

    <div class="container py-2 my-2">
      <section class="p-md-3 mx-md-5">
        <div class="row mb-5 d-flex align-items-center">
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'Bunker.Scales.IconTitleOne' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <h6>
                    <div [innerHTML]="'Bunker.Scales.IconTextOne'  | translate"></div>
                  </h6>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/bunker/cv1.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="row mb-5 d-flex align-items-center justify-content-end">
          <div class="col-md-4 col-lg-4">
            <div class="view overlay rounded">
              <img src="assets/industrial/bunker/cv2.jpg" class="img-fluid"
                   alt="Sample project image"/>
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6">
            <div class="">
              <div class="card-header white">
                <h4 class="font-weight-bold mb-0">{{'Bunker.Scales.IconTitleTwo' | translate}}</h4>
              </div>
              <div class="card-body">
                <ul class="mb-4">
                  <h6>
                    <div [innerHTML]="'Bunker.Scales.IconTextTwo'  | translate"></div>
                  </h6>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <div class="row pt-0 pb-5 flex-center">


      <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <div class="avatar white d-flex justify-content-center align-items-center">

          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          <img style="max-height: 300px"
               lightbox backgroundColor="white" src="assets/cert/bxn.jpg"
               class="img-fluid z-depth-1"
          />
        </div>
        <div class="text-center mt-4">
          <p class="font-weight-bold mb-lg-0">
            {{'Certificates.BXN' | translate}}
          </p>
        </div>
      </div>


      <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <div class="avatar white d-flex justify-content-center align-items-center">

          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          <img style="max-height: 300px"
               lightbox backgroundColor="white" src="assets/cert/bsn.jpg"
               class="img-fluid z-depth-1"
          />
        </div>
        <div class="text-center mt-4">
          <p class="font-weight-bold mb-lg-0">
            {{'Certificates.BSN' | translate}}
          </p>
        </div>
      </div>


    </div>


    <div class="py-3 my-3">
      <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
      <div class="">
        <table mdbTable bordered="true">
          <thead>
          <tr>
            <th scope="col">{{'BigTable.Header.Head1' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head2' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head3' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head4' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head5' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head6' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head7' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head8' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head9' | translate}}</th>
            <th scope="col">{{'BigTable.Header.Head10' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D1.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D1.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D1.Three' | translate}}</td>
            <td>{{'BigTable.Data.D1.Four' | translate}}</td>
            <td>{{'BigTable.Data.D1.Five' | translate}}</td>
            <td>{{'BigTable.Data.D1.Six' | translate}}</td>
            <td>{{'BigTable.Data.D1.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D1.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D1.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D1.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D2.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D2.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D2.Three' | translate}}</td>
            <td>{{'BigTable.Data.D2.Four' | translate}}</td>
            <td>{{'BigTable.Data.D2.Five' | translate}}</td>
            <td>{{'BigTable.Data.D2.Six' | translate}}</td>
            <td>{{'BigTable.Data.D2.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D2.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D2.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D2.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D3.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D3.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D3.Three' | translate}}</td>
            <td>{{'BigTable.Data.D3.Four' | translate}}</td>
            <td>{{'BigTable.Data.D3.Five' | translate}}</td>
            <td>{{'BigTable.Data.D3.Six' | translate}}</td>
            <td>{{'BigTable.Data.D3.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D3.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D3.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D3.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D4.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D4.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D4.Three' | translate}}</td>
            <td>{{'BigTable.Data.D4.Four' | translate}}</td>
            <td>{{'BigTable.Data.D4.Five' | translate}}</td>
            <td>{{'BigTable.Data.D4.Six' | translate}}</td>
            <td>{{'BigTable.Data.D4.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D4.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D4.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D4.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D5.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D5.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D5.Three' | translate}}</td>
            <td>{{'BigTable.Data.D5.Four' | translate}}</td>
            <td>{{'BigTable.Data.D5.Five' | translate}}</td>
            <td>{{'BigTable.Data.D5.Six' | translate}}</td>
            <td>{{'BigTable.Data.D5.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D5.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D5.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D5.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D6.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D6.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D6.Three' | translate}}</td>
            <td>{{'BigTable.Data.D6.Four' | translate}}</td>
            <td>{{'BigTable.Data.D6.Five' | translate}}</td>
            <td>{{'BigTable.Data.D6.Six' | translate}}</td>
            <td>{{'BigTable.Data.D6.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D6.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D6.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D6.Ten' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td>{{'BigTable.Data.D7.One' | translate}}</td>
            <td>
              <div [innerHTML]="'BigTable.Data.D7.Two'  | translate"></div>
            </td>
            <td>{{'BigTable.Data.D7.Three' | translate}}</td>
            <td>{{'BigTable.Data.D7.Four' | translate}}</td>
            <td>{{'BigTable.Data.D7.Five' | translate}}</td>
            <td>{{'BigTable.Data.D7.Six' | translate}}</td>
            <td>{{'BigTable.Data.D7.Seven' | translate}}</td>
            <td>{{'BigTable.Data.D7.Eight' | translate}}</td>
            <td>{{'BigTable.Data.D7.Nine' | translate}}</td>
            <td>{{'BigTable.Data.D7.Ten' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </section>


</div>
