<div class="gotop">
  <app-headerpos></app-headerpos>
</div>
<app-fullpos></app-fullpos>
<app-threepos></app-threepos>
<app-fourpos></app-fourpos>
<app-fivepos></app-fivepos>
<app-sixpos></app-sixpos>
<app-sevenpos></app-sevenpos>
<app-eightpos></app-eightpos>
<app-questions></app-questions>
<app-morepos></app-morepos>
<app-lastshoppos></app-lastshoppos>
