import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-livestock',
  templateUrl: './livestock.component.html',
  styles: [
  ],
})
export class LivestockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
