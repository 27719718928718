<div class="view">
  <div style="height: 100%;" class="bgquestprog">
    <div class="pt-1 wow bounceIn" data-wow-delay="0.6s">
      <mdb-card-body cascade="true" class="text-center pt-5 pb-3">
        <!--Title-->
        <mdb-card-title>
          <h3>
            <strong class="white-text pt-3">Есть вопросы ?</strong>
          </h3>
        </mdb-card-title>

        <mdb-card-text class="white-text pt-3">
          <h5>Оставьте заявку, чтобы получить бесплатную консультацию для своего бизнеса.</h5>
        </mdb-card-text>


        <!-- Default inline form -->
        <form class="form-inline flex-center pt-3 pb-5">
          <!-- Default input -->
          <label class="sr-only" for="inlineFormInputName2">Name</label>
          <input mdbInput type="text" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ваше имя">
          <!-- Default input -->
          <label class="sr-only" for="inlineFormInputGroupUsername2">Username</label>
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text">@</div>
            </div>
            <input mdbInput type="text" class="form-control py-0" id="inlineFormInputGroupUsername2" placeholder="Ваш Email">
          </div>
          <label class="sr-only" for="inlineFormInputName2">Name</label>
          <input mdbInput type="text" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="+(__) __ _ __">

          <button type="submit" mdbBtn color="primary" class="mt-0">Получить консультацию</button>
        </form>
        <!-- Default inline form -->


      </mdb-card-body>
    </div>
  </div>

</div>
