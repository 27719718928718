<div class="container mt-5">

  <!--Section: Content-->
  <section>

    <style>
      .news-tile .card-title {
        position: absolute;
        bottom: 15px;
        left: 40px;
      }

      .rgba-stylish-strong {
        background-color: rgba(62, 69, 81, .5);
      }
    </style>

    <!--Grid row-->
    <div class="row">

      <!--Grid column-->
      <div class="col-lg-4 col-md-12 mb-4  wow fadeInLeft" data-wow-delay="0.3s">

        <!--News tile -->
        <div class="news-tile view zoom z-depth-1 rounded mb-4">

          <a [routerLink]="'/auto-index'" class="text-white">
            <img style="width: 700px; max-height: 305px" src="assets/industrial/13.jpg"
                 class="img-fluid rounded-bottom" alt="sample image">
            <div class="mask rgba-stylish-light">
              <div class="text-white text-center py-lg-5 py-0 my-0">
                <div>
                  <h4 class="card-title font-weight-bold pt-2">
                    <strong>{{'Shop.Category.AutoScales' | translate}}</strong>
                  </h4>

                  <p class="mx-5 clearfix d-none d-md-block"></p>
                </div>
              </div>
            </div>
          </a>

        </div>
        <!--News tile -->

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-4 col-md-6 mb-4  wow zoomIn" data-wow-delay="0.3s">

        <!--News tile -->
        <div class="news-tile view zoom z-depth-1 rounded mb-4">

          <a [routerLink]="'/wagon-index'" class="text-white">
            <img style="width: 700px; max-height: 305px" src="assets/industrial/vagon/1.jpg"
                 class="img-fluid rounded-bottom" alt="sample image">
            <div class="mask rgba-stylish-light">
              <div class="text-white text-center py-lg-5 py-0 my-0">
                <div>
                  <h4 class="card-title font-weight-bold pt-2">
                    <strong>{{'Shop.Category.WagonScales' | translate}}</strong>
                  </h4>

                  <p class="mx-5 clearfix d-none d-md-block"></p>
                </div>
              </div>
            </div>
          </a>

        </div>
        <!--News tile -->

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-4 col-md-6 mb-4  wow fadeInRight" data-wow-delay="0.3s">

        <!--News tile -->
        <div class="news-tile view zoom z-depth-1 rounded mb-4">

          <a [routerLink]="'/bunker-index'" class="text-white">
            <img style="width: 700px; max-height: 305px" src="assets/industrial/bunker/2.jpg"
                 class="img-fluid rounded-bottom" alt="sample image">
            <div class="mask rgba-stylish-light">
              <div class="text-white text-center py-lg-5 py-0 my-0">
                <div>
                  <h4 class="card-title font-weight-bold pt-2">
                    <strong>{{'Shop.Category.HopperScales' | translate}}</strong>
                  </h4>

                  <p class="mx-5 clearfix d-none d-md-block"></p>
                </div>
              </div>
            </div>
          </a>

        </div>
        <!--News tile -->

      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->

  </section>
  <!--Section: Content-->


</div>
