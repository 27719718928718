<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'PosMarket.AlexMarketFront.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'PosMarket.AlexMarketFront.TitleBottom' | translate}}
  </p>
  <div class="row">

    <div class="col order-1 wow fadeInRight" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-download fa-2x green-text pr-2"></i>
          {{'PosMarket.AlexMarketFront.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.AlexMarketFront.SpecTextOne' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-sort-amount-down fa-2x green-text pr-2"></i>
          {{'PosMarket.AlexMarketFront.SpecTitleTwo' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.AlexMarketFront.SpecTextTwo' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-th-list fa-2x green-text pr-2"></i>
        {{'PosMarket.AlexMarketFront.SpecTitleThree' | translate}}
      </h6>
      <p class="">
        {{'PosMarket.AlexMarketFront.SpecTextThree' | translate}}
      </p>

    </div>


    <div class="col-lg-6 order-3 wow fadeInLeft" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/alexmarket/Logo AlexMarket PrintScreen.png" lightbox backgroundColor="white"
           alt="Sample image">

    </div>


    <div class="col order-2 wow fadeInRight" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-credit-card fa-2x green-text pr-2"></i>
          {{'PosMarket.AlexMarketFront.SpecTitleFour' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.AlexMarketFront.SpecTextFour' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-desktop fa-2x green-text pr-2"></i>
          {{'PosMarket.AlexMarketFront.SpecTitleFive' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.AlexMarketFront.SpecTextFive' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-print fa-2x green-text pr-2"></i>
        {{'PosMarket.AlexMarketFront.SpecTitleSix' | translate}}
      </h6>
      <p class="">
        {{'PosMarket.AlexMarketFront.SpecTextSix' | translate}}
      </p>


    </div>

  </div>


</div>
























