<div class="container pl-0">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <ol class="step pl-0 pt-5">

        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-1.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleOne' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextOne' | translate}}
            </p>
          </div>
        </li>

        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-2.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleTwo' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextTwo' | translate}}
            </p>
          </div>
        </li>


        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-3.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleThree' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextThree' | translate}}
            </p>
          </div>
        </li>


      </ol>
    </div>

    <div class="col-lg-6 mb-4">
      <ol class="step pl-0 pt-5">


        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-4.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleFour' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextFour' | translate}}
            </p>
          </div>
        </li>


        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-5.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleFive' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextFive' | translate}}
            </p>
          </div>
        </li>


        <li class="step-element pb-4 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number align-items-center mr-4">
            <img class="img-fluid mx-auto" src="assets/auto/hotel/2-icon-Hotel-4.jpg">
          </div>
          <div class="step-excerpt">
            <h6 class="font-weight-bold mb-3">
              {{'B52.Hotel.First.ImageTitleSix' | translate}}
            </h6>
            <p class="mb-0">
              {{'B52.Hotel.First.ImageTextSix' | translate}}
            </p>
          </div>
        </li>


      </ol>
    </div>

  </div>
</div>


<style>
  .fa-play:before {
    margin-left: .3rem;
  }

  /* Steps */
  .step {
    list-style: none;
    margin: 0;
  }

  .step-element {
    display: flex;
    padding: 1rem 0;
  }

  .step-number {
    position: relative;
    width: 14rem;
    flex-shrink: 0;
    text-align: center;
  }

  .step-number .number {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }

  .step-number .number {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    border-radius: 10rem;
  }

  .step-number::before {
    /*content: '';*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 48px;*/
    /*bottom: -2rem;*/
    /*margin-left: -1px;*/
    /*border-left: 2px dashed #fff;*/
  }

  .step .step-element:last-child .step-number::before {
    bottom: 1rem;
  }
</style>



























