<!-- Section: Testimonials v.3 -->
<section class="team-section text-center my-5">
<div class="container">


  <!--Grid row-->
  <section class="text-center ">
    <div class="container text-center">
      <!--Grid row-->
      <div class="row pt-5 flex-center">

        <!--Grid column-->
        <div class="col-lg-2 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>

          <h4 class="font-weight-bold mt-4 red-text">
            {{'Programs.InfoList.IconTitleOne' | translate}}
          </h4>
          <p class="font-weight-bold blue-text">
            {{'Programs.InfoList.IconTextOne' | translate}}
          </p>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 mr-4 ml-1 wow wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
          <h4 class="font-weight-bold mt-4 red-text">
            {{'Programs.InfoList.IconTitleTwo' | translate}}
          </h4>
          <p class="font-weight-bold blue-text">
            {{'Programs.InfoList.IconTextTwo' | translate}}
          </p>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-2 mr-4 pt-3 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
          <h4 class="font-weight-bold mt-4 red-text">
            {{'Programs.InfoList.IconTitleThree' | translate}}
          </h4>
          <p class="font-weight-bold blue-text">
            {{'Programs.InfoList.IconTextThree' | translate}}
          </p>
        </div>
        <!--Grid column-->


        <!--Grid column-->
        <div class="col-lg-2 mr-4 pt-3 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
          <h4 class="font-weight-bold mt-4 red-text">
            {{'Programs.InfoList.IconTitleFour' | translate}}
          </h4>
          <p class="font-weight-bold blue-text">
            {{'Programs.InfoList.IconTextFour' | translate}}
          </p>
        </div>
        <!--Grid column-->

      </div>

    </div>

  </section>
  <!--Grid row-->
</div>


  <section class="container pt-4 wow zoomIn" data-wow-delay="0.3s">
    <div style="font-family: 'Times New Roman'">
      <h5>
        <div class="-align-left" [innerHTML]="'Programs.InfoList.Text'  | translate"></div>
      </h5>
    </div>

  </section>

<div class="pt-5 flex-center wow bounceIn" data-wow-delay="0.6s">
<mdb-card  style="width: 1300px; height: 231px;">
  <!-- Card content -->
  <mdb-card-body cascade="true" class="pt-5 text-left bginfolist">

    <!--Title-->
    <mdb-card-title class="">
      <h4>
        <strong>{{'Programs.Tech.Title' | translate}}</strong>
      </h4>
    </mdb-card-title>


    <mdb-card-text class="black-text -align-left">
      <div [innerHTML]="'Programs.Tech.SubTitle'  | translate"></div>
    </mdb-card-text>

    <mdb-card-text class="black-text -align-left">
      <div [innerHTML]="'Programs.Tech.BottomTitle'  | translate"></div>
    </mdb-card-text>

  </mdb-card-body>

</mdb-card>

</div>
</section>
<!-- Section: Testimonials v.3 -->
