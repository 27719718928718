<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'PosMarket.ScalesRSPrint.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'PosMarket.ScalesRSPrint.TitleBottom' | translate}}</p>
  <div class="row flex-center">

    <div class="col-lg-6 order-1 wow fadeInLeft" data-wow-delay="1.2s">
      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-tv fa-2x green-text pr-2"></i>
          {{'PosMarket.ScalesRSPrint.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.ScalesRSPrint.SpecTextOne' | translate}}
        </p>
      </div>

      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-keyboard fa-2x green-text pr-2"></i>
          {{'PosMarket.ScalesRSPrint.SpecTitleTwo' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.ScalesRSPrint.SpecTextTwo' | translate}}
        </p>
      </div>

      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
          {{'PosMarket.ScalesRSPrint.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.ScalesRSPrint.SpecTextThree' | translate}}
        </p>
      </div>


      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-bars fa-2x green-text pr-2"></i>
        {{'PosMarket.ScalesRSPrint.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosMarket.ScalesRSPrint.SpecTextFour' | translate}}
      </p>


    </div>


    <div class="col-lg-5 order-21 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/alexmarket/AlexPrint1.png" lightbox backgroundColor="white" alt="Sample image">


    </div>


  </div>

  <div class="flex-center">
    <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s"
            type="button" (click)="test.toggle()" mdbWavesEffect>
      <mdb-icon fas icon="arrow-down"></mdb-icon>
      {{'BUTTONS.TehSpec' | translate}}
      <mdb-icon fas icon="arrow-down"></mdb-icon>
    </button>
  </div>

  <div mdbCollapse [isCollapsed]="true" #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
        <p style="text-align: left;"><strong>Наибольший предел взвешивания, кг:</strong> 6/15<br>
          <strong>Наименьший предел взвешивания, г:</strong> 20<br>
          <strong>Дискретность отсчета (d), г:</strong> 2/5<br>
          <strong>Объем памяти, товаров:</strong> 5500<br>
          <strong>Клавиатура: клавиши прямого вызова товара, шт:</strong> 112<br>
          <strong>Размер этикеток, мм:</strong> 58×40<br>
          <strong>Класс точности весов:</strong> Средний (III)</p>
      </div>
    </div>
  </div>


</div>


<!--<div class="container pt-5 pb-5">-->
<!--  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">Весы торговые с чекопечатью</h3>-->
<!--  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">ВЕСЫ_К торговые ALEX PRINT-1 (с чекопечатью)</p>-->
<!--  <div class="row flex-center">-->


<!--    <div class="col-md-3 order-2 wow fadeInRight pt-5" data-wow-delay="0.6s">-->
<!--      <div class="pt-5">-->
<!--        <h6 class="font-weight-bold mb-3">-->
<!--&lt;!&ndash;          <i class="fas fa-sitemap fa-2x green-text pr-2"></i>&ndash;&gt;-->

<!--        </h6>-->
<!--        <p class="">-->
<!--          Емкость хранилища до 10000 товаров и поддержка 224 горячих клавиш.-->
<!--          С помощью программного обеспечения ПК можно настроить последовательность клавиш и распечатать ее.-->
<!--          Быстрый поиск продуктов по номеру продукта.-->
<!--          Поддержка обновления PLU в режиме онлайн и редактирования.-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->


<!--    <div class="col-lg-6 order-1 wow fadeInLeft" data-wow-delay="0.6s">-->
<!--      <img class="img-fluid" src="assets/complect/alimentara/ALEX-PRINT-1.jpg" alt="Sample image">-->

<!--    </div>-->


<!--  </div>-->

<!--  <div class="flex-center">-->
<!--    <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>-->
<!--      <mdb-icon fas icon="arrow-down"></mdb-icon>-->
<!--      Тех.Характеристики-->
<!--      <mdb-icon fas icon="arrow-down"></mdb-icon>-->
<!--    </button>-->
<!--  </div>-->

<!--  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">-->
<!--    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">-->

<!--      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">-->
<!--          <p style="text-align: left;"><strong>Наибольший предел взвешивания, кг:</strong> 6/15<br>-->
<!--            <strong>Наименьший предел взвешивания, г:</strong> 20<br>-->
<!--            <strong>Дискретность отсчета (d), г:</strong> 2/5<br>-->
<!--            <strong>Объем памяти, товаров:</strong> 5500<br>-->
<!--            <strong>Клавиатура: клавиши прямого вызова товара, шт:</strong> 112<br>-->
<!--            <strong>Размер этикеток, мм:</strong> 58×40<br>-->
<!--            <strong>Класс точности весов:</strong> Средний (III)</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->


<!--</div>-->























