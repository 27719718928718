<!--Section: Content-->
<section class="container pt-2">

  <h1 class="text-center font-weight-bold mb-4 pb-2 wow zoomIn" data-wow-delay="0.3s">
    {{'SOLUTION.Index.Title' | translate}}
  </h1>


</section>
<!--Section: Content-->

<div class="container flex-center pt-4">

  <div class="row">

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold mb-4" style="width: 700px;height: 260px" src="assets/solutions/index/image0033-min.jpg" alt="Sample image">
        <a [routerLink]="'/agricultural-industry'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center">
          <h5 class="font-weight-bold">
            {{'SOLUTION.Index.SolutionNameOne' | translate}}
          </h5>
        </div>

      </div>


    </div>

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow zoomIn" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold mb-4" style="width: 700px; max-height: 330px" src="assets/solutions/index/image001-min.jpg" alt="Sample image">
        <a [routerLink]="'/dispensing-mixing-line-of-a-glass-factory'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'SOLUTION.Index.SolutionNameTwo' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold" style="width: 800px; height: 285px" src="assets/solutions/index/image002-min.jpg" alt="Sample image">
        <a [routerLink]="'/livestock-industry'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'SOLUTION.Index.SolutionNameThree' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="w-100"></div>

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/solutions/index/image004-min.png" alt="Sample image">
        <a [routerLink]="'/modernization-of-concrete-plants'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>


        <div class="card-body flex-center font-weight-bold mb-4">
          <h5 class="font-weight-bold">
            {{'SOLUTION.Index.SolutionNameFour' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="col">
        <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
          <img class="img-fluid font-weight-bold " style="width: 700px; height: 315px" src="/assets/automaticSystem/logo.jpg" alt="Sample image">
          <a [routerLink]="'/automatic-weight-and-dimension-control-system'">
            <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          </a>

          <div class="card-body flex-center font-weight-bold mb-4">
            <h5 class="font-weight-bold">
              {{'SOLUTION.Index.SolutionNameNew' | translate}}
            </h5>
          </div>

        </div>
      </div>


    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; height: 315px" src="assets/solutions/index/image005-min.jpg" alt="Sample image">
        <a [routerLink]="'/weighing-of-road-and-rail-transport'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>


        <div class="card-body flex-center font-weight-bold mb-4">
          <h5 class="font-weight-bold">
            {{'SOLUTION.Index.SolutionNameFive' | translate}}
          </h5>
        </div>

      </div>
    </div>

  </div>

</div>


<app-support-indus></app-support-indus>


