import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divtwoab',
  templateUrl: './divtwoab.component.html',
  styles: [
  ]
})
export class DivtwoabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
