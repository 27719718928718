<div class="container ">
  <h3 class="font-weight-bold text-center dark-grey-text pb-2 pt-4 wow zoomIn" data-wow-delay="0.6s">{{'AlexMarket.Advantage.Title' | translate}}</h3>
  <hr class="w-header my-4 pb-5">
</div>
<section class="bgadventage">
<div class="container ">

  <!-- Section -->


    <div class="row align-items-center wow fadeInRight" data-wow-delay="0.6s">

      <div class="col-lg-5 mb-4">
        <div class="view z-depth-1-half rounded">
          <div class="mask flex-center rgba-black-light">
            <a id="play" class="btn-floating btn-primary btn-lg" data-toggle="modal" data-target="#modal1"><i class="fas fa-play"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-7 mb-4">

        <ol class="step pl-0 pt-5">
          <li class="step-element pb-0">
            <div class="step-number">

              <span class="fa-stack fa-2x blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </div>
            <div class="step-excerpt">
              <h5 class="font-weight-bold black-text mb-5 pt-3">{{'AlexMarket.Advantage.IconOne' | translate}}</h5>
            </div>
          </li>
          <li class="step-element pb-0">
            <div class="step-number">
              <span class="fa-stack fa-2x blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </div>
            <div class="step-excerpt">
              <h5 class="font-weight-bold black-text mb-5 pt-3">{{'AlexMarket.Advantage.IconTwo' | translate}}</h5>
            </div>
          </li>
          <li class="step-element pb-0 ">
            <div class="step-number ">
              <span class="fa-stack fa-2x blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </div>
            <div class="step-excerpt ">
              <h5 class="font-weight-bold black-text mb-5 pt-3">{{'AlexMarket.Advantage.IconThree' | translate}}</h5>
            </div>
          </li>

          <li class="step-element pb-5">
            <div class="step-number">
              <span class="fa-stack fa-2x blue-text">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </div>
            <div class="step-excerpt ">
              <h5 class="font-weight-bold black-text  mb-5 pt-3">{{'AlexMarket.Advantage.IconFour' | translate}}</h5>
            </div>
          </li>
        </ol>

      </div>

    </div>

  <!-- Section -->

</div>

</section>
<style>
  .fa-play:before {
    margin-left: .3rem;
  }

  /* Steps */
  .step {
    list-style: none;
    margin: 0;
  }

  .step-element {
    display: flex;
    padding: 1rem 0;
  }

  .step-number {
    position: relative;
    width: 7rem;
    flex-shrink: 0;
    text-align: center;
  }

  .step-number .number {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }

  .step-number .number {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    border-radius: 10rem;
  }

  .step-number::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 48px;
    bottom: -2rem;
    margin-left: -1px;
    border-left: 2px dashed #000000;
  }

  .step .step-element:last-child .step-number::before {
    bottom: 1rem;
  }
</style>
