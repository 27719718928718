<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/auto-index'">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'CarWeights.StaticOc.Breadcrumb' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">
            <div [innerHTML]="'CarWeights.StaticOc.Title'  | translate"></div>
          </h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'CarWeights.StaticOc.SubTitle' | translate}}
          </h5>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4 mb-3">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/static/logo.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticOc.Text'  | translate"></div>
      </h5>
    </div>

  </section>



  <div  class="container">




    <div lightbox-group class=" ">
      <div class="row ">
        <div class="col-6 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/static/image003.png" alt="">
        </div>
        <div class="col-6 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/static/image005.jpg" alt="">

        </div>
      </div>
      <div class="row">
        <div class="col-6 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/static/image007.jpg" alt="">
        </div>
        <div class="col-6 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/static/image009.jpg" alt="">
        </div>
      </div>

    </div>
  </div>


  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticOc.SubText'  | translate"></div>
      </h5>
    </div>
  </section>


    <section class="container">

    <div class="flex-center">
      <h3 class="pt-3 mb-3">{{'CarWeights.StaticOc.FuncTitle' | translate}}</h3>
    </div>


    <div class="d-flex justify-content-center">
      <ul class="mb-4 col-7">
        <div [innerHTML]="'CarWeights.StaticOc.FuncText'  | translate"></div>
      </ul>
    </div>

    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-4 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
<!--            <img lightbox backgroundColor="white" src="assets/cert/bsn.jpg" class="img-fluid" alt="">-->
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-8 mb-4 mb-md-0">


          <h5>
            {{'Certificates.BDA' | translate}}
          </h5>

        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
      <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
      <div class="flex-center pb-2">
        <div class="col-lg-7">
          <!--      <img lightbox backgroundColor="white" src="assets/industrial/vagon/tech.png" class="img-fluid" alt="">-->
          <table mdbTable striped="true" bordered="true">
            <thead>
            </thead>
            <tbody>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstOne' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastOne' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstTwo' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastTwo' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstThree' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastThree' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstFour' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastFour' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstFive' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastFive' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstSix' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastSix' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstSeven' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastSeven' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstEight' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastEight' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstNine' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastNine' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.Two.FirstTen' | translate}}</td>
              <td class="">{{'CarWeights.Tables.Two.LastTen' | translate}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


  </section>



</div>
