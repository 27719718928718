<!--Section: Products v.3-->
<div class="container ">
  <h3 class="font-weight-bold text-center dark-grey-text pb-2 pt-4 wow fadeIn"
      data-wow-delay="0.6s">{{'AlexMarket.Solution.Title' | translate}}</h3>
  <hr class="w-header my-4 pb-5">
</div>
<section class="text-center pb-3 my-5">
  <div class="container  ">


    <div class="row flex-center wow zoomIn" data-wow-delay="0.6s">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-4  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/alexmarket/Prod.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">{{'AlexMarket.Solution.IconOne' | translate}}</h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-4  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/alexmarket/Spetmag.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">{{'AlexMarket.Solution.IconTwo' | translate}}</h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-4  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/alexmarket/Seti.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">{{'AlexMarket.Solution.IconThree' | translate}}</h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-4  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/alexmarket/Stroi.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="font-weight-bold pt-2">{{'AlexMarket.Solution.IconFour' | translate}}</h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->
      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-4 col-md-10 mb-5">

        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Om_ZrgBU0n8"
                  allowfullscreen></iframe>
        </div>

      </div>
      <!-- Grid column -->


    </div>
    <!--Grid row-->


  </div>

</section>
<!--Section: Products v.3-->
