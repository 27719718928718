<div class="container pt-5 pb-5">
  <div class="row">

    <div class="col order-1 wow fadeInLeft" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-search-plus fa-2x green-text pr-2"></i>
          {{'PosCompact.AlexMarketBack.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.AlexMarketBack.SpecTextOne' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-money-bill-alt fa-2x green-text pr-2"></i>
          {{'PosCompact.AlexMarketBack.SpecTitleTwo' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.AlexMarketBack.SpecTextTwo' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-lock fa-2x green-text pr-2"></i>
        {{'PosCompact.AlexMarketBack.SpecTitleThree' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.AlexMarketBack.SpecTextThree' | translate}}
      </p>

    </div>


    <div class="col-lg-6 order-3 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/alexmarket/AlexMarket_Admin_Large_right.png" lightbox backgroundColor="white" alt="Sample image">

    </div>



    <div class="col order-2 wow fadeInLeft" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-indent fa-2x green-text pr-2"></i>
          {{'PosCompact.AlexMarketBack.SpecTitleFour' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.AlexMarketBack.SpecTextFour' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-file-alt fa-2x green-text pr-2"></i>
          {{'PosCompact.AlexMarketBack.SpecTitleFive' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.AlexMarketBack.SpecTextFive' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-list-alt fa-2x green-text pr-2"></i>
        {{'PosCompact.AlexMarketBack.SpecTitleSix' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.AlexMarketBack.SpecTextSix' | translate}}
      </p>




    </div>

  </div>




</div>
























