<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/bunker-index'">{{'Bunker.Index.Breadcrumb' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'Bunker.Modern.Title' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-5">{{'Bunker.Modern.Title' | translate}}</h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'Bunker.Modern.SubTitle' | translate}}
          </h5>


        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4 mb-3">

          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/bunker/lll.jpg" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
      {{'Bunker.Modern.Text' | translate}}
    </h5>

    <div class="row mb-5 d-flex align-items-center justify-content-end">
      <div class="col-md-4 col-lg-4">
        <div class="view overlay rounded">
          <img src="assets/industrial/bunker/cv.png" class="img-fluid"
               alt="Sample project image" />
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="">
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            <div [innerHTML]="'Bunker.Modern.Image'  | translate"></div>

          </h5>
        </div>
      </div>
    </div>

  </section>


  <section class="pt-4">
    <h4 style="font-family: 'Times New Roman'"
        class="font-weight-bold flex-center pb-2">{{'CarWeights.AutoModern.Table.Title' | translate}}</h4>
    <div class="container">
      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="font-weight-bold flex-center black-text">
              {{'CarWeights.AutoModern.Table.Old.Title' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mb-3">
          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="font-weight-bold flex-center black-text">
              {{'CarWeights.AutoModern.Table.New.Title' | translate}}
            </h5>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.One' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/clock.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.One' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Two' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb2.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/Temp-30+45.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Two' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Three' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb3.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb4.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Three' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Four' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb5.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb6.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Four' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Five' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb7.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb8.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Five' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Six' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb9.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb10.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Six' | translate}}
            </h5>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.Old.Seven' | translate}}
            </h5>
          </div>
        </div>
        <div class="col-md-2 border">
          <div class="d-flex mt-1 mb-1">
            <div class="">
              <img lightbox class="img-fluid" src="assets/industrial/modern/bb12.png" alt="">
            </div>

            <div class="ml-auto">
              <img lightbox class="img-fluid" src="assets/industrial/modern/Automatic.png" alt="">
            </div>

          </div>
        </div>
        <div class="col-md-5 border">
          <div style="font-family: 'Times New Roman'">
            <h5 class="black-text">
              {{'CarWeights.AutoModern.Table.New.Seven' | translate}}
            </h5>
          </div>

        </div>
      </div>
    </div>


  </section>


  <section class="container">
    <div class="row pt-5 pb-5 flex-center">


      <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <div class="avatar white d-flex justify-content-center align-items-center">

          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          <img style="max-height: 300px"
               lightbox backgroundColor="white" src="assets/cert/bxn.jpg"
               class="img-fluid z-depth-1"
          />
        </div>
        <div class="text-center mt-4">
          <p class="font-weight-bold mb-lg-0">
            {{'Certificates.BXN' | translate}}
          </p>
        </div>
      </div>




      <div class="col-lg-6 col-md-6 mb-lg-0 mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <div class="avatar white d-flex justify-content-center align-items-center">

          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          <img style="max-height: 300px"
               lightbox backgroundColor="white" src="assets/cert/bsn.jpg"
               class="img-fluid z-depth-1"
          />
        </div>
        <div class="text-center mt-4">
          <p class="font-weight-bold mb-lg-0">
            {{'Certificates.BSN' | translate}}
          </p>
        </div>
      </div>



    </div>

  </section>


</div>
