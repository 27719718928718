<div class="bgindustrial">
<div class="container pt-2">
  <mdb-breadcrumb-item>
    <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
    <mdb-breadcrumb-item  [routerLink]="'/auto-index'">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{'CarWeights.StaticAuto.Title' | translate}}</mdb-breadcrumb-item>
  </mdb-breadcrumb-item>

  <!--Section: Content-->
  <section>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-7 mb-3 align-self-center text-center text-lg-left">

        <h1 class="h2 mb-5">{{'CarWeights.StaticAuto.Title' | translate}}</h1>
        <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
          {{'CarWeights.StaticAuto.SubTitle' | translate}}
        </h5>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-5 mb-4">

        <!-- Featured image -->
        <div class="view overlay">
          <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/Main.jpg" alt="Sample image">

        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->


</div>
</div>
