import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-indus',
  templateUrl: './text-indus.component.html',
  styleUrls: ['./text-indus.component.scss']
})
export class TextIndusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
