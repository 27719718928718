<!--Radio buttons-->
<section class="container">
  <div class="flex-center">

    <div class="btn-group pb-4">
      <label mdbBtn color="{{this.colorNew}}" (click)="changeColor()" [(ngModel)]="radioModel" mdbRadio="New"
        mdbWavesEffect>
        {{'CarWeights.StaticAuto.BetonNew.Title' | translate}}
      </label>
      <label mdbBtn color="{{this.color}}" (click)="changeColor()" [(ngModel)]="radioModel" mdbRadio="Left"
             mdbWavesEffect>
        {{'CarWeights.StaticAuto.Beton.Title' | translate}}
      </label>
      <label mdbBtn color="{{this.colorTwo}}" (click)="changeColor()" [(ngModel)]="radioModel" mdbRadio="Right"
             mdbWavesEffect>
        {{'CarWeights.StaticAuto.Metal.Title' | translate}}
      </label>
    </div>
  </div>


  <div *ngIf="radioModel == 'Left'" class="container">
    <div class="pb-3" style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticAuto.Beton.Text'  | translate"></div>
      </h5>
    </div>

    <div class="pb-3">
      <h1 class="h2 text-center">{{'CarWeights.Icons.ModernTitle' | translate}}</h1>
    </div>
    <div class="flex-center">
      <div class="row">
        <div class="col">
          <img src="assets/industrial/icons/AddDisplay.png"
               ngbTooltip="{{'CarWeights.Icons.IconAddDisplay' | translate}}" container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Border.png" ngbTooltip="{{'CarWeights.Icons.IconBorder' | translate}}"
               container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DisplayShockProtection.png"
               ngbTooltip="{{'CarWeights.Icons.IconDisplayProtect' | translate}}" container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DigitalSensors.png"
               ngbTooltip="{{'CarWeights.Icons.IconDigital' | translate}}" container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/lights.png" ngbTooltip="{{'CarWeights.Icons.IconLights' | translate}}"
               container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/barrier.png" ngbTooltip="{{'CarWeights.Icons.IconBarrier' | translate}}"
               container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Photo.png" ngbTooltip="{{'CarWeights.Icons.IconPhoto' | translate}}"
               container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RFIDidentification.png"
               ngbTooltip="{{'CarWeights.Icons.IconRFID' | translate}}" container="body" class="pr-5 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Automatic.png" ngbTooltip="{{'CarWeights.Icons.IconAutomatic' | translate}}"
               container="body" class="pr-5 pb-2">
        </div>

      </div>
    </div>


    <div lightbox-group class=" ">
      <div class="row ">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery1.png" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery2.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery3.jpg" alt="">

        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery4.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery5.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery6.jpg" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery7.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery8.png" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery9.png" alt="">

        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery10.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery11.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery12.jpg" alt="">

        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery13.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery14.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery15.jpg" alt="">

        </div>
      </div>
      <div class="row flex-center">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery16.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gallery17.jpg" alt="">
        </div>
      </div>
    </div>


    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-6 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsaa.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSA' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-7">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFourteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFourteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>





  <div *ngIf="radioModel == 'New'" class="container">

    <div class="pt-0 pb-3" style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticAuto.BetonNew.Text'  | translate"></div>
      </h5>
    </div>

    <div class="col pb-3">
      <div class="view overlay z-depth-1-half">
        <ng-container *ngIf="this.main.lang == 'ru'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/new/RU-preference-site-min.jpg" class="img-fluid" alt="">
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'ro'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/new/RO-preference-site-min.jpg" class="img-fluid" alt="">
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'en'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/new/EN-preference-site-min.jpg" class="img-fluid" alt="">
        </ng-container>
      </div>
    </div>

    <div class="pt-0 pb-3" style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticAuto.BetonNew.TextTwo'  | translate"></div>
      </h5>
    </div>

    <h1 class="h2 text-center">{{'CarWeights.Icons.ModernTitle' | translate}}</h1>

    <div class="flex-center pt-2 pb-2">
      <div class="row">
        <div class="col">
          <img src="assets/industrial/icons/AddDisplay.png" ngbTooltip="{{'CarWeights.Icons.IconAddDisplay' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Sheld.png" ngbTooltip="{{'CarWeights.Icons.IconShield' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/ramp.png" ngbTooltip="{{'CarWeights.Icons.IconRamp' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/frame.png" ngbTooltip="{{'CarWeights.Icons.IconFrame' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DisplayShockProtection.png"
               ngbTooltip="{{'CarWeights.Icons.IconDisplayProtect' | translate}}" container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DigitalSensors.png" ngbTooltip="{{'CarWeights.Icons.IconDigital' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/lights.png" ngbTooltip="{{'CarWeights.Icons.IconLights' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/barrier.png" ngbTooltip="{{'CarWeights.Icons.IconBarrier' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Photo.png"
               ngbTooltip="{{'CarWeights.Icons.IconPhoto' | translate}}" container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RFIDidentification.png" ngbTooltip="{{'CarWeights.Icons.IconRFID' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Automatic.png"
               ngbTooltip="{{'CarWeights.Icons.IconAutomatic' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
      </div>
    </div>

    <div lightbox-group class=" ">
      <div class="row ">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/1 2-min.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/1 3-min.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/1 4-min.jpg" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/1 5-min.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/2 2-min.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/surface-min.jpg" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/2-min.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/3 2-min.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/new/2 6-min.jpg" alt="">
        </div>
      </div>

    </div>


    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-6 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsaa.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSA' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-7">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.New.FirstOne' | translate}}</td>
              <td class="">{{'CarWeights.Tables.New.LastOne' | translate}}</td>
            </tr>
            <tr mdbTableCol>
              <td class="">{{'CarWeights.Tables.New.FirstTwo' | translate}}</td>
              <td class="">{{'CarWeights.Tables.New.LastTwo' | translate}}</td>
            </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstAAA' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastAAA' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstAAAA' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastAAAA' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.New.FirstFourteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.New.LastFourteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>





  <div *ngIf="radioModel == 'Right'" class="container">

    <div class="pt-0 pb-3" style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.StaticAuto.Metal.Text'  | translate"></div>
      </h5>
    </div>

    <div class="col pb-3">
      <div class="view overlay z-depth-1-half">
        <ng-container *ngIf="this.main.lang == 'ru'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/Автоматизированное_производство_автомобильных_весов-min.jpg" class="img-fluid" alt="">
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'ro'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/Productia_atomatizata_cantarelor_auto-min.jpg" class="img-fluid" alt="">
        </ng-container>
        <ng-container *ngIf="this.main.lang == 'en'">
          <img lightbox backgroundColor="white" src="assets/industrial/auto/Automated_production_truck_scales-min.jpg" class="img-fluid" alt="">
        </ng-container>
      </div>
    </div>

    <h1 class="h2 text-center">{{'CarWeights.Icons.ModernTitle' | translate}}</h1>

    <div class="flex-center pt-2 pb-2">
      <div class="row">
        <div class="col">
          <img src="assets/industrial/icons/AddDisplay.png" ngbTooltip="{{'CarWeights.Icons.IconAddDisplay' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Border.png" ngbTooltip="{{'CarWeights.Icons.IconBorder' | translate}}" container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Sheld.png" ngbTooltip="{{'CarWeights.Icons.IconShield' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/ramp.png" ngbTooltip="{{'CarWeights.Icons.IconRamp' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/frame.png" ngbTooltip="{{'CarWeights.Icons.IconFrame' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DisplayShockProtection.png"
               ngbTooltip="{{'CarWeights.Icons.IconDisplayProtect' | translate}}" container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/DigitalSensors.png" ngbTooltip="{{'CarWeights.Icons.IconDigital' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/lights.png" ngbTooltip="{{'CarWeights.Icons.IconLights' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/barrier.png" ngbTooltip="{{'CarWeights.Icons.IconBarrier' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Photo.png"
               ngbTooltip="{{'CarWeights.Icons.IconPhoto' | translate}}" container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/RFIDidentification.png" ngbTooltip="{{'CarWeights.Icons.IconRFID' | translate}}"
               container="body" class="pr-2 pb-2">
        </div>
        <div class="col">
          <img src="assets/industrial/icons/Automatic.png"
               ngbTooltip="{{'CarWeights.Icons.IconAutomatic' | translate}}" container="body"
               class="pr-2 pb-2">
        </div>
      </div>
    </div>

    <div lightbox-group class=" ">
      <div class="row ">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery1.png" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery2.png" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery3.png" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery4.png" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery5.jpg" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery6.jpg" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery7.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery8.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/gallery/gollery9.jpg" alt="">

        </div>
      </div>
      <div class="row">
        <div class="col-4 py-1 my-1">


        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/industrial/auto/Автомобиль_с_весами-min.jpg" alt="">

        </div>
        <div class="col-4 py-1 my-1">


        </div>
      </div>
    </div>


    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-6 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
            <img lightbox backgroundColor="white" src="assets/cert/bsaa.jpg" class="img-fluid" alt="">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4 mb-md-0">


          <h6 class="">
            {{'Certificates.BSA' | translate}}
          </h6>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-7">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastEleven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstTwelve' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastTwelve' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstThirteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastThirteen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.First.FirstFourteen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.First.LastFourteen' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>

</section>
<!--Radio buttons-->
