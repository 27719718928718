<div class="view">
<div style="height: 193px;" class="bgwhy">
</div>
  <div class="mask">
  <div class="pt-3">
    <mdb-card-body cascade="true" class="text-center pt-5">
      <!--Title-->
      <mdb-card-title>
        <h3>
          <strong class="white-text  wow fadeInDown" data-wow-delay="0.6s" style="font-family: 'Times New Roman'">{{ 'HOME.WHY.Title' | translate }}</strong>
        </h3>
      </mdb-card-title>

      <mdb-card-text class="white-text  wow fadeInDown" style="font-family: 'Times New Roman'" data-wow-delay="0.6s">
        <h5><div [innerHTML]="'HOME.WHY.Text' | translate"></div></h5>
      </mdb-card-text>
    </mdb-card-body>
  </div>
  </div>
</div>

