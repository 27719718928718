import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foureali',
  templateUrl: './foureali.component.html',
  styles: [
  ]
})
export class FourealiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
