<!--Section: Products v.3-->
<div class="container ">
  <h3 class="font-weight-bold text-center dark-grey-text pb-2 pt-4 wow fadeIn" data-wow-delay="0.6s">
    <div [innerHTML]="'Flagman.SecondFlag.Title'  | translate"></div>
  </h3>
  <hr class="w-header my-4 pb-5">
</div>
<section class="text-center pb-3 my-5">
  <div class="container">


    <div class="row flex-center wow zoomIn" data-wow-delay="0.6s">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/sklad.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.SecondFlag.IconOne' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/kontrol.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.SecondFlag.IconTwo' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/1-zamok-1.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.SecondFlag.IconThree' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-3 col-md-12">

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/loialnosti.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="pt-2">
                  {{'Flagman.SecondFlag.IconFour' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->

        <!--News tile -->
        <div class="news-tile view   mb-4">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/udaleno.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class=" pt-2">
                  {{'Flagman.SecondFlag.IconFive' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


        <!--News tile -->
        <div class="news-tile view   mb-4 ">

          <div class="col-lg-12 col-sm-6 mb-5">
            <div class="row d-flex align-items-center">
              <div
                class="col-5  w-100 white d-flex justify-content-center align-items-center"
              >
                <img
                  src="assets/flagman/chek.png"
                  class="img-fluid  "
                />
              </div>
              <div class="col-7">
                <h6 class="pt-2">
                  {{'Flagman.SecondFlag.IconSix' | translate}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!--News tile -->


      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-lg-6 col-md-10">

        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/B_wa7AB_EzA"
                  allowfullscreen></iframe>
        </div>

      </div>
      <!-- Grid column -->


    </div>
    <!--Grid row-->


  </div>

</section>
<!--Section: Products v.3-->
