<div class="container pl-0">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <ol class="step pl-0 pt-5">

        <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number ">
            <img class="img-fluid mx-auto" src="assets/auto/restourant/kisspng-technical-support-information-technology-help-desk-call-center-5b284d6d0fa629_0964476315293679170641.png">
          </div>
          <div class="step-excerpt">
            <h6>{{'B52.Restaurant.Five.OneTop' | translate}}</h6>
            <h3>{{'B52.Restaurant.Five.OneCenter' | translate}}</h3>
            <p style="text-align: justify;">{{'B52.Restaurant.Five.OneBottom' | translate}}</p>
            <p></p>
          </div>
        </li>

        <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number ">
            <img class="img-fluid mx-auto" src="assets/auto/restourant/transparent-logistic.png">
          </div>
          <div class="step-excerpt">
            <h6>{{'B52.Restaurant.Five.TwoTop' | translate}}</h6>
            <h3>{{'B52.Restaurant.Five.TwoCenter' | translate}}</h3>
            <p style="text-align: justify;">{{'B52.Restaurant.Five.TwoBottom' | translate}}</p>
            <p></p>
          </div>
        </li>




      </ol>
    </div>

    <div class="col-lg-6 mb-4">
      <ol class="step pl-0 pt-5">


        <li class="step-element pb-5 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number ">
            <img class="img-fluid mx-auto" src="assets/auto/restourant/cooking-chef.png">
          </div>
          <div class="step-excerpt">
            <h6>{{'B52.Restaurant.Five.ThreeTop' | translate}}</h6>
            <h3>{{'B52.Restaurant.Five.ThreeCenter' | translate}}</h3>
            <p style="text-align: justify;">{{'B52.Restaurant.Five.ThreeBottom' | translate}}</p>
            <p></p>
          </div>
        </li>



        <li class="step-element pb-0 wow zoomIn" data-wow-delay="0.6s">
          <div class="step-number ">
            <img class="img-fluid mx-auto" src="assets/auto/restourant/kisspng-technical.png">
          </div>
          <div class="step-excerpt">
            <h6>{{'B52.Restaurant.Five.FourTop' | translate}}</h6>
            <h3>{{'B52.Restaurant.Five.FourCenter' | translate}}</h3>
            <p style="text-align: justify;">{{'B52.Restaurant.Five.FourBottom' | translate}}</p>
            <p></p>
          </div>
        </li>






      </ol>
    </div>

  </div>
</div>




<style>
  .fa-play:before {
    margin-left: .3rem;
  }

  /* Steps */
  .step {
    list-style: none;
    margin: 0;
  }

  .step-element {
    display: flex;
    padding: 1rem 0;
  }

  .step-number {
    position: relative;
    width: 7rem;
    flex-shrink: 0;
    text-align: center;
  }

  .step-number .number {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }

  .step-number .number {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    border-radius: 10rem;
  }

  .step-number::before {
    /*content: '';*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 48px;*/
    /*bottom: -2rem;*/
    /*margin-left: -1px;*/
    /*border-left: 2px dashed #fff;*/
  }

  .step .step-element:last-child .step-number::before {
    bottom: 1rem;
  }
</style>


























