<body (window:scroll)="onScroll($event)" class="fixed-sn light-blue-skin">

<header>
  <!--Navbar-->
  <div style="font-family: Times New Roman;" class="">
    <!--Navbar-->
    <mdb-navbar SideClass="navbar navbarmidle  navbar-expand-lg d-flex justify-content-center" [containerInside]="false">
      <!-- Navbar brand -->

      <div class="pl-5">
        <ng-container *ngIf="lang == 'ru'">
          <a [routerLink]="'/'">
            <img src="assets/logo/White_ru-min.png" height="50" alt="" class="pr-5">
          </a>
        </ng-container>

        <ng-container *ngIf="lang == 'en'">
          <a [routerLink]="'/'">
            <img src="assets/logo/White_en-min.png" height="50" alt="" class="pr-5">
          </a>
        </ng-container>

        <ng-container *ngIf="lang == 'ro'">
          <a [routerLink]="'/'">
            <img src="assets/logo/White_ro-min.png" height="50" alt="" class="pr-5">
          </a>
        </ng-container>
      </div>

        <div class="d-block d-xl-none pl-3">
          <li class="nav-item container py-3 my-3 d-flex justify-content-center">
                      <div style="border: 2px solid;" class="row  border-white">
                        <div class="col">
                          <div class="row">
                            <div style="border: 3px solid;" class="col-5  border-white bg-white text-center">
                              <a routerLink="/contacts">
                              <h5 class="blue-text flex-center flex-nowrap example-parent">
                                <mdb-icon class="mr-1" fas icon="map-marker-alt"></mdb-icon> {{'MAIN.CONTACT.Filial' | translate}}
                              </h5>
                              </a>
                            </div>
                            <div class="col d-flex flex-row-reverse">
                              <div class="row">
                                <div class="w-100">
                                  <a class="text-white" href="tel:+37322244572">
                                  <div class="col text-white font-weight-bold">{{'MAIN.CONTACT.Magazin' | translate}}</div>
                                  <div class="col text-white font-weight-bold">+37322244572</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
          </li>
          <li class="nav-item container">
            <div style="border: 2px solid;" class="row border-white">
              <div class="col col-example bg-white text-center">
                <b class="blue-text flex-center flex-nowrap example-parent">
                  <b [innerHTML]="'MAIN.CONTACT.Tech'  | translate"></b>
                </b>
              </div>
              <div class="w-100"></div>
              <div class="col col-example text-center border-right font-weight-bold">
                <a class="text-white flex-center" href="tel:+37360001103">
                  <b [innerHTML]="'MAIN.CONTACT.Torg'  | translate"></b>
                </a>
              </div>
              <div class="col col-example text-center border-left font-weight-bold">
                <a class="text-white flex-center" href="tel:+37369900989">
                  <b [innerHTML]="'MAIN.CONTACT.Prom'  | translate"></b>
                </a>
              </div>
            </div>

          </li>



        </div>

<!-- ----------------------------------------------------------------------------------------------------------------------- -->


      <links>
        <div class="navbar-nav ml-auto d-none d-xl-block">
        <ul class="navbar-nav ml-auto d-flex justify-content-center">
          <div class="d-flex justify-content-center">
            <div class="align-self-center">
            <li class="navbar-brand-my">
              <div style="border: 2px solid;" class="row  border-white mr-4">
                <div class="col">
                  <div class="row">
                    <div style="border: 3px solid;" class="col border-white bg-white text-center">
                      <a routerLink="/contacts">
                      <h5 class="blue-text flex-center flex-nowrap example-parent">
                        <mdb-icon class="mr-1" fas icon="map-marker-alt"></mdb-icon>
                        {{'MAIN.CONTACT.Filial' | translate}}
                      </h5>
                      </a>
                    </div>
                    <div class="col">
                      <a class="text-white" href="tel:+37322244572">
                      <div class="row text-center">
                        <div class="col text-white font-weight-bold ">{{'MAIN.CONTACT.Magazin' | translate}}</div>
                        <div class="col font-weight-bold ">+37322244572</div>
                      </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            </div>

            <li class="navbar-brand-my">
              <div style="border: 2px solid;" class="row border-white">
                <div class="col col-example bg-white text-center">
                  <b class="blue-text flex-center flex-nowrap example-parent">
                    <b [innerHTML]="'MAIN.CONTACT.Tech'  | translate"></b>
                  </b>
                </div>
                <div class="w-100"></div>
                <div class="col col-example text-center border-right font-weight-bold">
                  <a class="text-white flex-center" href="tel:+37360001103">
                    <b [innerHTML]="'MAIN.CONTACT.Torg'  | translate"></b>
                  </a>
                </div>
                <div class="col col-example text-center border-left font-weight-bold">
                  <a class="text-white flex-center" href="tel:+37369900989">
                    <b [innerHTML]="'MAIN.CONTACT.Prom'  | translate"></b>
                  </a>
                </div>
              </div>

            </li>
          </div>
        </ul>
        </div>
      </links>

    </mdb-navbar>

  </div>


  <!-- Меню верхнее -->

  <div [ngClass]="{'fixed-top': hide}">
    <div class="">
      <!--Navbar-->

      <mdb-navbar SideClass="navbar navbar-expand-lg  navbar-light grey lighten-3   font-weight-bolder"
                  [containerInside]="false">

        <!-- -------------------------------------------------------------------------- -->
        <div style="height: 50px" class="navbar-nav mr-auto">

          <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item">
              <ul class="navbar-nav nav-flex-icons ml-auto">
                <li class="nav-item d-block d-lg-none">
                  <a href="https://www.facebook.com/alexse.com.md" target="_blank" type="button" mdbBtn floating="true"
                     size="lg" color="fb" class="waves-light">
                    <mdb-icon class="indigo-text" fab icon="facebook-f"></mdb-icon>
                  </a>
                </li>
                <li class="nav-item d-block d-lg-none">
                  <a href="https://www.youtube.com/channel/UCZeEuCodwjhQIwugyqWDBAA" target="_blank" type="button"
                     mdbBtn floating="true" size="lg" color="yt" class="waves-light">
                    <mdb-icon class="red-text" fab icon="youtube"></mdb-icon>
                  </a>
                </li>
                <li class="nav-item d-block d-lg-none">
                  <a href="https://www.instagram.com/alex.s_e/" target="_blank" type="button" mdbBtn floating="true"
                     size="lg" color="ins" class="waves-light">
                    <mdb-icon class="orange-text" fab icon="instagram"></mdb-icon>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="d-block d-lg-none navbar-nav mr-auto">
          <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item">

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink-5" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <ng-container *ngIf="lang == 'ru'">
                  <img class="pr-1" height="28px" src="assets/lang/russia.png"> <small class="mt-2">RU</small>
                </ng-container>

                <ng-container *ngIf="lang == 'en'">
                  <img class="pr-1" height="28px" src="assets/lang/en.png"> <small class="mt-2">EN</small>
                </ng-container>

                <ng-container *ngIf="lang == 'ro'">
                  <img class="pr-1" height="28px" src="assets/lang/romania.png"> <small class="mt-2">RO</small>
                </ng-container>

              </a>

              <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-5">

                <a (click)="changeLang('ru')" class="dropdown-item"> <img height="30px" src="assets/lang/russia.png">
                  Russian</a>
                <a (click)="changeLang('en')" class="dropdown-item"> <img height="30px" src="assets/lang/en.png">
                  English</a>
                <a (click)="changeLang('ro')"  class="dropdown-item"> <img height="30px" src="assets/lang/romania.png">
                  Romanian</a>
              </div>

            </li>

            <li class="nav-item" style="padding-top: 1px">
              <a routerLink="/cart" class="nav-link waves-effect">
          <span class="badge red z-depth-1 mr-1">
          </span><i id="navbar-static-cart" alt="Cart" class="fas fa-shopping-cart">
              </i>
                <mdb-badge *ngIf="order.cartItems !== null; else cartNull"
                           danger="true">{{order.cartItems}}</mdb-badge>
                <ng-template #cartNull>
                  <mdb-badge danger="true">{{cartItems}}</mdb-badge>
                </ng-template>
                <span class="sr-only"> Cart </span>
              </a>
            </li>


          </ul>
        </div>
        <!-- -------------------------------------------------------------------------- -->
        <div [ngClass]="{'d-none': scroll }" class="">
          <ng-container *ngIf="lang == 'ru'">
            <a [routerLink]="'/'">
              <img src="assets/logo/Blue_ru-min.png" height="50" alt="" class="d-none d-xl-block pr-5">
            </a>
          </ng-container>

          <ng-container *ngIf="lang == 'en'">
            <a [routerLink]="'/'">
              <img src="assets/logo/Blue_en-min.png" height="50" alt="" class=" d-none d-xl-block pr-5">
            </a>
          </ng-container>

          <ng-container *ngIf="lang == 'ro'">
            <a [routerLink]="'/'">
              <img src="assets/logo/Blue_ro-min.png" height="50" alt="" class=" d-none d-xl-block pr-5">
            </a>
          </ng-container>
        </div>

        <!-- Collapsible content -->
        <links>
          <!-- Links -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown pr-3" dropdown>
              <a routerLink="/products" type="button" class="nav-link  waves-light " mdbWavesEffect>
                {{ 'MAIN.NAVIGATION.Product' | translate }}
                <span class="caret"></span></a>

            </li>

            <div>
              <li class="nav-item dropdown pr-3" dropdown>
                <a routerLink="/programs" dropdownToggle mdbWavesEffect type="button"
                   class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                  {{ 'MAIN.NAVIGATION.Programs' | translate }}<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                  <a class="dropdown-item waves-light font-weight-bolder" mdbWavesEffect routerLink="/alex-market">Alex
                    Market</a>
                  <a class="dropdown-item waves-light font-weight-bolder" mdbWavesEffect
                     routerLink="/flagman">Flagman</a>
                  <a class="dropdown-item waves-light font-weight-bolder" mdbWavesEffect routerLink="/b52">B52</a>
                </div>
              </li>
            </div>

            <li class="nav-item pr-3">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/support-service">{{ 'MAIN.NAVIGATION.Support' | translate }}</a>
            </li>

            <li class="nav-item pr-3 ">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/about">{{ 'MAIN.NAVIGATION.About' | translate }}</a>
            </li>

            <li class="nav-item pr-3 ">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/solutions">{{ 'MAIN.NAVIGATION.Solutions' | translate }}</a>
            </li>

            <li class="nav-item pr-3">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/contacts">{{ 'MAIN.NAVIGATION.Contacts' | translate }}</a>
            </li>


            <li class="nav-item pr-3">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/news">{{ 'MAIN.NAVIGATION.News' | translate }}</a>
            </li>


            <li class="nav-item pr-3">
              <a class="nav-link waves-light" mdbWavesEffect
                 routerLink="/price-list">{{ 'MAIN.NAVIGATION.Price' | translate }}</a>
            </li>

          </ul>
          <!-- Links -->

          <!-- Full Nav two -->

          <div class="collapse navbar-collapse" id="navbarSupportedContent-555">
            <ul class="navbar-nav mr-auto">

            </ul>
            <ul class="navbar-nav ml-auto nav-flex-icons">
              <li class="nav-item">
                <ul class="navbar-nav nav-flex-icons ml-auto">
                  <li class="nav-item d-none d-md-block">
                    <a href="https://www.facebook.com/alexse.com.md" target="_blank" type="button" mdbBtn floating="true"
                       size="lg" color="fb" class="waves-light">
                      <mdb-icon class="indigo-text" fab icon="facebook-f"></mdb-icon>
                    </a>
                  </li>
                  <li class="nav-item d-none d-md-block">
                    <a href="https://www.youtube.com/channel/UCZeEuCodwjhQIwugyqWDBAA" target="_blank" type="button"
                       mdbBtn floating="true" size="lg" color="yt" class="waves-light">
                      <mdb-icon class="red-text" fab icon="youtube"></mdb-icon>
                    </a>
                  </li>
                  <li class="nav-item d-none d-md-block">
                    <a href="https://www.instagram.com/alex.s_e/" target="_blank" type="button" mdbBtn floating="true"
                       size="lg" color="ins" class="waves-light">
                      <mdb-icon class="orange-text" fab icon="instagram"></mdb-icon>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="d-none d-lg-block">
            <ul class="navbar-nav ml-auto nav-flex-icons">

              <li class="nav-item pr-3">

              <li class="nav-item dropdown">

                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <ng-container *ngIf="lang == 'ru'">
                    <img class="pr-1" height="28px" src="assets/lang/russia.png"> <small class="mt-2">RU</small>
                  </ng-container>

                  <ng-container *ngIf="lang == 'en'">
                    <img class="pr-1" height="28px" src="assets/lang/en.png"> <small class="mt-2">EN</small>
                  </ng-container>

                  <ng-container *ngIf="lang == 'ro'">
                    <img class="pr-1" height="28px" src="assets/lang/romania.png"> <small class="mt-2">RO</small>
                  </ng-container>

                </a>

                <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">

                  <a (click)="changeLang('ru')"  class="dropdown-item"> <img height="30px" src="assets/lang/russia.png">
                    Russian</a>
                  <a (click)="changeLang('en')"  class="dropdown-item"> <img height="30px" src="assets/lang/en.png">
                    English</a>
                  <a (click)="changeLang('ro')"  class="dropdown-item"> <img height="30px" src="assets/lang/romania.png">
                    Romanian</a>
                </div>

              </li>

              <li class="nav-item">
                <a routerLink="/cart" class="nav-link waves-effect">
            <span class="badge red z-depth-1 mr-1">
            </span><i id="navbar-static-cart1" alt="Cart" class="fas fa-shopping-cart">
                </i>
                  <mdb-badge *ngIf="order.cartItems !== null; else cartNull"
                             danger="true">{{order.cartItems}}</mdb-badge>
                  <ng-template #cartNull>
                    <mdb-badge danger="true">{{cartItems}}</mdb-badge>
                  </ng-template>
                  <span class="sr-only"> Cart </span>
                </a>
              </li>
            </ul>
          </div>
        </links>
        <!-- Collapsible content -->
      </mdb-navbar>
    </div>
  </div>


</header>


<router-outlet></router-outlet>


<!-- Footer -->
<div class="bgwhy">

  <footer class="page-footer font-small d-print-none">

    <!-- Footer Links -->
    <div class="container text-center text-md-left">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-md-2 mx-auto pt-5">
          <!-- Links -->
          <strong
            class="font-weight-bold text-uppercase mt-3 mb-4 my-translated-paragraph">{{ 'MAIN.FOOTER.CHISINAU.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              <div [innerHTML]="'MAIN.FOOTER.CHISINAU.Adress' | translate"></div>
            </li>
            <li>
              <a href="tel:+37322244572">{{ 'MAIN.FOOTER.CHISINAU.Phone' | translate }}</a>
            </li>
          </ul>
        </div>
        <!-- Grid column -->

        <hr class="clearfix w-100 d-md-none">


        <div class="col-md-2 mx-auto pt-5">

          <strong class="font-weight-bold text-uppercase mt-3 mb-4">{{ 'MAIN.FOOTER.BALTI.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              {{ 'MAIN.FOOTER.BALTI.Adress' | translate }}
            </li>
            <li>
              <a href="tel:+37323140456">{{ 'MAIN.FOOTER.BALTI.Phone' | translate }}</a>
            </li>
          </ul>
        </div>

        <hr class="clearfix w-100 d-md-none">
        <div class="col-md-2 mx-auto pt-5">
          <strong
            class="font-weight-bold text-uppercase mt-3 mb-4">{{ 'MAIN.FOOTER.TIRASPOL.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              {{ 'MAIN.FOOTER.TIRASPOL.Adress' | translate }}
            </li>
            <li>
              <a href="tel:53367540">{{ 'MAIN.FOOTER.TIRASPOL.Phone' | translate }}</a>
            </li>
          </ul>
        </div>
        <hr class="clearfix w-100 d-md-none">
        <div class="col-md-2 mx-auto pt-5">
          <strong class="font-weight-bold text-uppercase mt-3 mb-4">{{ 'MAIN.FOOTER.COMRAT.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              {{ 'MAIN.FOOTER.COMRAT.Adress' | translate }}
            </li>
            <li>
              <a href="tel:+37329880533 ">{{ 'MAIN.FOOTER.COMRAT.Phone' | translate }}</a>
            </li>
          </ul>
        </div>
        <hr class="clearfix w-100 d-md-none">
        <div class="col-md-2 mx-auto pt-5">
          <!-- Links -->
          <strong
            class="font-weight-bold text-uppercase mt-3 mb-4">{{ 'MAIN.FOOTER.DROCHIA.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              {{ 'MAIN.FOOTER.DROCHIA.Adress' | translate }}
            </li>
            <li>
              <a href="tel:+37368650707">{{ 'MAIN.FOOTER.DROCHIA.Phone' | translate }}</a>
            </li>
          </ul>
        </div>

        <div class="col-md-2 mx-auto pt-5">
          <!-- Links -->
          <strong
            class="font-weight-bold text-uppercase mt-3 mb-4">{{ 'MAIN.FOOTER.UNGHENI.Name' | translate }}</strong>
          <ul class="list-unstyled">
            <li>
              {{ 'MAIN.FOOTER.UNGHENI.Adress' | translate }}
            </li>
            <li>
              <a href="tel:+37368650707">{{ 'MAIN.FOOTER.UNGHENI.Phone' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-copyright text-center mt-2 py-3">Copiryght © ICS
      <a href="http://alex-se.com/"> "Alex S&E" SRL 2020</a>
    </div>

  </footer>
</div>
</body>















