<section class="pb-5 my-2 flex-center">
<div class="container text-center">
    <!--Section heading-->
    <h2 class="h2 py-4 wow fadeInDown font-weight-bold" data-wow-delay="0.6s">{{ 'HOME.PARTNERS.Title' | translate}}</h2>
<!--<mdb-carousel [isControls]="false" class="carousel-multi-item pb-5 pr-5 flex-center" [type]="'carousel-multi-item'"-->
<!--              [animation]="'slide'">-->
<!--  <mdb-carousel-item *ngFor="let item of slides; let i = index">-->
<!--    <div class=""  *ngFor="let card of item; let cardIndex = index">-->
<!--      <mdb-card class="pr-5 ">-->
<!--        <mdb-card-img class="img-thumbnail pr-3 pl-3" style="height: 60px; width: 120px" [src]="card.img" alt="Card image cap"></mdb-card-img>-->
<!--      </mdb-card>-->
<!--    </div>-->
<!--  </mdb-carousel-item>-->
<!--</mdb-carousel>-->


  <mdb-carousel [isControls]="false" class="carousel-multi-item multi-animation wow fadeIn" data-wow-delay="0.6s" [type]="'carousel-multi-item'"
                [animation]="'slide'">
    <mdb-carousel-item *ngFor="let item of slides; let i = index">
      <div class="col-md-2 " *ngFor="let card of item">
        <mdb-card class="my-1">
          <mdb-card-img class="img-thumbnail" [src]="card.img" alt="Card image cap"></mdb-card-img>
        </mdb-card>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>

</div>
</section>



