<!--Section: Products v.3-->
<section class="text-center pb-3 my-1 flex-center">
  <div class="container text-center">

  <!--Section heading-->
  <h2 class="h2 py-1 wow fadeInDown font-weight-bold" data-wow-delay="0.6s">{{ 'HOME.SHOP.Title' | translate }}</h2>
  <!--Section description-->
  <p class="grey-text mb-3 wow fadeInDown" data-wow-delay="0.6s">{{ 'HOME.SHOP.Text' | translate }}</p>

  <!--Grid row-->
  <div  class="row ">



    <!--Grid column-->
    <ng-container *ngFor="let prod of products$" >
    <div *ngIf="prod.option"  class="col-lg-3 col-md-6 mb-4 wow bounceIn" data-wow-delay="0.6s">

      <!--Card-->
      <mdb-card class="align-items-center">

        <!--Card image-->
        <div class="view overlay waves-light" mdbWavesEffect>
          <mdb-card-img  src="{{prod.img[0].path}}" alt=""></mdb-card-img>
          <a [routerLink]="['products/item', prod._id]">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <!--Card image-->

        <!--Card content-->
        <mdb-card-body class="text-center">

          <h5>
            <strong>
              <a [routerLink]="['products/item', prod._id]" class="dark-grey-text">
                <ng-container *ngIf="this.main.lang == 'ru'">
                  {{prod.name}}
                </ng-container>

                <ng-container *ngIf="this.main.lang == 'ro'">
                  {{prod.nameRo}}
                </ng-container>

                <ng-container *ngIf="this.main.lang == 'en'">
                  {{prod.nameEn}}
                </ng-container>

                <mdb-badge pill="true" danger="true">NEW</mdb-badge>
              </a>
            </strong>
          </h5>

          <h4 class="font-weight-bold blue-text">
            <strong>{{prod.cost | currency: 'MDL '}}</strong>
          </h4>

        </mdb-card-body>
        <!--Card content-->

      </mdb-card>
      <!--Card-->

    </div>
    </ng-container>
    <!--Grid column-->





  </div>
</div>
</section>
<!--Section: Products v.3-->

<ng-template #loading>
  <p class="text-center">Загружаю продукты...</p>
</ng-template>
