<!--Section: Content-->
<section class="container pt-2">
  <mdb-breadcrumb-item>
    <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>
  </mdb-breadcrumb-item>

  <h1 class="text-center font-weight-bold mb-4 pb-2 wow zoomIn" data-wow-delay="0.3s">
    {{'CarWeights.Category.Title' | translate}}
  </h1>


</section>
<!--Section: Content-->

<div class="container flex-center pt-4">

  <div class="row">

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold mb-4" style="width: 700px;max-height: 305px" src="assets/industrial/13.jpg" alt="Sample image">
        <a [routerLink]="'/stationary-automotive-scales'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatOne' | translate}}
          </h5>
        </div>

      </div>


    </div>
    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow zoomIn" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold mb-4" style="width: 700px; max-height: 330px" src="assets/industrial/2.jpg" alt="Sample image">
        <a [routerLink]="'/mobile-farmer-scales'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatTwo' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold" style="width: 700px; max-height: 330px" src="assets/industrial/3.jpg" alt="Sample image">
        <a [routerLink]="'/portable-for-weighing-axle-loads'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatThree' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="w-100"></div>

    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInLeft" data-wow-delay="0.3s">
        <img class="img-fluid mb-4" style="width: 700px;max-height: 330px" src="assets/industrial/4.jpg" alt="Sample image">
        <a [routerLink]="'/stationary-for-weighing-axle-loads'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatFour' | translate}}
          </h5>
        </div>

      </div>

    </div>
    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow zoomIn" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold mb-4" style="width: 3000px;max-height: 330px;" src="assets/industrial/5.jpg" alt="Sample image">
        <a [routerLink]="'/on-board-weighing-systems'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>

        <div class="card-body flex-center font-weight-bold">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatFive' | translate}}
          </h5>
        </div>

      </div>

    </div>


    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/industrial/6.jpg" alt="Sample image">
        <a [routerLink]="'/modernization-of-automotive-scales'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>


        <div class="card-body flex-center font-weight-bold mb-4">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatSix' | translate}}
          </h5>
        </div>

      </div>

    </div>

    <div class="w-100"></div>


    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/automaticSystem/logo.jpg" alt="Sample image">
        <a [routerLink]="'/automatic-weight-and-dimension-control-system'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>


        <div class="card-body flex-center font-weight-bold mb-4">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatSeven' | translate}}
          </h5>
        </div>

      </div>

    </div>



    <div class="col">
      <!-- Image -->

    </div>


    <div class="col">
      <!-- Image -->
      <div class="view overlay rounded z-depth-1-half mb-4 wow fadeInRight" data-wow-delay="0.3s">
        <img class="img-fluid font-weight-bold " style="width: 700px; max-height: 330px" src="assets/industrial/auto/Cantar_Automobil_Mobile_Line-min.png" alt="Sample image">
        <a [routerLink]="'/mobile-truck-scale-with-continuous-platform'">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>


        <div class="card-body flex-center font-weight-bold mb-4">
          <h5 class="font-weight-bold">
            {{'CarWeights.Category.CatEight' | translate}}
          </h5>
        </div>

      </div>
    </div>

  </div>

</div>

<app-icon-list></app-icon-list>
<app-support-indus></app-support-indus>
<app-text-indus></app-text-indus>

