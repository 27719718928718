<div class="bgcomplectheadalex wow zoomIn" data-wow-delay="0.1s">
  <mdb-card-body cascade="true" class="text-center">
    <!--Title-->
    <mdb-card-title>
      <h1 class="pt-5">
        <strong class="white-text wow fadeInDown" data-wow-delay="0.6s"> {{ 'PosAlimentara.Header.TitleTop' | translate }}<p class=" wow zoomIn" data-wow-delay="1.0s">
          {{ 'PosAlimentara.Header.TitleBottom' | translate }}
        </strong>
      </h1>
    </mdb-card-title>

    <mdb-card-text class="black-text pt-5 container wow fadeInDown" data-wow-delay="1.4s">
      <div class="row text-center d-flex justify-content-center">
        <div class="col-lg-3 col-md-6 mb-lg-0 mb-5">
          <i class="fas fa-gift fa-2x white-text mb-4"></i>
          <h6 class="font-weight-bold mb-4 white-text">{{ 'PosAlimentara.Header.IconOne' | translate }}</h6>

        </div>
        <div class="col-lg-3 col-md-6 mb-lg-0 mb-5">
          <i class="fas fa-archive white-text fa-2x mb-4"></i>
          <h6 class="font-weight-bold mb-4 white-text">{{ 'PosAlimentara.Header.IconTwo' | translate }}</h6>

        </div>
        <div class="col-lg-3 col-md-6 mb-md-0 mb-5">
          <i class="fas fa-shopping-cart fa-2x white-text mb-4"></i>
          <h6 class="font-weight-bold mb-4 white-text">{{ 'PosAlimentara.Header.IconThree' | translate }}</h6>

        </div>
        <div class="col-lg-3 col-md-6 mb-md-0 mb-5">
          <i class="fas fa-cart-arrow-down fa-2x white-text mb-4"></i>
          <h6 class="font-weight-bold mb-4 white-text">{{ 'PosAlimentara.Header.IconFour' | translate }}</h6>
        </div>
      </div>
    </mdb-card-text>
  </mdb-card-body>
</div>












