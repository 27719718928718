<!--Section: Features v.3-->
<section class="container pb-3">

  <!--Section heading-->
  <h3 class="font-weight-bold text-center dark-grey-text pb-2 pt-4 wow fadeIn"
      data-wow-delay="0.6s">{{'AlexMarket.Terminal.Title' | translate}}</h3>
  <hr class="w-header my-4 pb-3">
  <!--Section description-->

  <!--Grid row-->
  <div class="row pt-2">
    <div class="col-lg-5 text-center text-lg-left">
      <!--Grid column-->
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/gyZQ1uhvrBI"
                allowfullscreen></iframe>
      </div>
      <!--Grid column-->
    </div>

    <!--Grid column-->
    <div class="col-lg-7">

      <!--Grid row-->
      <div class="row pb-3 pl-3">
        <div class="col-10">
          <p class="text-black text-left">{{'AlexMarket.Terminal.Text' | translate}}</p>
        </div>
      </div>
      <!--Grid row-->


    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
<!--Section: Features v.3-->


