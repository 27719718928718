<div class="container pt-1 pb-4">

  <!-- Section -->
  <section>

    <style>
      .border-top {
        border-top-width: 2px !important;
      }
    </style>

    <div class="row">

      <div class="col-md-8 col-lg-8 col-xl-6 wow fadeInLeft" data-wow-delay="0.6s">

        <p class="pb-3 pb-md-1">
        <div [innerHTML]="'Support.Text.Left'  | translate"></div>
      </div>

      <div class="col-md-8 col-lg-8 col-xl-6 wow fadeInRight" data-wow-delay="0.6s">
        <p class="pb-3 pb-md-1">
        <div [innerHTML]="'Support.Text.Right'  | translate"></div>
      </div>
    </div>
  </section>
  <!-- Section -->

</div>
