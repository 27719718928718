<div class="view">
  <div style="height: 585px;" class="bgprograms wow zoomIn" data-wow-delay="0.1s">
  </div>
  <div class="mask">
    <div class="pone">
      <mdb-card-body cascade="true" class="text-center ">
        <!--Title-->
        <mdb-card-title>
          <h1>
            <strong class="white-text wow fadeInDown" data-wow-delay="1.0s" >{{'Programs.Header.Title' | translate}}</strong>
          </h1>
        </mdb-card-title>

        <mdb-card-text class="white-text pt-5">
          <h5 class=" wow fadeInDown" data-wow-delay="2.6s">
            <div [innerHTML]="'Programs.Header.SubTitle'  | translate"></div>
          </h5>
        </mdb-card-text>
      </mdb-card-body>
    </div>
  </div>
</div>











