
<div class="container flex-center gotop pb-5 my-5">

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="text-center border border-light p-5"
    style="width: 600px"
  >
    <app-alerting></app-alerting>

    <p class="h4 mb-4">Создать акаунт</p>
        <div class="card-title font-weight-bold red-text">
        </div>
        <div class=" font-weight-bold green-text" >

        </div>

    <!-- Email -->
    <div
      [ngClass]="{invalid: form.get('email').touched && form.get('email').invalid }"
      class="mb-4">
      <input
        formControlName="email"
        type="email"
        id="defaultLoginFormEmail"
        class="form-control"
        placeholder="E-mail"
      >
      <div
        class="red-text "
        *ngIf="form.get('email').touched && form.get('email').invalid"
      >
        <small  *ngIf="form.get('email').errors.required">
          Введите поле email
        </small>
        <small *ngIf="form.get('email').errors.email">
          Поле email должно быть email
        </small>
      </div>
    </div>




    <!-- Password -->
    <div
      [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid }"
      class="mb-4">
      <input type="password" id="defaultLoginFormPassword" class="form-control" placeholder="Password" formControlName="password">
      <div
        class="red-text"
        *ngIf="form.get('password').touched && form.get('password').invalid"
      >
        <small *ngIf="form.get('password').errors.required" >
          Введите пароль
        </small>
        <small *ngIf="form.get('password').errors.minlength" >
          Пароль должен быть не менее
          {{form.get('password').errors.minlength.requiredLength}}
          символов. Сейчас он
          {{form.get('password').errors.minlength.actualLength}}
          символа.
        </small>
      </div>
      <div class="d-flex justify-content-around">
      </div>
    </div>



    <!-- Sign in button -->
    <button
      [disabled]="form.invalid || form.disabled"
      mdbBtn
      color="info"
      block="true"
      class="my-4"
      type="submit">
      Зарегистрироваться
    </button>




  </form>

</div>

