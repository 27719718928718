<!--Section: Features v.1-->
<section class="text-center " xmlns:color="http://www.w3.org/1999/xhtml">
  <div class="container text-center ">
  <!--Grid row-->
  <div class="row pt-3 flex-center">

    <!--Grid column-->
    <div class="col-md-2 mr-4 ml-1 wow fadeInUp" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>

      <h3 class="font-weight-bold mt-4 red-text">{{ 'HOME.QUALITIES.ONE.Title' | translate }}</h3>
      <p class="font-weight-bold blue-text">{{ 'HOME.QUALITIES.ONE.Text' | translate }}</p>
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-2 mr-4 ml-1 wow fadeInUp" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
      <h3 class="font-weight-bold mt-4 red-text">{{ 'HOME.QUALITIES.TWO.Title' | translate }}</h3>
      <p class="font-weight-bold blue-text">{{ 'HOME.QUALITIES.TWO.Text' | translate }}</p>
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-2 mr-4 ml-1 wow zoomIn" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
      <h3 class="font-weight-bold mt-4 red-text">{{ 'HOME.QUALITIES.THREE.Title' | translate }}</h3>
      <p class="font-weight-bold blue-text">{{ 'HOME.QUALITIES.THREE.Text' | translate }}</p>
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-2 mr-4 ml-1 wow fadeInDown pt-2" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse"></i>
        </span>
      <h3 class="font-weight-bold mt-4 red-text">{{ 'HOME.QUALITIES.FOUR.Title' | translate }} </h3>
      <p class="font-weight-bold blue-text">{{ 'HOME.QUALITIES.FOUR.Text' | translate }}</p>
    </div>
    <!--Grid column-->


    <!--Grid column-->
    <div class="col-md-2 mr-4 ml-1 wow fadeInDown pt-2" data-wow-delay="0.3s">
        <span class="fa-stack fa-3x ">
          <i class="fas fa-circle fa-stack-2x" style="color:#1288ba"></i>
          <i class="fas fa-star fa-stack-1x fa-inverse "></i>
        </span>
      <h3 class="font-weight-bold mt-4 red-text">{{ 'HOME.QUALITIES.FIVE.Title' | translate }}</h3>
      <p class="font-weight-bold blue-text">{{ 'HOME.QUALITIES.FIVE.Text' | translate }}</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->





  <div class="row pt-3 flex-center" >
    <!-- Card -->
    <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.6s">

    <mdb-card
      style="background-image: url('assets/blog1.jpg');"
      class="card-image mb-3 mx-2 bgwhy"
    >
      <!-- Content -->
      <div class="flex-center text-white text-center d-flex align-items-center rgba-black-light py-5 px-4" >
        <div>
          <h4 class="white-text">
            <mdb-icon fas icon="certificate"></mdb-icon>
            {{ 'HOME.QUALITIES.LEFT.Title' | translate }}
          </h4>


          <a [routerLink]="'/certifications'" mdbBtn color="blue" class="waves-light" mdbWavesEffect>
            <mdb-icon far icon="clone" class="left"></mdb-icon>
            {{ 'BUTTONS.Certificate' | translate }}</a
          >
        </div>
      </div>
      <!-- Content -->
    </mdb-card>
    </div>
    <!-- Card -->

    <!-- Card -->
    <div class="col-lg-5">

    <mdb-card
      style="background-image: url('assets/blog2.jpg')"
      class="card-image mb-3 mx-2 bgwhy wow fadeInRight" data-wow-delay="0.6s"
    >
      <!-- Content -->
      <div class="flex-center text-white text-center d-flex align-items-center rgba-black-light py-5 px-4">
        <div>
          <h4 class="white-text">
            <mdb-icon fas icon="headset"></mdb-icon>
            {{ 'HOME.QUALITIES.RIGHT.Title' | translate }}
          </h4>

          <a routerLink="/support-service" mdbBtn color="blue" class="waves-light" mdbWavesEffect>
            <mdb-icon far icon="clone" class="left"></mdb-icon>
            {{ 'BUTTONS.Support' | translate }}</a
          >
        </div>
      </div>
      <!-- Content -->
    </mdb-card>
    </div>
    <!-- Card -->
  </div>

  </div>


</section>
<!--Section: Features v.1-->
