<div class="">
  <div class="container pt-2">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/products'">{{'CarWeights.BreadcrumbProduct' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/auto-index'">{{'CarWeights.Category.Title' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'CarWeights.MoveOc.Breadcrumb'  | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-lg-7 mb-3 align-self-center text-center text-lg-left">
          <h1 class="h2 mb-5">
            <div [innerHTML]="'CarWeights.MoveOc.Title'  | translate"></div>

          </h1>
          <h5 style="font-family: 'Times New Roman'" class="black-text mb-4">
            {{'CarWeights.MoveOc.SubTitle'  | translate}}
          </h5>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-5 mb-3">
          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/industrial/perenosnie/logo.jpg" alt="Sample image">
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->

  </div>

  <section class="container">
    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'CarWeights.MoveOc.Text'  | translate"></div>
      </h5>
    </div>

  </section>


  <section class="container">

    <div class="flex-center">
    <h3 class="pt-3 mb-3">{{'CarWeights.MoveOc.FuncTitle' | translate}}</h3>
    </div>
<div class="d-flex justify-content-center">
        <ul class="mb-4 col-7">
          <div [innerHTML]="'CarWeights.MoveOc.FuncText'  | translate"></div>

        </ul>
</div>

    <!--Grid row-->
    <div class="py-2 my-2">
      <div class="row flex-center">

        <!--Grid column-->
        <div class="col-md-4 col-lg-2 mb-4 mb-md-0">

          <!--Image-->
          <div class="view overlay z-depth-1-half">
          </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-8 mb-4 mb-md-0">


          <h5 class="">
            {{'Certificates.BDA' | translate}}

          </h5>


        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
    <h1 class="h2 text-center">{{'CarWeights.Tables.Title' | translate}}</h1>
    <div class="flex-center pb-2">
      <div class="col-lg-7">
        <table mdbTable striped="true" bordered="true">
          <thead>
          </thead>
          <tbody>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstOne' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastOne' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstTwo' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastTwo' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstThree' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastThree' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstFour' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastFour' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstFive' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastFive' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstSix' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastSix' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstSeven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastSeven' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstEight' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastEight' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstNine' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastNine' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstTen' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastTen' | translate}}</td>
          </tr>
          <tr mdbTableCol>
            <td class="">{{'CarWeights.Tables.Three.FirstEleven' | translate}}</td>
            <td class="">{{'CarWeights.Tables.Three.LastEleven' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </section>



</div>
