import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sixali',
  templateUrl: './sixali.component.html',
  styles: [
  ]
})
export class SixaliComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
