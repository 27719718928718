<!-- Section: Testimonials v.3 -->
<section class="team-section text-center my-5 flex-center">
<div class="containermid">

  <div class="row text-center">

    <!--Grid column-->
    <div class="col-md-4 mb-md-0 mb-5 wow bounceIn" data-wow-delay="0.6s">

      <div class="testimonial">
        <img class="img-fluid" src="assets/programs/AexMarket-main.jpg">
        <!--Content-->
        <h4 class="font-weight-bold dark-grey-text mt-4">{{'Programs.List.AlexTitle' | translate}}</h4>
        <p class="dark-grey-text pr-2 font-weight-bold">
        <div class="font-weight-bold" [innerHTML]="'Programs.List.AlexSubTitle'  | translate"></div>
        <!--Review-->
        <div>
          <button routerLink="/alex-market" type="button" class="btn btn-primary">
            {{'Programs.List.Button' | translate}}
          </button>
        </div>
      </div>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-4 mb-md-0 mb-5 wow bounceIn" data-wow-delay="0.6s">

      <div class="testimonial">
        <img class="img-fluid" src="assets/programs/B52-main.jpg">
        <!--Content-->
        <h4 class="font-weight-bold dark-grey-text mt-4">{{'Programs.List.B52Title' | translate}}</h4>
        <p class="dark-grey-text pr-2 font-weight-bold">
        <div class="font-weight-bold" [innerHTML]="'Programs.List.B52SubTitle'  | translate"></div>
        <!--Review-->
        <div>
          <button routerLink="/b52" type="button" class="btn btn-primary">
            {{'Programs.List.Button' | translate}}
          </button>
        </div>

      </div>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-4 wow bounceIn" data-wow-delay="0.6s">

      <div class="testimonial">
        <img class="img-fluid" src="assets/programs/Flafman-main.jpg">
        <!--Content-->
        <h4 class="font-weight-bold dark-grey-text mt-4">{{'Programs.List.FlagTitle' | translate}}</h4>
        <p class="dark-grey-text pr-2 font-weight-bold">
        <div class="font-weight-bold" [innerHTML]="'Programs.List.FlagSubTitle'  | translate"></div>
        <div>
          <button routerLink="/flagman" type="button" class="btn btn-primary">
            {{'Programs.List.Button' | translate}}
          </button>
        </div>

        <!--Review-->
      </div>

    </div>

    <!--Grid column-->

  </div>
  <!--Grid row-->
</div>
</section>
<!-- Section: Testimonials v.3 -->
