<div class="container mt-5">


  <!--Section: Content-->
  <section>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-8 mb-4 align-self-center text-center text-lg-left wow fadeInLeft" data-wow-delay="0.6s">

        <div class="wpb_wrapper">
          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6>{{'B52.Hotel.Second.OneTop' | translate}}</h6>
              <h3>{{'B52.Hotel.Second.OneCenter' | translate}}</h3>
              <p style="text-align: justify;">{{'B52.Hotel.Second.OneBottom' | translate}}</p>

            </div>
          </div>

          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6>{{'B52.Hotel.Second.TwoTop' | translate}}</h6>
              <h3>{{'B52.Hotel.Second.TwoCenter' | translate}}</h3>
              <p style="text-align: justify;">{{'B52.Hotel.Second.TwoBottom' | translate}}</p>

            </div>
          </div>

          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6>{{'B52.Hotel.Second.ThreeTop' | translate}}</h6>
              <h3>{{'B52.Hotel.Second.ThreeCenter' | translate}}</h3>
              <p style="text-align: justify;">{{'B52.Hotel.Second.ThreeBottom' | translate}}</p>

            </div>
          </div>
        </div>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-4 mb-4 wow fadeInRight" data-wow-delay="0.6s">

        <!-- Featured image -->
        <div class="view overlay">
          <img class="img-fluid mx-auto" src="assets/auto/hotel/receptionist.png" alt="Sample image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->


</div>
