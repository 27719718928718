import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divab',
  templateUrl: './divab.component.html',
  styles: [
  ]
})
export class DivabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
