<section class="text-center my-5">
  <div class="container">
    <div class="container-fluid mt-3 mb-5">

      <!-- Section: Block Content -->
      <section class="mb-4 wow zoomIn" data-wow-delay="0.6s">

        <style>
          .map-container {
            overflow: hidden;
            position: relative;
            height: 0;
          }

          .map-container iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
          }
        </style>

        <!-- Google Maps -->
        <div id="full-width-map" class="z-depth-1-half map-container" style="height: 500px">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.409922412399!2d28.833268315432562!3d47.03218651284877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c4b32635d1b%3A0x55a41cd8fa072219!2sAlex%20S%26E!5e0!3m2!1sru!2s!4v1576842217558!5m2!1sru!2s" frameborder="0"
                  style="border:0" allowfullscreen></iframe>
        </div>
        <!-- Google Maps -->

      </section>
      <!-- Section: Block Content -->

    </div>
  </div>
</section>

