<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">{{'PosCompact.MiniPos.TitleTop' | translate}}</h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">{{'PosCompact.MiniPos.TitleBottom' | translate}}</p>
  <div class="row">
    <div class="col order-1 wow fadeInLeft" data-wow-delay="1.2s">



      <div class="pb-5">
          <h6 class="font-weight-bold mb-3">
            <i class="fas fa-th fa-2x green-text pr-2"></i>
            {{'PosCompact.MiniPos.SpecTitleOne' | translate}}
          </h6>
          <p class="">
            {{'PosCompact.MiniPos.SpecTextOne' | translate}}
          </p>
      </div>
          <h6 class="font-weight-bold mb-3">
            <i class="fas fa-signal fa-2x green-text pr-2"></i>
            {{'PosCompact.MiniPos.SpecTitleTwo' | translate}}
          </h6>
          <p class="">
            {{'PosCompact.MiniPos.SpecTextTwo' | translate}}
          </p>

    </div>


    <div class="col-lg-6 order-3 wow fadeInRight" data-wow-delay="0.6s">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/EIgmqB6_gc8" allowfullscreen></iframe>
      </div>
    </div>



    <div class="col order-2 wow fadeInLeft" data-wow-delay="0.6s">
      <div class="pb-5">
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
        {{'PosCompact.MiniPos.SpecTitleThree' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.MiniPos.SpecTextThree' | translate}}

      </p>
    </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-plug fa-2x green-text pr-2"></i>
        {{'PosCompact.MiniPos.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.MiniPos.SpecTextFour' | translate}}
      </p>



    </div>

  </div>


  <button mdbBtn color="primary" class="waves-light mt-5 wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec' | translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-6 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
        <p style="text-align: left;"><strong>Model: PiPO X10 Pro</strong></p>
        <p style="text-align: left;"><strong>Color:</strong> Black<br>
          <strong>OS:</strong> Windows 10<br>
          <strong>CPU:</strong> Intel Cherry Trail Z8350 Quad Core<br>
          <strong>GPU:</strong> Intel HD Gen8 Graphics<br>
          <strong>RAM:</strong> 4GB DDR3L<br>
          <strong>ROM:</strong> 64GB eMMC<br>
          <strong>Screen:</strong> 10.8 inch<br>
          <strong>Display:</strong> IPS, Capacitive Touch Screen<br>
          <strong>Resoulution:</strong> 1920*1280 pixels<br>
          <strong>Card Extended:</strong> Support TF card up to 64GB4 x USB ports1 x HDMI port</p>
        <p style="padding-left: 40px; text-align: left;">1 x RJ45 LAN Port<br>
          1 x TF card slot<br>
          1 x Earphone jack<br>
          1 x 5V/3A DC Power port</p>
      </div>
    </div>
  </div>
  </div>























