<div class="container mt-5">


  <!--Section: Content-->
  <section>

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-8 mb-4 align-self-center text-center text-lg-left wow fadeInLeft" data-wow-delay="0.6s">
        <h6>{{'B52.Restaurant.Second.OneTop' | translate}}</h6>
        <h3>{{'B52.Restaurant.Second.OneCenter' | translate}}</h3>
        <p style="text-align: justify;">{{'B52.Restaurant.Second.OneBottom' | translate}}</p>



        <h6>{{'B52.Restaurant.Second.TwoTop' | translate}}</h6>
        <h3>{{'B52.Restaurant.Second.TwoCenter' | translate}}</h3>
        <p style="text-align: justify;">{{'B52.Restaurant.Second.TwoBottom' | translate}}</p>



      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-4 mb-4 wow fadeInRight" data-wow-delay="0.6s">

        <!-- Featured image -->
        <div class="view overlay">
          <img class="img-fluid mx-auto" src="assets/auto/restourant/oficiant1.jpg" alt="Sample image">
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->


</div>
