<div class="gotop">
  <app-headerali></app-headerali>
</div>
<app-fullali></app-fullali>
<app-threepos></app-threepos>
<app-foureali></app-foureali>
<app-fiveeali></app-fiveeali>
<app-sixali></app-sixali>
<app-scanali></app-scanali>
<app-sevenali></app-sevenali>
<app-eightali></app-eightali>
<app-questions></app-questions>
<app-morepos></app-morepos>
<app-lastshoppos></app-lastshoppos>
