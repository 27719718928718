<div class="bc-icons-2">
  <mdb-breadcrumb customClass="white">
    <div class="container">

      <mdb-breadcrumb-item>
        <mdb-breadcrumb-item (click)="productPage.defaultPage()"
                             [routerLink]="'/'">{{'Shop.Breadcrumbs.Home' | translate}}</mdb-breadcrumb-item>
        <mdb-breadcrumb-item (click)="productPage.defaultPage()"
                             [routerLink]="'/products'">{{'Shop.Breadcrumbs.Market' | translate}}</mdb-breadcrumb-item>
        <mdb-breadcrumb-item (click)="productPage.defaultPage()"
                             [routerLink]="'/products/subcategory/'+ this.subcategoryId">
          <ng-container *ngIf="this.main.lang == 'ru'">
            {{this.subcategoryName}}
          </ng-container>

          <ng-container *ngIf="this.main.lang == 'ro'">
            {{this.subcategoryNameRo}}
          </ng-container>

          <ng-container *ngIf="this.main.lang == 'en'">
            {{this.subcategoryNameEn}}
          </ng-container>
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="active">
          <ng-container *ngIf="this.main.lang == 'ru'">
            {{this.productName}}
          </ng-container>

          <ng-container *ngIf="this.main.lang == 'ro'">
            {{this.productNameRo}}
          </ng-container>

          <ng-container *ngIf="this.main.lang == 'en'">
            {{this.productNameEn}}
          </ng-container>

        </mdb-breadcrumb-item>
      </mdb-breadcrumb-item>

    </div>
  </mdb-breadcrumb>
</div>

<div *ngIf="product$ | async as product; else loading ">
  <div class="container gotop">
    <!--Section: Content-->
    <section class="">

      <div class="pt-2">
        <app-alerting></app-alerting>
      </div>
      <!-- Section heading -->

      <div class="row pt-0">

        <div class="col-lg-6">

          <!--Carousel Wrapper-->
          <div id="carousel-thumb1" class=" carousel slide carousel-fade carousel-thumbnails mb-1 pb-1"
               data-ride="carousel">

            <mdb-carousel class="flex-center" [isControls]="true" [animation]="'slide'">
              <!--First slide-->
              <mdb-slide>
                <div class=" pl-5">
                  <img src="{{product.img[0].path}}"
                       lightbox backgroundColor="white"
                       alt="First slide" class="img-fluid">

                </div>
              </mdb-slide>

            </mdb-carousel>


          </div>
          <!--/.Carousel Wrapper-->
          <div lightbox-group class="row mb-1 ">
            <div class="flex-center">
              <figure *ngIf="product.imgOne[0].path" class="col-md-2 py-2 border mr-3">
                <img src="{{product.imgOne[0].path}}"
                     lightbox
                     class="img-fluid">
              </figure>
              <figure *ngIf="product.imgTwo[0].path" class="col-md-2 py-2 border mr-3">
                <img src="{{product.imgTwo[0].path}}"
                     lightbox
                     class="img-fluid">
              </figure>
              <figure *ngIf="product.imgThree[0].path" class="col-md-2  py-2 border  mr-3">

                <img src="{{product.imgThree[0].path}}"
                     lightbox
                     class="img-fluid">
              </figure>
              <figure *ngIf="product.imgFour[0].path" class="col-md-2  py-2 border  mr-3">
                <img src="{{product.imgFour[0].path}}"
                     lightbox
                     class="img-fluid">
              </figure>
            </div>
          </div>
        </div>


        <div class="col-lg-6 text-center text-md-left">
          <ng-container *ngIf="this.main.lang == 'ru'">
            <h2
              class="h2-responsive text-center text-md-left product-name font-weight-bold dark-grey-text mb-1 ml-xl-0 ml-4 ">{{product.name}}</h2>
            <hr>
          </ng-container>

          <ng-container *ngIf="this.main.lang  == 'ro'">
            <h2
              class="h2-responsive text-center text-md-left product-name font-weight-bold dark-grey-text mb-1 ml-xl-0 ml-4 ">{{product.nameRo}}</h2>
            <hr>
          </ng-container>

          <ng-container *ngIf="this.main.lang  == 'en'">
            <h2
              class="h2-responsive text-center text-md-left product-name font-weight-bold dark-grey-text mb-1 ml-xl-0 ml-4 ">{{product.nameEn}}</h2>
            <hr>
          </ng-container>

          <div class="font-weight-normal">

            <p class="ml-xl-0 ml-4">
              <ng-container *ngIf="this.main.lang == 'ru'">
                <quill-view-html [content]="product.content"></quill-view-html>
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'ro'">
                <quill-view-html [content]="product.contentRo"></quill-view-html>
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'en'">
                <quill-view-html [content]="product.contentEn"></quill-view-html>
              </ng-container>
            </p>


            <div *ngIf="product.subcategory === casaProd" class="input-group mb-3">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="indicator1">{{'Shop.Select.Indicator' | translate}}</label>
                  <select [(ngModel)]="selectedIndicator" (change)="onSelectInd(selectedIndicator)"
                          class="browser-default custom-select" id="indicator1">
                    <option value="MERAV2000">MERAV2000</option>
                    <ng-container *ngFor="let indicat of indicatorS">
                      <ng-container *ngFor="let indi of product.indicator">
                        <ng-container *ngIf="indicat._id == indi">
                          <option [value]="indicat.name">
                            <ng-container *ngIf="this.main.lang == 'ru'">
                              {{indicat.name}} + {{indicat.price}} MDL
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'ro'">
                              {{indicat.nameRo}} + {{indicat.price}} MDL
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'en'">
                              {{indicat.nameEn}} + {{indicat.price}} MDL
                            </ng-container>
                          </option>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>

                <ng-container *ngFor="let indicat  of indicatorS">
                  <ng-container *ngIf="selectedIndicator === indicat.name ">
                    <div class="container pt-3 pb-1">
                      <!--Section: Content-->
                      <section class="">
                        <!--Grid row-->
                        <div class="row">
                          <!--Grid column-->
                          <div class="col-lg-4 col-md-3 mb-4 mb-md-0">
                            <!--Image-->
                            <!--                            <div class="view overlay z-depth-1-half">-->
                            <div class="">
                              <img lightbox src="{{indicat.img}}" class="img-fluid" alt="">
                            </div>
                          </div>
                          <!--Grid column-->
                          <!--Grid column-->
                          <div class="col-md-8 mb-2">
                            <ng-container *ngIf="this.main.lang == 'ru'">
                              <quill-view [content]="indicat.content"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'ro'">
                              <quill-view [content]="indicat.contentRo"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'en'">
                              <quill-view [content]="indicat.contentEn"></quill-view>
                            </ng-container>
                            <div *ngIf="indicat.price !== 0">
                              <strong class="text-danger"> + {{indicat.price | currency: 'MDL '}}</strong>
                            </div>
                          </div>
                          <!--Grid column-->
                        </div>
                        <!--Grid row-->
                      </section>
                      <!--Section: Content-->
                    </div>
                  </ng-container>
                </ng-container>

              </div>


              <div class="input-group-prepend">
                <label class="input-group-text" for="casa">{{'Shop.Select.Option' | translate}}</label>
                <select [(ngModel)]="selected" (change)="onSelectCasa(selected)" class="browser-default custom-select"
                        id="casa">
                  <option value="Без колес">Без колес</option>
                  <ng-container *ngFor="let cassss of casaS let inx = index">
                    <ng-container *ngIf="cassss._id == product.casa[inx]">
                      <option [value]="cassss.name">
                        <ng-container *ngIf="this.main.lang == 'ru'">
                          {{cassss.name}} + {{cassss.price}} MDL
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'ro'">
                          {{cassss.nameRo}} + {{cassss.price}} MDL
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'en'">
                          {{cassss.nameEn}} + {{cassss.price}} MDL
                        </ng-container>
                      </option>
                    </ng-container>
                  </ng-container>

                </select>
              </div>
            </div>

            <ng-container *ngFor="let cassss of casaS">
              <ng-container *ngIf="selected === cassss.name ">
                <div class="container pt-0 pb-1">
                  <!--Section: Content-->
                  <section class="">
                    <div class="row">

                      <div class="col-md-11 mb-2 mb-md-0">
                        <div *ngIf="cassss.price !== 0">
                          <strong class="d-flex justify-content-center text-danger">
                            + {{cassss.price | currency: 'MDL '}}</strong>
                        </div>
                      </div>
                      <!--Grid column-->
                    </div>
                    <!--Grid row-->
                  </section>
                  <!--Section: Content-->
                </div>
              </ng-container>
            </ng-container>


            <div
              *ngIf="product.subcategory === platProd || product.subcategory === platgogoProd || product.subcategory === platgoProd">

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="indicator">{{'Shop.Select.Indicator' | translate}}</label>
                  <select [(ngModel)]="selectedIndicator" (change)="onSelectInd(selectedIndicator)"
                          class="browser-default custom-select" id="indicator">
                    <option value="MERAV2000">MERAV2000</option>
                    <ng-container *ngFor="let indicat of indicatorS">
                      <ng-container *ngFor="let indi of product.indicator">
                        <ng-container *ngIf="indicat._id == indi">
                          <option [value]="indicat.name">
                            <ng-container *ngIf="this.main.lang == 'ru'">
                              {{indicat.name}} + {{indicat.price}} MDL
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'ro'">
                              {{indicat.nameRo}} + {{indicat.price}} MDL
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'en'">
                              {{indicat.nameEn}} + {{indicat.price}} MDL
                            </ng-container>
                          </option>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>

                <ng-container *ngFor="let indicat of indicatorS">
                  <ng-container *ngIf="selectedIndicator === indicat.name ">
                    <div class="container pt-3 pb-1">
                      <!--Section: Content-->
                      <section class="">
                        <!--Grid row-->
                        <div class="row">
                          <!--Grid column-->
                          <div class="col-lg-4 col-md-3 mb-4 mb-md-0">
                            <!--Image-->
                            <!--                            <div class="view overlay z-depth-1-half">-->
                            <div class="">

                              <img lightbox src="{{indicat.img}}" class="img-fluid" alt="">
                            </div>
                          </div>
                          <!--Grid column-->
                          <!--Grid column-->
                          <div class="col-md-8 mb-2 mb-md-0">
                            <ng-container *ngIf="this.main.lang == 'ru'">
                              <quill-view [content]="indicat.content"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'ro'">
                              <quill-view [content]="indicat.contentRo"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'en'">
                              <quill-view [content]="indicat.contentEn"></quill-view>
                            </ng-container>
                            <div *ngIf="indicat.price !== 0">
                              <strong class="text-danger"> + {{indicat.price | currency: 'MDL '}}</strong>
                            </div>
                          </div>
                          <!--Grid column-->
                        </div>
                        <!--Grid row-->
                      </section>
                      <!--Section: Content-->
                    </div>
                  </ng-container>
                </ng-container>

              </div>
              <div *ngIf="product.pandus[0] !== pandusNoHaveProd">
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="pandus">{{'Shop.Select.Ramp' | translate}}</label>
                    <select [(ngModel)]="selectedPandus" (change)="onSelectPand(selectedPandus)"
                            class="browser-default custom-select" id="pandus">
                      <option value="Без пандусов">Без пандусов</option>
                      <ng-container *ngFor="let pand of pandusS">
                        <ng-container *ngFor="let pan of product.pandus">
                          <ng-container *ngIf="pand._id === pan">
                            <option [value]="pand.name">
                              <ng-container *ngIf="this.main.lang == 'ru'">
                                {{pand.name}} + {{pand.price}} MDL
                              </ng-container>

                              <ng-container *ngIf="this.main.lang == 'ro'">
                                {{pand.nameRo}} + {{pand.price}} MDL
                              </ng-container>

                              <ng-container *ngIf="this.main.lang == 'en'">
                                {{pand.nameEn}} + {{pand.price}} MDL
                              </ng-container>
                            </option>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </select>
                  </div>
                </div>

                <ng-container *ngFor="let pand of pandusS">
                  <ng-container *ngIf="selectedPandus === pand.name">
                    <div class="container pt-3">
                      <!--Section: Content-->
                      <section class="">
                        <!--Grid row-->
                        <div class="row">
                          <!--Grid column-->
                          <div class="col-lg-4 col-md-3 mb-4 mb-md-0">
                            <!--Image-->
                            <div class="">
                              <img lightbox src="{{pand.img}}" class="img-fluid" alt="">
                            </div>
                          </div>
                          <!--Grid column-->
                          <!--Grid column-->
                          <div class="col-md-8 mb-2 mb-md-0">
                            <ng-container *ngIf="this.main.lang == 'ru'">
                              <quill-view [content]="pand.content"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'ro'">
                              <quill-view [content]="pand.contentRo"></quill-view>
                            </ng-container>

                            <ng-container *ngIf="this.main.lang == 'en'">
                              <quill-view [content]="pand.contentEn"></quill-view>
                            </ng-container>
                            <div *ngIf="pand.price !== 0">
                              <strong class="text-danger"> + {{pand.price | currency: 'MDL '}}</strong>
                            </div>
                          </div>
                          <!--Grid column-->
                        </div>
                        <!--Grid row-->
                      </section>
                      <!--Section: Content-->
                    </div>
                  </ng-container>
                </ng-container>
              </div>


            </div>
            <div class="mt-3">
              <h3 class="h3-responsive text-center text-md-left mb-2 ml-xl-0 ml-4">
          <span class="red-text font-weight-bold">
            <strong class="pr-3">{{product.cost | number}} MDL</strong>
          </span>
                <span class="grey-text">
            <small>
              <s>{{+product.oldCost | currency: 'MDL '}}</s> <small
              class="pl-5 grey-text">{{'Shop.Index.Code' | translate}} {{product.code}}</small>
            </small>
          </span>
              </h3>
              <div class="row mt-3 mb-4">
                <div class="col-md-12 text-center text-md-left">
                  <li class="list-group-item">{{'Shop.Index.Number' | translate}} <input type="number" value="1" min="1"
                                                                                         [(ngModel)]="product.quantity">
                  </li>

                  <div *ngIf="product.subcategory === casaProd" class="pt-1">
                    <button [disabled]="!product.quantity"
                            (click)="addToOrderCasa(product, selected, priceCasa , selectedIndicator, priceIndicator)"
                            class="btn btn-primary btn-rounded">
                      <i class="fas fa-cart-plus mr-2" aria-hidden="true"></i> {{'BUTTONS.AddShop' | translate}}
                    </button>
                  </div>


                  <div
                    *ngIf="product.subcategory === platProd || product.subcategory === platgogoProd || product.subcategory === platgoProd"
                    class="pt-1">
                    <button [disabled]="!product.quantity"
                            (click)="addToOrderIndAndPand(product, selectedIndicator, priceIndicator, selectedPandus, pricePandus)"
                            class="btn btn-primary btn-rounded">
                      <i class="fas fa-cart-plus mr-2" aria-hidden="true"></i> {{'BUTTONS.AddShop' | translate}}
                    </button>
                  </div>


                  <div
                    *ngIf="product.subcategory !== casaProd &&  product.subcategory !== platProd &&  product.subcategory !== platgogoProd && product.subcategory !== platgoProd"
                    class="pt-1">
                    <button [disabled]="!product.quantity" (click)="addToOrder(product)"
                            class="btn btn-primary btn-rounded">
                      <i class="fas fa-cart-plus mr-2" aria-hidden="true"></i> {{'BUTTONS.AddShop' | translate}}
                    </button>
                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <ngb-tabset [destroyOnHide]="false">

        <ngb-tab title="{{'Shop.Index.Tech' | translate}}">
          <ng-template  ngbTabContent>
            <ng-container *ngIf="this.main.lang == 'ru'">
              <div class="pt-2" [froalaView]="product.spec"></div>
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'ro'">
              <div class="pt-2" [froalaView]="product.specRo"></div>
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'en'">
              <div class="pt-2" [froalaView]="product.specEn"></div>
            </ng-container>
            <!--            <div [froalaView]="product.spec"></div>-->
            <!--            <quill-view-html [content]="product.spec"></quill-view-html>-->
          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{'Shop.Index.Func' | translate}}">
          <ng-template  ngbTabContent>
            <ng-container *ngIf="this.main.lang == 'ru'">
              <div class="pt-2">
              <quill-view-html [content]="product.functions"></quill-view-html>
              </div>
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'ro'">
              <div class="pt-2">
              <quill-view-html [content]="product.functionsRo"></quill-view-html>
              </div>
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'en'">
              <div class="pt-2">
              <quill-view-html [content]="product.functionsEn"></quill-view-html>
              </div>
            </ng-container>

            <!--            <quill-view-html [content]="product.functions"></quill-view-html>-->
          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{'Shop.Index.Video' | translate}}">
          <ng-template  ngbTabContent>
              <div class="pt-2" [froalaView]="product.video"></div>
          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{'Shop.Index.Cert' | translate}}">
          <ng-template  ngbTabContent>
            <div class="pt-2">
            <quill-view-html [content]="product.certificate"></quill-view-html>
            </div>
          </ng-template>
        </ngb-tab>

      </ngb-tabset>


    </section>
    <!--Section: Content-->


  </div>
</div>


<ng-template #loading>
  <p class="text-center">Загружаю продукт...</p>
</ng-template>
