<div class="container">


  <!--Section: Content-->
  <section class=" text-left dark-grey-text">

    <h3 class="font-weight-bold mb-5"></h3>

    <!--Grid row-->
    <div class="row">

      <!--Grid column-->
      <div class="col-md-6 mb-4 mb-md-0">



        <div class="wpb_wrapper">
          <h3>{{'B52.Billiard.Second.OneTop' | translate}}</h3>
          <p style="text-align: justify;">{{'B52.Billiard.Second.OneBottom' | translate}}</p>

        </div>

        <img class="img-fluid mx-auto" src="assets/auto/billiard/STATIONAR-MODUL1.jpg">

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-6 mb-4 mb-md-0">


        <div class="wpb_wrapper">
          <h3>{{'B52.Billiard.Second.TwoTop' | translate}}</h3>
          <p style="text-align: justify;">{{'B52.Billiard.Second.TwoBottom' | translate}}</p>

        </div>

<!--        <img class="img-fluid mx-auto" src="assets/auto/billiard/STATIONAR-MODUL1.jpg">-->
        <div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill"><div class="vc_column-inner vc_custom_1583335778392"><div class="wpb_wrapper"><h2 style="text-align: center;font-family:Abril Fatface;font-weight:400;font-style:normal" class="vc_custom_heading">800Х553</h2></div></div></div>

      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->


  </section>
  <!--Section: Content-->


</div>
