<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'PosMarket.FPrint.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'PosMarket.FPrint.TitleBottom' | translate}}
  </p>
  <div class="row flex-center">

    <div class="col-lg-6 order-1 wow fadeInLeft" data-wow-delay="1.2s">
      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-print fa-2x green-text pr-2"></i>
          {{'PosMarket.FPrint.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.FPrint.SpecTextOne' | translate}}
        </p>
      </div>


      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-scroll fa-2x green-text pr-2"></i>
          {{'PosMarket.FPrint.SpecTitleTwo' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.FPrint.SpecTextTwo' | translate}}
        </p>
      </div>


      <div class="pb-4">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
          {{'PosMarket.FPrint.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'PosMarket.FPrint.SpecTextThree' | translate}}
        </p>
      </div>


      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-list-alt fa-2x green-text pr-2"></i>
        {{'PosMarket.FPrint.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosMarket.FPrint.SpecTextFour' | translate}}
      </p>


    </div>


    <div class="col-lg-6 order-21 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/alexmarket/DATECSFP700.png" lightbox backgroundColor="white"
           alt="Sample image">


    </div>


  </div>

  <div class="flex-center">
    <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s"
            type="button" (click)="test.toggle()" mdbWavesEffect>
      <mdb-icon fas icon="arrow-down"></mdb-icon>
      {{'BUTTONS.TehSpec' | translate}}
      <mdb-icon fas icon="arrow-down"></mdb-icon>
    </button>
  </div>

  <div mdbCollapse [isCollapsed]="true" #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
        <p style="text-align: left;"><strong>Кассовая лента, шт:</strong> 1<br>
          <strong>Ширина печати:</strong> 80 мм (640 точек)<br>
          <strong>Скорость печати:</strong> Максимальное количество 200 мм в секунду<br>
          <strong>Однослойная термобумага, мм:</strong> 57/80<br>
          <strong>Скорость печати, мм/сек:</strong> 200<br>
          <strong>Дисплей покупателя:</strong> Нет<br>
          <strong>Дисплей продавца:</strong> Есть<br>
          <strong>Габаритные размеры, мм:</strong> 150х185х130<br>
          <strong>Масса, кг:</strong> 1.7</p>
      </div>
    </div>
  </div>
</div>










