<div class="bc-icons-2">
  <mdb-breadcrumb customClass="white">
    <div class="container">

      <mdb-breadcrumb-item>
        <mdb-breadcrumb-item [routerLink]="'/'">{{'Shop.Breadcrumbs.Home' | translate}}</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="active">{{'Shop.Breadcrumbs.Products' | translate}}</mdb-breadcrumb-item>
      </mdb-breadcrumb-item>
    </div>
  </mdb-breadcrumb>
</div>


<div class="container">
  <div class="row pt-0 pb-0">
    <div class="col-sm-9 order-2">
      <div class="d-flex justify-content-end">
        <select class="browser-default" [(ngModel)]="sort">
          <option value="-date">{{'Shop.Select.Date' | translate}}</option>
          <option value="cost">{{'Shop.Select.CostLow' | translate}}</option>
          <option value="-cost">{{'Shop.Select.CostHigh' | translate}}</option>
          <option value="name">{{'Shop.Select.NameA' | translate}}</option>
          <option value="-name">{{'Shop.Select.NameZ' | translate}}</option>
          <option value="-sale">{{'Shop.Select.Sale' | translate}}</option>
        </select>
      </div>
      <!--Section: Products v.3-->
      <section class="text-center pb-3 my-5">
        <!--Grid row-->
        <div class="row" *ngIf="products$ | async  as products ; else loading">

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4"
               *ngFor="let prod of products | filterActiveProduct | searchProduct: searchStr | orderBy: sort | paginate: { itemsPerPage: 24, currentPage: page } ">

            <!--Card-->
            <mdb-card class="align-items-center">

              <div class="view">
                <img src="{{prod.img[0].path}}" class="img-fluid " alt="zoom">
                <div class="mask waves-effect">
                  <p class="white-text">
                    <mdb-badge *ngIf="prod.sale" pill="true"
                               danger="true">{{'Shop.Select.Sale' | translate}}</mdb-badge>
                    <mdb-badge *ngIf="prod.option" pill="true"
                               danger="true">{{'Shop.Select.New' | translate}}</mdb-badge>
                  </p>
                </div>

                <a [routerLink]="['item', prod._id]">
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--Card image-->

              <!--Card content-->
              <mdb-card-body id="product" class="text-center">
                <!--Category & Title-->
                <a href="" class="grey-text">
                </a>
                <h5>
                  <strong>
                    <a [routerLink]="['item', prod._id]" href="" class="dark-grey-text">
                      <ng-container *ngIf="this.main.lang == 'ru'">
                        {{prod.name}}
                      </ng-container>

                      <ng-container *ngIf="this.main.lang == 'ro'">
                        {{prod.nameRo}}
                      </ng-container>

                      <ng-container *ngIf="this.main.lang == 'en'">
                        {{prod.nameEn}}
                      </ng-container>
                    </a>
                  </strong>
                </h5>

                <h4 class="font-weight-bold blue-text">
                  <strong>{{prod.cost | number}} MDL</strong>
                </h4>

              </mdb-card-body>
              <!--Card content-->

            </mdb-card>
            <!--Card-->

          </div>
          <!--Grid column-->

        </div>
        <!--Grid row-->
        <section *ngIf="currentSubId$ | async  as curSubId" class="text-center">
            <ng-container *ngIf="this.main.lang == 'ru'">
              <quill-view-html [content]="curSubId.desc"></quill-view-html>
            </ng-container>
            <ng-container *ngIf="this.main.lang == 'ro'">
              <quill-view-html [content]="curSubId.descRo"></quill-view-html>
            </ng-container>
            <ng-container *ngIf="this.main.lang == 'en'">
              <quill-view-html [content]="curSubId.descEn"></quill-view-html>
            </ng-container>
        </section>

      </section>
      <!--Section: Products v.3-->

    </div>


    <div class="col order-1 border-right">

      <!-- Search form -->
      <div class="form-inline md-form form-sm active-cyan-2 border pl-3">
        <input class="form-control form-control-sm mr-1 w-75 pl-3" type="text"
               placeholder="{{'Shop.Category.Search' | translate}}"
               [(ngModel)]="searchStr" aria-label="Search"
               mdbInput>
        <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
      </div>

      <hr>

      <div>
        <div class="list-group my-2 py-2">
          <a (click)="defaultPage()" [routerLink]="'/products'"
             class="list-group-item active waves-light text-white text-center" mdbWavesEffect>
            {{'Shop.Category.All' | translate}}
          </a>
        </div>
      </div>


      <div>
        <div class="list-group my-2 py-2">
          <a (click)="test2.toggle()" class="list-group-item active waves-light text-white text-center" mdbWavesEffect>
            {{'Shop.Category.Industrial' | translate}}
          </a>
          <div mdbCollapse [isCollapsed]="colapseOne" #test2="bs-collapse">
            <a (click)="defaultPage()"
               [routerLink]="['/auto-index']"
               class="list-group-item list-group-item-action waves-light"
            >
              {{'Shop.Category.AutoScales' | translate}}
            </a>

            <a (click)="defaultPage()"
               [routerLink]="['/wagon-index']"
               class="list-group-item list-group-item-action waves-light"
            >
              {{'Shop.Category.WagonScales' | translate}}
            </a>

            <a (click)="defaultPage()"
               [routerLink]="['/bunker-index']"
               class="list-group-item list-group-item-action waves-light"
            >
              {{'Shop.Category.HopperScales' | translate}}
            </a>
          </div>
        </div>
      </div>


      <div class="d-none d-sm-block">
        <ng-container *ngIf="productCategory$ | async as productCategory">

          <div *ngFor="let pcategory of productCategory" class="list-group my-2 py-2">
            <a (click)="test3.toggle()" class="list-group-item active waves-light text-white text-center"
               mdbWavesEffect>

              <ng-container *ngIf="this.main.lang == 'ru'">
                {{pcategory.name}}
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'ro'">
                {{pcategory.nameRo}}
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'en'">
                {{pcategory.nameEn}}
              </ng-container>
            </a>
            <!--          [isCollapsed]="false" -->

            <ng-container>
              <div mdbCollapse [isCollapsed]="colapse" #test3="bs-collapse">
                <div *ngIf="subproductCategory$ | async as productSubCategory">
                  <ng-container *ngFor="let psubcategory of productSubCategory">
                    <ng-container *ngIf="pcategory._id === psubcategory.category">
                      <a (click)="defaultPage()" [routerLink]="['/products/subcategory', psubcategory._id]"
                         class="list-group-item list-group-item-action waves-light">
                        <ng-container *ngIf="this.main.lang == 'ru'">
                          {{psubcategory.name}}
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'ro'">
                          {{psubcategory.nameRo}}
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'en'">
                          {{psubcategory.nameEn}}
                        </ng-container>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

      </div>


      <div class="d-block d-sm-none">
        <ng-container *ngIf="productCategory$ | async as productCategory">

          <div *ngFor="let pcategory of productCategory" class="list-group my-2 py-2">
            <a (click)="test4.toggle()" class="list-group-item active waves-light text-white text-center"
               mdbWavesEffect>

              <ng-container *ngIf="this.main.lang == 'ru'">
                {{pcategory.name}}
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'ro'">
                {{pcategory.nameRo}}
              </ng-container>

              <ng-container *ngIf="this.main.lang == 'en'">
                {{pcategory.nameEn}}
              </ng-container>
            </a>
            <!--          [isCollapsed]="false" -->

            <ng-container>
              <div mdbCollapse [isCollapsed]="colapseOne" #test4="bs-collapse">
                <div *ngIf="subproductCategory$ | async as productSubCategory">
                  <ng-container *ngFor="let psubcategory of productSubCategory">
                    <ng-container *ngIf="pcategory._id === psubcategory.category">
                      <a (click)="defaultPage()" [routerLink]="['/products/subcategory', psubcategory._id]"
                         class="list-group-item list-group-item-action waves-light">
                        <ng-container *ngIf="this.main.lang == 'ru'">
                          <div (click)="test4.toggle()">
                            {{psubcategory.name}}
                          </div>
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'ro'">
                          <div (click)="test4.toggle()">
                            {{psubcategory.nameRo}}
                          </div>
                        </ng-container>

                        <ng-container *ngIf="this.main.lang == 'en'">
                          <div (click)="test4.toggle()">
                            {{psubcategory.nameEn}}
                          </div>
                        </ng-container>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

      </div>


    </div>

  </div>

  <div class="flex-center">
    <pagination-controls
      (pageChange)="pageChanged($event)"
      (pageChange)="page = $event"
    >

    </pagination-controls>
  </div>

  <ng-template #loading>
    <p class="text-center">Загружаю продукты...</p>
  </ng-template>
</div>
