<section class="section pt-5">
  <ng-container *ngIf="this.main.lang == 'ru'">
    <div class="flex-center wow zoomIn" data-wow-delay="0.6s">
      <img src="assets/industrial/bg.jpg" class="img-fluid z-depth-1 rounded mb-4" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="this.main.lang == 'ro'">
    <div class="flex-center wow zoomIn" data-wow-delay="0.6s">
      <img src="assets/industrial/bgRo.jpg" class="img-fluid z-depth-1 rounded mb-4" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="this.main.lang == 'en'">
    <div class="flex-center wow zoomIn" data-wow-delay="0.6s">
      <img src="assets/industrial/bgEn.jpg" class="img-fluid z-depth-1 rounded mb-4" alt="">
    </div>
  </ng-container>
</section>
