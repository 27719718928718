<div>
  <div class=" my-1 py-5  grey lighten-5">
    <!--Section: Content-->
    <section class="text-center px-md-5 mx-md-5 dark-grey-text wow bounceIn" data-wow-delay="0.6s">
      <h1 class="font-weight-bold green-text">
        {{'Flagman.ComplectFlag.Title' | translate }}
      </h1>
      <p class="font-weight-bold grey-text black-text">
        {{'Flagman.ComplectFlag.SubTitle' | translate }}
      </p>

    </section>
    <!--Section: Content-->


  </div>


  <div class=" mt-0 mb-0">
    <!-- Section -->
    <section class="  wow fadeInLeft" data-wow-delay="0.6s">

      <div class="row no-gutters">

        <div class="col-lg-6 order-md-2">
          <!-- Card -->
          <div class="card card-image bgcomplectflagone  flex-center">
            <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded ">
              <div>
                <h5 class="py-3 font-weight-bold">
                  <strong>
                    <div [innerHTML]="'Flagman.ComplectFlag.OneTitle'  | translate"></div>
                  </strong>
                </h5>
                <p class="pb-0">
                <div [innerHTML]="'Flagman.ComplectFlag.OneText'  | translate"></div>

                <a routerLink="/pos-horeca-1" class="btn btn-white btn-rounded btn-md black-text">
                  <i class="fas fa-clone left"></i>
                    {{'Flagman.ComplectFlag.OneButton' | translate}}
                </a>
              </div>
            </div>
          </div>
          <!-- Card -->

        </div>

        <div class="col-lg-6 mx-auto d-flex bgcomplectflag1 align-items-center text-white">
          <div class="px-4 py-4 py-lg-0">

          </div>
        </div>

      </div>

    </section>
    <!-- Section -->


    <!-- Grid row -->
    <div class="row no-gutters  wow fadeInRight" data-wow-delay="0.6s">

      <!-- Grid column -->
      <div class="col-md-6 mb-0">

        <!-- Card -->
        <div class="card-image bgcomplectflagtwo  flex-center">
          <div class="text-white text-center d-flex align-items-center  py-5 px-4 px-md-5 rounded">
            <div>
              <h5 class="py-3 font-weight-bold">
                <strong>
                  <div [innerHTML]="'Flagman.ComplectFlag.TwoTitle'  | translate"></div>
                </strong>
              </h5>
              <p class="pb-0">
              <div [innerHTML]="'Flagman.ComplectFlag.TwoText'  | translate"></div>
              <a routerLink="/pos-horeca-2" class="btn btn-white btn-rounded btn-md black-text">
                <i class="fas fa-clone left"></i>
                {{'Flagman.ComplectFlag.TwoButton' | translate}}
              </a>
            </div>
          </div>
        </div>
        <!-- Card -->

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-6 mb-0">

        <!-- Card -->
        <div class="card-image bgcomplectflag2">
          <div class="text-white text-center d-flex align-items-center  py-5 px-3 px-md-5 rounded">

          </div>
        </div>
        <!-- Card -->

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->
  </div>


</div>
