<section class="pt-5 pb-5">

  <style>
    .border-top {
      border-top-width: 2px !important;
    }
  </style>

  <h3 class="font-weight-bold text-center dark-grey-text pb-2 wow zoomIn" data-wow-delay="0.6s">{{'About.TextRab.Title' | translate}}</h3>


  <div class="row d-flex justify-content-center">

    <div class="col-md-6 col-lg-5 col-xl-4 wow fadeInLeft" data-wow-delay="1.2s">
      <h5 class="font-weight-normal border-top border-secondary pt-4 mb-4"><a class="dark-grey-text" href="#"></a></h5>

      <p class="mb-5 pb-md-3">{{'About.TextRab.TextTop' | translate}}</p>

      <p class="mb-5 pb-md-3">{{'About.TextRab.TextBottom' | translate}}

      </p>


      <h5 class="font-weight-normal border-top border-secondary pt-4 mb-4"><a class="dark-grey-text" href="#"></a></h5>
    </div>


    <div class="col-md-6 col-lg-5 col-xl-4 wow fadeInRight" data-wow-delay="1.2s">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/CTm1K07t4uQ" allowfullscreen></iframe>
      </div>
    </div>



  </div>
</section>
<!-- Section -->
