<div class="">
  <div class="container pt-0">
    <mdb-breadcrumb-item>
      <mdb-breadcrumb-item  [routerLink]="'/solutions'">{{'SOLUTION.Index.Title' | translate}}</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">{{'SOLUTION.Index.SolutionNameFour' | translate}}</mdb-breadcrumb-item>
    </mdb-breadcrumb-item>

    <!--Section: Content-->
    <section>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-7 mb-3 align-self-center text-center text-lg-left">

          <h1 class="h2 mb-4 pb-5">
            <div [innerHTML]="'SOLUTION.Index.SolutionNameFour'  | translate"></div>
          </h1>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-5 mb-3">
          <!-- Featured image -->
          <div class="view overlay">
            <img class="img-fluid rounded-circle mx-auto" src="assets/solutions/index/image004-min.png" alt="Sample image">

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

      <div style="font-family: 'Times New Roman'">
        <h5 class="black-text">
          <div [innerHTML]="'SOLUTION.Modernization.SubTitle'  | translate"></div>
        </h5>
      </div>

    </section>
    <!--Section: Content-->





    <div lightbox-group class=" ">
      <div class="row ">
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/solutions/modernization/image003-min.png" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/solutions/modernization/image005-min.png" alt="">
        </div>
        <div class="col-4 py-1 my-1">
          <img lightbox class="img-fluid" src="assets/solutions/modernization/image007-min.png" alt="">
        </div>
      </div>
    </div>


    <div style="font-family: 'Times New Roman'">
      <h5 class="black-text">
        <div [innerHTML]="'SOLUTION.Modernization.SubSubTitle'  | translate"></div>
      </h5>
    </div>

    <section class="container">
      <div class="container pt-3">
        <h6 class="mb-0">
          <div [innerHTML]="'SOLUTION.Modernization.ContentOne.Title'  | translate"></div>
        </h6>
        <div class="row mb-5 d-flex align-items-center">
          <div class="col">
            <div class="">
              <div class="card-header white">
              </div>
              <h6>
                <div class="card-body ">
                  <ul class="mb-4">
                    <div [innerHTML]="'SOLUTION.Modernization.ContentOne.SubTitle'  | translate"></div>
                  </ul>
                </div>
              </h6>
            </div>
          </div>

        </div>
      </div>

      <div class="container">
        <h6 class="mb-0">
          <div [innerHTML]="'SOLUTION.Modernization.ContentTwo.Title'  | translate"></div>
        </h6>
        <div class="row mb-5 d-flex align-items-center">
          <div class="col">
            <div class="">
              <div class="card-header white">
              </div>
              <h6>
                <div class="card-body ">
                  <ul class="mb-4">
                    <div [innerHTML]="'SOLUTION.Modernization.ContentTwo.SubTitle'  | translate"></div>
                  </ul>
                </div>
              </h6>
            </div>
          </div>

        </div>
      </div>

    </section>






  </div>

</div>
