<section class="pb-5">
  <div class="container text-center">

    <!--Section heading-->
    <h2 class="text-center h1 py-3">
      <strong>Сертификаты</strong>
    </h2>


    <!--Grid row-->
    <div class="row text-center pt-5 d-flex justify-content-center">

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay z-depth-1-half wow zoomIn" data-wow-delay="0.6s">
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/am.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>

      </div>
      <!--Grid column-->

<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s">-->
<!--          <a>-->
<!--            <img lightbox backgroundColor="white" src="assets/cert/az.jpg" class="img-fluid" alt="Sample project image">-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/bcn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>

      </div>
      <!--Grid column-->


<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>-->
<!--          <a>-->
<!--            <img lightbox backgroundColor="white" src="assets/cert/bda.jpg" class="img-fluid" alt="Sample project image">-->
<!--          </a>-->
<!--        </div>-->

<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->



      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/bsn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>
      </div>
      <!--Grid column-->


      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/bxn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>
      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->





    <div class="row text-center pt-5 d-flex justify-content-center">

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light  wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/am.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>

      </div>
      <!--Grid column-->

<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>-->
<!--          <a>-->
<!--            <img lightbox backgroundColor="white" src="assets/cert/az.jpg" class="img-fluid" alt="Sample project image">-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->

      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1  waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/bcn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>

      </div>
      <!--Grid column-->


<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-2 col-md-3 mb-1">-->

<!--        &lt;!&ndash;Featured image&ndash;&gt;-->
<!--        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>-->
<!--          <a>-->
<!--            <img lightbox backgroundColor="white" src="assets/cert/bda.jpg" class="img-fluid" alt="Sample project image">-->
<!--          </a>-->
<!--        </div>-->

<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->



      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img  lightbox backgroundColor="white" src="assets/cert/bsn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>
      </div>
      <!--Grid column-->


      <!--Grid column-->
      <div class="col-lg-2 col-md-3 mb-1">

        <!--Featured image-->
        <div class="view overlay rounded z-depth-1 waves-light    wow zoomIn" data-wow-delay="0.6s" mdbWavesEffect>
          <a>
            <img lightbox backgroundColor="white" src="assets/cert/bxn.jpg" class="img-fluid" alt="Sample project image">
          </a>
        </div>
      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->




  </div>
</section>
<!--/Projects section v.1-->
