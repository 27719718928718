<div class="container">

  <!-- Section -->
  <section>

    <style>
      .border-top {
        border-top-width: 2px !important;
      }
    </style>

    <div class="row">

      <div class="col-md-6 col-lg-5 col-xl-6 wow fadeInLeft" data-wow-delay="0.6s">
        <h5 class="font-weight-normal border-top border-light pt-4 mb-4"><a class="dark-grey-text"></a></h5>
        <div [innerHTML]="'About.FinalAb.TextOne'  | translate"></div>
      </div>

      <div class="col-md-6 col-lg-5 col-xl-6 wow fadeInRight" data-wow-delay="0.6s">
        <h5 class="font-weight-normal border-top border-light pt-4 mb-4"><a class="dark-grey-text"></a></h5>
        <div [innerHTML]="'About.FinalAb.TextTwo'  | translate"></div>
      </div>
    </div>
  </section>
  <!-- Section -->

</div>
