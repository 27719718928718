<div class="view">
  <div style="height: 200px;" class="bgdivert">
  </div>
  <div class="mask ">
    <div class="pt-2">
      <mdb-card-body cascade="true" class="text-center pt-5">
        <!--Title-->
        <mdb-card-title class="pt-5">
          <h1>
            <strong class="white-text wow bounceIn" data-wow-delay="0.6s">{{'About.DivTwoAb.Title' | translate}}</strong>
          </h1>
          <h3>
            <strong class="white-text wow bounceIn" data-wow-delay="0.6s">{{'About.DivTwoAb.Text' | translate}}</strong>
          </h3>
        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>
</div>
