import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sevenmark',
  templateUrl: './sevenmark.component.html',
  styles: [
  ]
})
export class SevenmarkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
