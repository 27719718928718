<div class="gotop">
  <app-headermark></app-headermark>
</div>
<app-fullmark></app-fullmark>
<app-threepos></app-threepos>
<app-fouremark></app-fouremark>
<app-fivemark></app-fivemark>
<app-scanmark></app-scanmark>
<app-sixmark></app-sixmark>
<app-sevenmark></app-sevenmark>
<app-eightmark></app-eightmark>
<app-questions></app-questions>
<app-morepos></app-morepos>
<app-lastshoppos></app-lastshoppos>
