<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'Flagman.Horeca1.Soft.TitleTop' | translate}}
  </h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">
    {{'Flagman.Horeca1.Soft.TitleBottom' | translate}}
  </p>
  <div class="row flex-center">

    <div class="col order-2 wow fadeInRight" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-th-list fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.Soft.SpecTitleOne' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.Soft.SpecTextOne' | translate}}
        </p>
      </div>
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-recycle fa-2x green-text pr-2"></i>
          {{'Flagman.Horeca1.Soft.SpecTitleTwo' | translate}}
        </h6>
        <p class="text-muted">
          {{'Flagman.Horeca1.Soft.SpecTextTwo' | translate}}
        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-file-alt fa-2x green-text pr-2"></i>
        {{'Flagman.Horeca1.Soft.SpecTitleThree' | translate}}
      </h6>
      <p class="text-muted">
        {{'Flagman.Horeca1.Soft.SpecTextThree' | translate}}
      </p>

    </div>


    <div class="col-lg-6 order-1 wow fadeInLeft" data-wow-delay="0.6s">
      <img lightbox backgroundColor="white" class="img-fluid" src="assets/flagman/logointerface.png" alt="Sample image">

    </div>

  </div>


</div>
























