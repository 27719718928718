<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-3 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">{{'PosAlimentara.DatecsDPSD.TitleTop' | translate}}</h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-4 text-center wow zoomIn" data-wow-delay="1.6s">{{'PosAlimentara.DatecsDPSD.TitleBottom' | translate}}</p>
  <div class="row ">
    <div class="col order-1 wow fadeInLeft" data-wow-delay="1.2s">

      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-sitemap fa-2x green-text pr-2"></i>
          {{'PosAlimentara.DatecsDPSD.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosAlimentara.DatecsDPSD.SpecTextOne' | translate}}

        </p>
      </div>
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-rocket fa-2x green-text pr-2"></i>
        {{'PosAlimentara.DatecsDPSD.SpecTitleTwo' | translate}}
      </h6>
      <p class="">
        {{'PosAlimentara.DatecsDPSD.SpecTextTwo' | translate}}
      </p>

    </div>


    <div class="col-lg-3 order-3 wow fadeInRight" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/complect/alimentara/Datecs-DP-150SD.jpg" alt="Sample image">
    </div>



    <div class="col order-2 wow fadeInLeft" data-wow-delay="0.6s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-signal fa-2x green-text pr-2"></i>
          {{'PosAlimentara.DatecsDPSD.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'PosAlimentara.DatecsDPSD.SpecTextThree' | translate}}

        </p>
      </div>

      <h6 class="font-weight-bold mb-0">
        <i class="fas fa-power-off fa-2x green-text pr-2"></i>
        {{'PosAlimentara.DatecsDPSD.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosAlimentara.DatecsDPSD.SpecTextFour' | translate}}
      </p>



    </div>

  </div>


  <button mdbBtn color="primary" class="waves-light mt-0 wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec' | translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">


        <p style="text-align: left;"><strong>Dimensions</strong> Height × Width × Depth:76 mm × 66.5 mm × 168 mm<br>
          <strong>Cable</strong> Straight 2.0 m<br>
          <strong>Case material</strong> ABS<br>
          <strong>Indicator</strong> Beeper, LED<br>
          <strong>Input voltage</strong> 5 VDC ± 0.25 V<br>
          <strong>Current</strong> 30 mA (Standby); 105 mA (Operating); 170 mA (Max.)<br>
          <strong>Laser</strong> 630-650 nm laser diode<br>
          <strong>Scanning</strong> angle ±65°, ±70°, ±42° (Skew, Pitch, Roll)<br>
          <strong>Decode capability</strong><br>
          UPC-A, UPC-E, UPC-E1, EAN-13, EAN-8, ISBN (Bookland EAN), ISSN,<br>
          Code 39, Code 39 full ASCII, Code 32, Trioptic Code 39, Interleaved 2 of<br>
          5, Industrial 2 of 5 , Matrix 2 of 5, Codabar (NW7), Code128,UCC/EAN<br>
          128 (GS1-128), ISBT 128, Code 93, Code 11 (USD-8), MSI/Plessey,<br>
          UK/Plessey, China Post, GS1 DataBar (formerly RSS) variants, Telepen<br>
          <strong>Temperature</strong> 0° to 50°C (32° to 122°F), Operating; -40° to 60°C (-40° to 140°F), Storage<br>
          <strong>Safety</strong></p>


      </div>
    </div>
  </div>
</div>























