<div class="view">
  <div style="height: 100px;" class="bgdivert">
  </div>
  <div class="mask ">
    <div class="pt-2">
      <mdb-card-body cascade="true" class="text-center pt-5">
        <!--Title-->
        <mdb-card-title class="pt-5">
          <h2>
            <strong class="white-text wow bounceIn" data-wow-delay="0.6s">
              {{'Support.DivSupport.Title' | translate}}
            </strong>
          </h2>
        </mdb-card-title>
      </mdb-card-body>
    </div>
  </div>
</div>
