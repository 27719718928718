<div class="container my-5 py-5 ">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">
    {{'PosCompact.LastShop.Title' | translate}}
  </h3>


  <!--Section: Content-->
  <section class="text-center grey lighten-4 wow zoomIn" data-wow-delay="0.6s">


    <!--Grid row-->
    <div class="row py-5 my-5">

        <!--Grid column-->
      <ng-container *ngFor="let prod of products | slice:0:4; let index = index">
        <div *ngIf="index % 3 == 0" class="col-lg-3 col-md-6 mb-4">

          <a [routerLink]="['products/item', prod._id]" class="waves-effect waves-light"><img
            src="{{prod.img[0].path}}" style="height: 200px" class="img-fluid"
            alt=""></a>

          <p class="mb-1"><a [routerLink]="['products/item', prod._id]" class="font-weight-bold black-text">
            <ng-container *ngIf="this.main.lang == 'ru'">
              {{prod.name}}
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'ro'">
              {{prod.nameRo}}
            </ng-container>

            <ng-container *ngIf="this.main.lang == 'en'">
              {{prod.nameEn}}
            </ng-container>
          </a></p>

          <p class="mb-1"><small class="mr-1"></small><strong>
            {{prod.cost}}
          </strong></p>


        </div>
        <!--Grid column-->

      </ng-container>


    </div>
    <!--Grid row-->


  </section>
  <!--Section: Content-->
  <div class="flex-center">
    <div class="btn-group py-5 " role="group" aria-label="Basic example">

      <button routerLink="/products" mdbBtn type="button" color="primary" class="waves-light" mdbWavesEffect>
        {{'BUTTONS.GoShop' | translate}}
      </button>

    </div>
  </div>

</div>
