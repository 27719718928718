<div class="container pt-5 pb-5">
  <h3 class="font-weight-bold black-text mb-4 pb-2 text-center wow zoomIn" data-wow-delay="0.6s">{{'PosCompact.DatecsDPSD.TitleTop' | translate}}</h3>
  <p class="font-weight-bold lead text-muted mx-auto mt-4 pt-2 mb-5 text-center wow zoomIn" data-wow-delay="1.6s">{{'PosCompact.DatecsDPSD.TitleBottom' | translate}}</p>
  <div class="row">


    <div class="col order-2 wow fadeInRight" data-wow-delay="0.6s">

      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-th fa-2x green-text pr-2"></i>
          {{'PosCompact.DatecsDPSD.SpecTitleOne' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.DatecsDPSD.SpecTextOne' | translate}}
        </p>
      </div>
      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-rocket fa-2x green-text pr-2"></i>
        {{'PosCompact.DatecsDPSD.SpecTitleTwo' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.DatecsDPSD.SpecTextTwo' | translate}}
      </p>

    </div>


    <div class="col-lg-4 order-1 wow fadeInLeft" data-wow-delay="0.6s">
      <img class="img-fluid" src="assets/complect/Datecs-DP-150SD-300x300.jpg" alt="Sample image">

    </div>



    <div class="col order-3 wow fadeInRight" data-wow-delay="1.2s">
      <div class="pb-5">
        <h6 class="font-weight-bold mb-3">
          <i class="fas fa-cloud-upload-alt fa-2x green-text pr-2"></i>
          {{'PosCompact.DatecsDPSD.SpecTitleThree' | translate}}
        </h6>
        <p class="">
          {{'PosCompact.DatecsDPSD.SpecTextThree' | translate}}

        </p>
      </div>

      <h6 class="font-weight-bold mb-3">
        <i class="fas fa-plug fa-2x green-text pr-2"></i>
        {{'PosCompact.DatecsDPSD.SpecTitleFour' | translate}}
      </h6>
      <p class="">
        {{'PosCompact.DatecsDPSD.SpecTextFour' | translate}}
      </p>



    </div>

  </div>


  <button mdbBtn color="primary" class="waves-light mt-5 text-center wow fadeInLeft" data-wow-delay="0.6s" type="button" (click)="test.toggle()" mdbWavesEffect>
    <mdb-icon fas icon="arrow-down"></mdb-icon>
    {{'BUTTONS.TehSpec' | translate}}
    <mdb-icon fas icon="arrow-down"></mdb-icon>
  </button>


  <div  mdbCollapse [isCollapsed]="true"  #test="bs-collapse">
    <div class="col-lg-7 text-center text-md-left wow fadeInRight" data-wow-delay="0.6s">

      <div class="col py-3 my-3 grey lighten-3 font-weight-normal">
          <p style="text-align: left;"><strong>Механизм принтера:</strong> Прямая линия термопринтер<br>
            <strong>Скорость печати:</strong> до 12 строк в секунду<br>
            <strong>Ширина бумаги:</strong> 57 мм (42 символов в строке)<br>
            <strong>Толщина бумаги:</strong> 60 – 72 мкм<br>
            <strong>Ролл Диаметр:</strong> 55 мм<br>
            <strong>Загрузка бумаги:</strong> Легкая загрузка бумаги<br>
            <strong>Дисплей оператора:</strong> Графический ЖК-дисплей, 7 строк х 25 символов (256 х 128)<br>
            <strong>Дисплей клиента:</strong> Графический ЖК-дисплей, 2 строки х 16 символов (256 х 64)<br>
            <strong>Клавиатура:</strong> 33 клавиш<br>
            <strong>Базы данных:</strong> PLU Вместимость 100 000 PLus<br>
            <strong>Inventory группы:</strong> 9<br>
            <strong>Bедомства:</strong> 9<br>
            <strong>Oператоры:</strong> 30 (30 активных)<br>
            <strong>Фискальная память:</strong> Энергонезависимая, может поддерживать до 1825 ежедневных отчетов<br>
            <strong>Способы оплаты:</strong> Наличные, чек *, карточка *, кредит * (* программируемый)<br>
            <strong>Денежные средства</strong> с альтернативной валюты<br>
            <strong>Tипы штрих-кода:</strong> EAN 13, EAN 8, UPC, Отпечатано электронными весами<br>
            <strong>Максимальное количество продаж в одной квитанции</strong> Более 380<br>
            <strong>Cвязь:</strong> 2 х RS-232C порт для подключения:<br>
            – Считыватель бар-кода<br>
            – Цифровая шкала<br>
            – Внешний дисплей покупателя<br>
            – ПК<br>
            <strong>1 х USB класс</strong> устройства через мини-USB (опция)<br>
            <strong>Контроль Ящика 1 х </strong><br>
            <strong>Bluetooth</strong> (опция)<br>
            <strong>GPRS</strong> (опция)<br>
            <strong>Ethernet</strong> (опция)<br>
            <strong>Источник питания:</strong> Входное напряжение: AC 110 / 240В ~, 50-60 Гц<br>
            <strong>i. Выход:</strong> DC 9V / 5A<br>
            <strong>Aккумулятор:</strong> Литий-ионный аккумулятор емкостью 2000 мАч 7.4V<br>
            <strong>Размеры:</strong> (ДхШхВ) 160 х 216 х 97 мм<br>
            <strong>Bес:</strong> 570 г</p>
      </div>
    </div>
  </div>
</div>























