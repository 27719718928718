<mdb-carousel class="carousel slide carousel-fade" [interval]="2000" [animation]="'fade'">
  
  <ng-container *ngIf="this.main.lang == 'ru'">

    <mdb-carousel-item>
      <div class="view w-100">
        <a routerLink="/products/item/6451240e6f36270acd9cf211"><img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/RU-min.png" alt="First slide"></a>
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerOne.jpg" alt="First slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerTwo.jpg"
             alt="Second slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerThree.jpg"
             alt="Third slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

  </ng-container>

  <ng-container *ngIf="this.main.lang == 'en'">

    <mdb-carousel-item>
      <div class="view w-100">
        <a routerLink="/products/item/6451240e6f36270acd9cf211"><img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/EN-min.png" alt="First slide"></a>
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>


    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerOneEn.jpg"
             alt="First slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerTwoEn.jpg"
             alt="Second slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerThreeEn.jpg"
             alt="Third slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>
  </ng-container>

  <ng-container *ngIf="this.main.lang == 'ro'">

    <mdb-carousel-item>
      <div class="view w-100">
        <a routerLink="/products/item/6451240e6f36270acd9cf211"><img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/RO-min.png" alt="First slide"></a>
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerOneRo.jpg"
             alt="First slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>
    
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerTwoRo.jpg"
             alt="Second slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100 wow zoomIn" data-wow-delay="0.8s" src="assets/banner/BannerThreeRo.jpg"
             alt="Third slide">
        <div class=" waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
      </div>
    </mdb-carousel-item>
  </ng-container>
</mdb-carousel>
